import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {
  Location,
  LocationStatus
} from '../../../../ecomm/types/search-location.types';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { LocationInfoModalComponent } from '../location-info-modal/location-info-modal.component';
import {
  CustomerFeature,
  CustomerFeatureState
} from '../../../../ecomm/store/features/customer';
import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';
import { DOCUMENT } from '@angular/common';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { RegionalConfigurationFeature } from '../../../../ecomm/store/features/regional-configuration';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetStoreHandoffModeHoursPipe } from '../../../common';
import { CartWorkflowService } from '../../../../ecomm/workflows/cart/cart-workflow.service';
import { CartFeature, CartFeatureState } from '../../../../ecomm/store/features/cart';

export type LocationSearchNavigateEvent = {
  locationStatus: string | LocationStatus;
  slug: string;
  handoffMode: HandoffMode;
  deliveryAddress?: string;
};

type HTMLElementWithOptionalScroll = HTMLElement & {
  scrollIntoViewIfNeeded?: HTMLElement['scrollIntoView'];
};

@Component({
  selector: 'wri-location-search-card',
  templateUrl: './location-search-card.component.html',
  styleUrls: ['./location-search-card.component.scss'],
  providers: [GetStoreHandoffModeHoursPipe] // Provide the pipe here
})
export class LocationSearchCardComponent implements OnInit {
  @Input() locationData: Location | undefined;
  @Input() selectedHandoffMode: HandoffMode | undefined;
  @Output() navigate = new EventEmitter<LocationSearchNavigateEvent>();
  public customerState: CustomerFeatureState | null = null;
  public cartState: CartFeatureState | undefined;
  @ViewChild(LocationInfoModalComponent)
  locationInfoModal!: LocationInfoModalComponent;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @ViewChild('switchLocationConfirmationModal') switchLocationConfirmationModal!: TemplateRef<HTMLElement>;
  @Output() isLocationUnFavorited = new EventEmitter<{
    isUnfavorited: boolean;
    locationId: string;
  }>();
  @Input() selectedTab = '';
  private subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private userAccountService: CustomerWorkflowService,
    private analyticsService: AnalyticsService,
    private modalService: NgbModal,
    private redirectService: RedirectService,
    private scrollToService: ScrollToService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private cartWorkflowService: CartWorkflowService,
    private getStoreHandoffModeHoursPipe: GetStoreHandoffModeHoursPipe
  ) {}

  private _showFocus = false;

  get showFocus() {
    return this._showFocus;
  }

  @Input()
  @HostBinding('class.show-focus')
  set showFocus(val: boolean) {
    this._showFocus = val;
    if (val && this.id) {
      const el: HTMLElementWithOptionalScroll | null =
        this._document.getElementById(this.id);
      if (!el) {
        return;
      }
      if (window.innerWidth > 991) {
        this.scrollToService.scrollTo({ target: el, offset: -10 });
      } else {
        if (el.scrollIntoView) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
        }
      }
    }
  }

  @HostBinding('attr.id')
  @HostBinding('attr.data-testid')
  get id() {
    return this.locationData?.id;
  }

  get offersDelivery() {
    return (
      this.locationData?.handoffModes.some(
        (f) => f.toLowerCase() === 'delivery'
      ) ?? false
    );
  }

  ngOnInit() {
    this.subscribeToCustomerState();
    this.subscribeToCartState();
  }

  private isCartNotEmpty(): boolean | undefined {
    return this.cartState?.cart?.items && this.cartState.cart.items.length > 0;
  }

  async carryoutStoreClick(
    storeId: string
  ) {
    const { cartTransferred } = await this.cartWorkflowService.isCartTransferred(
      this.route.snapshot,
      storeId,
      HandoffMode.carryout
    );
    if (cartTransferred && this.isCartNotEmpty()) {
      this.modalService.open(this.switchLocationConfirmationModal, {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm'
      });
    } else {
      this.navigateToMenuPage();
    }
  }

  navigateToMenuPage() {
    this.analyticsService.logGaEvent({
      event: 'store_order_now',
      order_method: this.selectedHandoffMode ?? 'carryout'
    });

    this.navigate.emit({
      locationStatus: this.locationData?.locationStatus ?? '',
      slug: this.locationData?.slug ?? '',
      handoffMode: this.selectedHandoffMode ?? 'carryout'
    });
  }

  showLocationInfo() {
    this.locationInfoModal.openModal();
    this.analyticsService.logGaEvent({ event: 'view_store_details' });
  }

  public async unFavoriteLocation() {
    if (this.locationData?.id) {
      const response = await this.userAccountService.unFavoriteLocation(
        this.locationData?.id
      );

      if (this.selectedTab === 'favorites') {
        response &&
          this.isLocationUnFavorited.emit({
            isUnfavorited: true,
            locationId: this.locationData?.id
          });
      }
    }
  }

  public async onFavoriteLocation() {
    this.analyticsService.logGaEvent({ event: 'favorite_store' });

    if (this.customerState?.customer) {
      if (this.locationData?.id) {
        await this.userAccountService.setFavoriteLocation(
          this.locationData?.id
        );
      }
    } else {
      this.modalService.open(this.commonModal, {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm'
      });
    }
  }

  async login() {
    this.modalService.dismissAll();
    const encodeUrlPath = encodeURIComponent(this.router.url);

    if (this.featureFlagService.featureFlags['enableLogin']) {
      const regionalConfigOptions = await firstValueFrom(
        this.store.select(
          RegionalConfigurationFeature.selectRegionalConfigurationOptions
        )
      );
      if (
        regionalConfigOptions &&
        regionalConfigOptions['feature_enable_login']?.value === 'false'
      ) {
        this.redirectService.redirectToLegacy(
          `account/login?ngfe_auth&ngfe_url=${encodeUrlPath}`
        );
      } else {
        await this.authService.login(this.router.url);
      }
    } else {
      this.redirectService.redirectToLegacy(
        `account/login?ngfe_auth&ngfe_url=${encodeUrlPath}`
      );
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private subscribeToCustomerState(): void {
    const customerState$ = this.store
      .select(CustomerFeature.selectCustomerState)
      .pipe(filter(Boolean));

    this.subscription.add(
      customerState$.subscribe((state) => {
        this.customerState = state;
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartState = state;
      })
    );
  }

  get hasCarryout() {
    return this.locationData?.handoffModes?.includes('carryout');
  }

  get carryoutOrBusinessHours() {
    const locationData = this.locationData;
    if (!locationData) return [];

    if (!this.hasCarryout) {
      return this.getStoreHandoffModeHoursPipe.transform(
        locationData.storeHandoffModeHours ?? [],
        'Carryout'
      );
    } else {
      return locationData.storeHours ?? [];
    }
  }
}
