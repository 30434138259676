import { BaseModel } from '@wingstop/models/base.model';

export class OrderResponseTaxModel extends BaseModel {
  label: string = null;
  tax: number = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
