import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ACTIVE_OFFER, ActiveOfferResolver } from '../common';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { ConnectedHomePageComponent } from './components/connected-home-page/connected-home-page.component';
import { HomePageModule } from './home-page.module';

const homePageRoutes: Routes = [
  {
    path: '',
    component: ConnectedHomePageComponent,
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableHomePage'))
    ],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true
    }
  }
];

@NgModule({
  imports: [HomePageModule, RouterModule.forChild(homePageRoutes)]
})
export class HomePageRoutingModule {}
