import { BaseModel } from '@wingstop/models/base.model';

export class SSOUser extends BaseModel {
  public static PROVIDER_GOOGLE: string = 'google';
  public static PROVIDER_FACEBOOK: string = 'facebook';

  provider: string = null;
  providertoken: string = null;
  provideruserid: string = null;
  contactnumber: string = null;
  basketid: string = null;
  authtoken: string = null;
  optin: boolean = true;
  nomnom: {} = {
    country: 'USA',
  };

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
