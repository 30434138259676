import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AlertModel } from '@wingstop/models/alert.model';
import { Basket } from '@wingstop/models/basket.model';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';
import { ClubModel } from '@wingstop/models/club.model';
import { Location } from '@wingstop/models/location/location.model';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { ContactDetails } from '@wingstop/models/login/contact-details.model';
import { ContactOptions } from '@wingstop/models/login/contact-options.model';
import { RememberMe } from '@wingstop/models/login/remember-me.model';
import { UserBillingAccount } from '@wingstop/models/login/user-billing-account.model';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';
import { Menu } from '@wingstop/models/menu.model';
import { Modifier } from '@wingstop/models/menu/option.model';
import { ProductRedirect } from '@wingstop/models/menu/product-redirect.model';
import { FavoriteOrder } from '@wingstop/models/order/favorite-order.model';
import {
  FiservHostedPageResponse,
  FiservSessionResponse,
  FiservUser,
} from '@wingstop/models/order/fiserv-payments.model';
import { LastSessionOrder } from '@wingstop/models/order/last-session-order.model';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { Token } from '@wingstop/models/token.model';
import { IAppStore } from '@wingstop/store/app-store';
import { Observable } from 'rxjs';
import { IPilotProgram } from '@wingstop/models/pilot/pilot-program.model';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';
@Injectable()
export class AppStateSelectors {
  constructor(private store: Store<IAppStore>) { }

  searchLocations: Observable<Location[]> = this.store.select(
    (state) => state.appState.searchLocations
  );

  selectedLocation: Observable<Location> = this.store.select(
    (state) => state.appState.selectedLocation
  );

  googleMap: Observable<google.maps.Map> = this.store.select(
    (state) => state.appState.googleMap
  );

  selectedMenu: Observable<Menu> = this.store.select(
    (state) => state.appState.selectedMenu
  );

  globalMenu: Observable<Menu> = this.store.select(
    (state) => state.appState.globalMenu
  );

  basket: Observable<Basket> = this.store.select(
    (state) => state.appState.basket
  );

  token: Observable<Token> = this.store.select((state) => state.appState.token);

  authentication: Observable<Authentication> = this.store.select(
    (state) => state.appState.authentication
  );

  productRedirect: Observable<ProductRedirect> = this.store.select(
    (state) => state.appState.productRedirect
  );

  selectedProduct: Observable<Modifier[]> = this.store.select(
    (state) => state.appState.selectedProductModifiers
  );

  recent: Observable<OrderResponse[]> = this.store.select(
    (state) => state.appState.recent
  );

  errors: Observable<object> = this.store.select(
    (state) => state.appState.errors
  );

  addresses: Observable<UserDeliveryAddress[]> = this.store.select(
    (state) => state.appState.addresses
  );

  billingAccounts: Observable<UserBillingAccount[]> = this.store.select(
    (state) => state.appState.billingAccounts
  );

  favoriteLocations: Observable<Location[]> = this.store.select(
    (state) => state.appState.favoriteLocations
  );

  favoriteOrders: Observable<FavoriteOrder[]> = this.store.select(
    (state) => state.appState.favoriteOrders
  );

  contactOptions: Observable<ContactOptions> = this.store.select(
    (state) => state.appState.contactOptions
  );

  contactDetails: Observable<ContactDetails> = this.store.select(
    (state) => state.appState.contactDetails
  );

  redirect: Observable<string> = this.store.select(
    (state) => state.appState.redirect
  );

  rememberMe: Observable<RememberMe> = this.store.select(
    (state) => state.appState.rememberMe
  );

  openAlert: Observable<boolean> = this.store.select(
    (state) => state.appState.openAlert
  );

  confirmationPageViewed: Observable<boolean> = this.store.select(
    (state) => state.appState.confirmationPageViewed
  );

  alertContent: Observable<AlertModel> = this.store.select(
    (state) => state.appState.alertContent
  );

  lastSessionOrder: Observable<LastSessionOrder> = this.store.select(
    (state) => state.appState.lastSessionOrder
  );

  confirmAlert: Observable<boolean> = this.store.select(
    (state) => state.appState.confirmAlert
  );

  favoriteName: Observable<string> = this.store.select(
    (state) => state.appState.favoriteName
  );

  clubForm: Observable<ClubModel> = this.store.select(
    (state) => state.appState.clubForm
  );

  tipIndex: Observable<number> = this.store.select(
    (state) => state.appState.tipIndex
  );

  userTip: Observable<boolean> = this.store.select(
    (state) => state.appState.userTip
  );

  defaultTip: Observable<any> = this.store.select(
    (state) => state.appState.defaultTip
  );

  currentProductSelections: Observable<any> = this.store.select(
    (state) => state.appState.currentProductSelections
  );

  upsellProducts: Observable<any> = this.store.select(
    (state) => state.appState.upsellProducts
  );

  createUserError: Observable<any> = this.store.select(
    (state) => state.appState.createUserError
  );

  showSecretMenu: Observable<boolean> = this.store.select(
    (state) => state.appState.showSecretMenu
  );

  showUserRegistration: Observable<boolean> = this.store.select(
    (state) => state.appState.showUserRegistration
  );

  showRoundUp: Observable<boolean> = this.store.select(
    (state) => state.appState.showRoundUp
  );

  isDigitalMenu: Observable<boolean> = this.store.select(
    (state) => state.appState.isDigitalMenu
  );

  isReorder: Observable<boolean> = this.store.select(
    (state) => state.appState.isReorder
  );

  digitalMenuLoaded: Observable<boolean> = this.store.select(
    (state) => state.appState.digitalMenuLoaded
  );

  paymentProvider: Observable<string> = this.store.select(
    (state) => state.appState.paymentProvider
  );

  fiservUser: Observable<FiservUser> = this.store.select(
    (state) => state.appState.fiservUser
  );

  fiservSession: Observable<FiservSessionResponse> = this.store.select(
    (state) => state.appState.fiservSession
  );

  locationSupportsPrepaid: Observable<boolean> = this.store.select(
    (state) => state.appState.locationSupportsPrepaid
  );

  fiservIframeResponse: Observable<FiservHostedPageResponse> =
    this.store.select((state) => state.appState.fiservIframeResponse);

  inSubmissionFlow: Observable<boolean> = this.store.select(
    (state) => state.appState.inSubmissionFlow
  );

  roundUpAmount: Observable<number> = this.store.select(
    (state) => state.appState.roundUpAmount
  );

  cokeFreestyle: Observable<boolean> = this.store.select(
    (state) => state.appState.cokeFreestyle
  );

  showCokeFreestyle: Observable<boolean> = this.store.select(
    (state) => state.appState.showCokeFreestyle
  );

  showApplePay: Observable<boolean> = this.store.select(
    (state) => state.appState.showApplePay
  );

  showAppBanner: Observable<boolean> = this.store.select(
    (state) => state.appState.showAppBanner
  );

  appBannerClosedByUser: Observable<boolean> = this.store.select(
    (state) => state.appState.appBannerClosedByUser
  );

  appDownloadBannerEnabled: Observable<boolean> = this.store.select(
    (state) => state.appState.appDownloadBannerEnabled
  );

  userLocale: Observable<string> = this.store.select(
    (state) => state.appState.userLocale
  );

  s3SeoMetadata: Observable<SeoMetadata[]> = this.store.select(
    (state) => state.appState.s3SeoMetadata
  );

  pilotProgram: Observable<IPilotProgram> = this.store.select(
    (state) => state.appState.pilotProgram
  );

  pilotProgramUser: Observable<IPilotProgramUser> = this.store.select(
    (state) => state.appState.pilotProgramUser
  );
}
