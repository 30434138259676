import { BaseModel } from '@wingstop/models/base.model';
export class TokenizedCreditCard extends BaseModel {
  transactionId: string = null;
  aliasCC: string = null;
  aliasCVV: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
