import { BaseModel } from '@wingstop/models/base.model';
import { UserBillingAccountBalance } from './user-billing-account-balance.model';

export class UserBillingAccount extends BaseModel {
  accountid: any = null;
  accountidstring: string = null;
  accounttype: string = null;
  cardtype: string = null;
  cardsuffix: string = null;
  description: string = null;
  removable: boolean = null;
  expiration: string = null;
  balance: UserBillingAccountBalance = null;
  fiserv?: boolean = false;

  constructor(values?: any) {
    super();

    if (values) {
      if (values.balance) {
        values.balance = new UserBillingAccountBalance(values.balance);
      }
    }

    if (values.accountidstring) {
      values.accountid = values.accountidstring;
    }

    this.initialize(values);
  }
}
