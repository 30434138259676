import moment from 'moment-mini';

import { BaseModel } from '@wingstop/models/base.model';
import { BasketProduct } from './basket-product.model';
import { OrderResponseTaxModel } from './order-response-tax.model';
import { DeliveryStatus } from '@wingstop/models/order/delivery-status.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { Discount } from '@wingstop/models/order/discount.model';
import { CustomField } from '@wingstop/models/order/custom-field.model';
import { Basket } from '@wingstop/models/basket.model';
import { Donation } from './donation.model';
import { Fee } from './fee.model';

export class OrderResponse extends BaseModel {
  arrivalstatus: string = null;
  billingaccountid: string = null;
  billingaccountids: any[] = null;
  customerhandoffcharge: number = null;
  customfields: CustomField[] = null;
  deliveryaddress: DeliveryAddress = null;
  deliverymode: string = null;
  discount: number = null;
  discounts: Discount[] = null;
  hasolopass: boolean = null;
  id: string = null;
  iseditable: boolean = null;
  mode: string = null;
  oloid: number = null;
  orderref: string = null;
  posreferenceresponse: string = null;
  products: BasketProduct[] = null;
  readytime: moment.Moment;
  salestax: number = null;
  status: string = null;
  subtotal: number = null;
  taxes: OrderResponseTaxModel[] = null;
  timemode: string = null;
  timeplaced: moment.Moment = null;
  tip: number = null;
  total: number = null;
  vendorextref: string = null;
  vendorid: any = null;
  vendorname: string = null;
  deliveries: DeliveryStatus[] = null;
  donations: Donation[] = [];
  totaldonations: number = null;
  fees: Fee[];
  unavailableproducts: BasketProduct[] = null;

  // new fields?
  store_latitude: any;
  store_longitude: any;
  store_slug: any;

  // These are local helper variables when an order is Re-Ordered.
  // See order/recent component.
  error: string = null;
  success: string = null;

  constructor(values?: any) {
    super();

    if (values) {
      if (values.products) {
        values.products = values.products.map(
          (value: any) => new BasketProduct(value)
        );
      }
      if (values.taxes) {
        values.taxes = values.taxes.map(
          (value: any) => new OrderResponseTaxModel(value)
        );
      }
      if (values.deliveries) {
        values.deliveries = values.deliveries.map(
          (value: any) => new DeliveryStatus(value)
        );
      }
      if (values.discounts) {
        values.discounts = values.discounts.map(
          (value: any) => new Discount(value)
        );
      }
      if (values.customfields) {
        values.customfields = values.customfields.map(
          (value: any) => new CustomField(value)
        );
      }
      if (values.timeplaced) {
        values.timeplaced = moment(values.timeplaced, 'YYYYMMDD HH:mm');
      }
      if (values.readytime) {
        values.readytime = moment(values.readytime, 'YYYYMMDD HH:mm');
      }
      if (values.deliveryaddress) {
        values.deliveryaddress = new DeliveryAddress(values.deliveryaddress);
      }
    }

    this.initialize(values);
  }

  getDeliveryMode() {
    return this.deliverymode;
  }

  public inASAPMode() {
    return this.timemode === 'asap';
  }

  public inLaterMode() {
    return this.timemode === 'advance' || this.timemode === 'later';
  }

  public isDispatch() {
    return this.getDeliveryMode() === Basket.MODE_DISPATCH;
  }

  public isPickup() {
    return this.getDeliveryMode() === Basket.MODE_PICKUP;
  }

  public isCurbside() {
    return this.getDeliveryMode() === Basket.MODE_CURBSIDE;
  }

  public isDinein() {
    return this.getDeliveryMode() === Basket.MODE_DINEIN;
  }

  public isScheduledOrder() {
    return this.status === 'Scheduled';
  }
}
