import { BaseModel } from '@wingstop/models/base.model';

export class GeocodeAddressComponent extends BaseModel {
  long_name: string;
  short_name: string;
  types: string[];

  constructor(values?: any) {
    super();

    this.initialize(values);
  }
}
