import { BaseModel } from '@wingstop/models/base.model';

export class Authentication extends BaseModel {
  authtoken: string = null;
  contactdetails: string = null;
  deliveryaddresses: string[] = null;
  emailaddress: string = null;
  firstname: string = null;
  lastname: string = null;
  nomnom: any = null;
  provider: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
