import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { ChooseJobLocationComponent } from './components/choose-job-location/choose-job-location.component';
import { JobSearchModule } from './job-search.module';
import { ACTIVE_OFFER, ActiveOfferResolver } from '../common';

const jobSearchRoutes: Routes = [
  {
    path: '',
    component: ChooseJobLocationComponent,
    canActivate: [
      combineGuard(cacheLoadedGuard, featureFlagRouteGuard('enableJobSearch'))
    ],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    },
    data: {
      reuse: true
    }
  }
];

@NgModule({
  imports: [JobSearchModule, RouterModule.forChild(jobSearchRoutes)]
})
export class JobSearchRoutingModule {}
