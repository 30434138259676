import { BaseModel } from '@wingstop/models/base.model';
import { GeocodeAddressComponent } from '@wingstop/models/geocode-address-component.model';

export class GeocodeParsedAddress extends BaseModel {
  number: string;
  street: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  subpremise: string;

  constructor(values?: any) {
    super();

    // A sane person might ask why we're looking this many times - it's because some fields require a fallback to a different
    // field if not found, and the only way to be sure we've traversed the entire array before using the fallback, is to parse
    // the whole array every time (or a similar approach?)
    let street_number: string,
      route: string,
      city: string,
      state: string,
      postal: string,
      subpremise: string = null;

    if (values.address_components && Array.isArray(values.address_components)) {
      values.address_components = values.address_components.map(
        (value: any) => new GeocodeAddressComponent(value)
      );
      values.address_components.forEach((a: GeocodeAddressComponent) => {
        if (a.types.includes('street_number')) {
          street_number = a.long_name;
        }
      });
      values.address_components.forEach((a: GeocodeAddressComponent) => {
        if (a.types.includes('route')) {
          route = a.long_name;
        }
      });
      values.address_components.forEach((a: GeocodeAddressComponent) => {
        if (
          a.types.includes('locality') ||
          a.types.includes('administrative_area_level_3')
        ) {
          city = a.long_name;
        }
      });
      values.address_components.forEach((a: GeocodeAddressComponent) => {
        if (a.types.includes('administrative_area_level_1')) {
          state = a.short_name;
        }
      });
      values.address_components.forEach((a: GeocodeAddressComponent) => {
        if (a.types.includes('postal_code')) {
          postal = a.long_name;
        }
      });

      values.address_components.forEach((a: GeocodeAddressComponent) => {
        if (a.types.includes('subpremise')) {
          subpremise = a.long_name;
        }
      });

      if (!city) {
        values.address_components.forEach((a: GeocodeAddressComponent) => {
          if (
            a.types.includes('neighborhood') ||
            a.types.includes('administrative_area_level_3')
          ) {
            city = a.long_name;
          }
        });
      }

      // This is likely a Google Places search result and we need to get the value from a different spot.
      if (!values.country) {
        values.address_components.forEach((a: GeocodeAddressComponent) => {
          if (a.types.includes('country')) {
            values.country = a.short_name;
          }
        });
      }

      if (street_number && route && city && state && postal) {
        values.number = street_number;
        values.street = values.number + ' ' + route;
        values.city = city;
        values.state = state;
        values.postal = postal;
        values.subpremise = subpremise;
      }
    }

    if (values.type && values.type === 'Feature') {
      let context: MapboxGeocodeContext;

      values.number = values.address;
      values.street =
        values.number && values.text
          ? values.number + ' ' + values.text
          : values.text;

      context = values.context
        ? (values.context.find((c: MapboxGeocode) =>
            c.id.includes('place.')
          ) as MapboxGeocodeContext)
        : null;
      values.city = context ? context.text : null;

      context = values.context
        ? (values.context.find((c: MapboxGeocode) =>
            c.id.includes('region.')
          ) as MapboxGeocodeContext)
        : null;
      if (context && context.short_code && context.short_code.includes('-')) {
        values.state = context.short_code.split('-')[1];
      }

      context = values.context
        ? (values.context.find((c: MapboxGeocode) =>
            c.id.includes('postcode.')
          ) as MapboxGeocodeContext)
        : null;
      values.postal = context ? context.text : null;

      context = values.context
        ? (values.context.find((c: MapboxGeocode) =>
            c.id.includes('country.')
          ) as MapboxGeocodeContext)
        : null;
      values.country = context ? context.short_code.toUpperCase() : null;
    }

    this.initialize(values);
  }

  isComplete() {
    return this.number && this.street && this.city && this.state && this.postal;
  }

  format() {
    return (
      (this.street || this.number || '') +
      ', ' +
      (this.city ? this.city + ', ' : '') +
      (this.state ? this.state + ', ' : '') +
      (this.postal ? this.postal + ', ' : '') +
      (this.country ? this.country + ', ' : '')
    )
      .trim()
      .replace(/(^,)|(,$)/g, '');
  }
}

class MapboxGeocode {
  id: string;
  context: MapboxGeocodeContext[];
}

class MapboxGeocodeContext {
  id: string;
  wikidata: string;
  text: string;
  short_code: string;
}
