<div class="flavor-wpr">
  <div class="flavor-wpr-titles">
    <div class="flavor-wpr-header" data-testid="find-flavor">
      Find your Flavor
    </div>
    <div class="flavor-wpr-subtitle" data-testid="find-flavor-subtitle">
      <span>Kick up the flavor on any of our classic wings</span>
      <span>boneless wings, or crispy tenders!</span>
    </div>
  </div>

  <div class="flavor-wpr-slider">
    <div class="flavor-wpr-slider-title">heat scale</div>
    <div class="flavor-wpr-slider-container">
      <ngx-slider
        [options]="heatScaleSliderConfig"
        [value]="selectedMinHeatScaleCount"
        [highValue]="selectedMaxHeatScaleCount"
        (userChange)="onUserChange($event)"
      ></ngx-slider>
    </div>
    <div class="descriptors">
      <div class="descriptors-wpr">
        <div class="no-heat">{{ flavorSpiceHeading.MILD }}</div>
        <div class="some-heat">{{ flavorSpiceHeading.MEDIUM }}</div>
        <div class="all-the-heat">{{ flavorSpiceHeading.HOT }}</div>
      </div>
    </div>
  </div>

  <div class="flavor-wpr-type">
    <div class="flavor-wpr-type-title">Flavor type</div>
    <div class="flavor-wpr-type-switchs-wpr">
      <div class="wet-switch-wpr">
        <label class="switch">
          <input
            (change)="onChangeIsWet()"
            role="switch"
            type="checkbox"
            [attr.aria-checked]="selectedIsWetValue"
            [checked]="selectedIsWetValue"
          />
          <span class="slider round"></span>
        </label>
        <div class="switch-label">Wet</div>
      </div>
      <div class="dry-switch-wpr">
        <label class="switch">
          <input
            (change)="onChangeIsDry()"
            role="switch"
            type="checkbox"
            [attr.aria-checked]="selectedIsDryValue"
            [checked]="selectedIsDryValue"
          />
          <span class="slider round"></span>
        </label>
        <div class="switch-label">Dry</div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="selectedFlavors?.length > 0; then hasFlavors; else noFlavors"
  ></ng-container>

  <ng-template #hasFlavors>
    <div class="flavor-wpr-filtered-flavors" data-testid="has-flavors">
      <div *ngFor="let flavor of selectedFlavors">
        <wri-flavor-card
          [flavor]="flavor"
          [storeSlug]="storeSlug"
        ></wri-flavor-card>
      </div>
    </div>
  </ng-template>
  <ng-template #noFlavors>
    <ng-container
      *ngIf="allFlavors?.length > 0; then hasAPIFlavors; else noAPIFlavors"
    ></ng-container>
    <ng-template #hasAPIFlavors>
      <div class="flavor-wpr-no-flavors" data-testid="no-flavors">
        Sorry, no flavors match your selection.
      </div>
    </ng-template>
    <ng-template #noAPIFlavors>
      <div class="flavor-wpr-no-flavors" data-testid="no-flavors">
        We ran into an issue loading flavor data. Please try again.
      </div>
    </ng-template>
  </ng-template>
</div>
