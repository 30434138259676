import { BaseModel } from '@wingstop/models/base.model';

export class UserBillingAccountBalance extends BaseModel {
  balance: number = null;
  success: boolean = null;
  message: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
