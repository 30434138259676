import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  ACTIVATED_ROUTE_SNAPSHOT,
  ACTIVE_OFFER,
  ActivatedRouteSnapshotResolver,
  ActiveOfferResolver,
  DYNAMIC_SCRIPTS,
  DynamicScriptsResolver,
  GenericErrorComponent,
  authenticatedGuard,
  loginRedirectUnauthenticatedGuard
} from '../common';
import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { AccountsModule } from './accounts.module';
import { ConnectedSignupComponent } from './components/connected-signup/connected-signup.component';
import { SigninCallbackComponent } from './components/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './components/signout-callback/signout-callback.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { LoginComponent } from './components/login/login.component';

export const accountsRoutes: Routes = [
  {
    path: 'signin-callback',
    component: SigninCallbackComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver
    }
  },
  {
    path: 'signout-callback',
    component: SignoutCallbackComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver
    }
  },
  {
    path: 'settings',
    component: UserAccountComponent,
    canActivate: [combineGuard(cacheLoadedGuard, loginRedirectUnauthenticatedGuard)],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver,
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  },
  {
    path:'login',
    component: LoginComponent,
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver
    }
  },
  {
    path:'signup',
    component: ConnectedSignupComponent,
    canActivate: [combineGuard(cacheLoadedGuard, authenticatedGuard, featureFlagRouteGuard('enableAccountRegister'))],
    resolve: {
      [ACTIVATED_ROUTE_SNAPSHOT]: ActivatedRouteSnapshotResolver,
      [DYNAMIC_SCRIPTS]: DynamicScriptsResolver
    }
  },
  {
    path: '',
    component: GenericErrorComponent
  }
];

@NgModule({
  imports: [AccountsModule, RouterModule.forChild(accountsRoutes)]
})
export class AccountsRoutingModule {}
