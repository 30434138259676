import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@wingstop/environments/environment';
import { enableProdMode } from '@angular/core';
import 'zone.js';
import * as Sentry from '@sentry/angular-ivy';
import gitInfo from '../git-info.json';

declare var window: any;

if (environment.production) {
  enableProdMode();
}
if (environment.sentry.enableSentry)
  Sentry.init({
    environment: environment.envName,
    dsn: environment.sentry.dsn,
    release: gitInfo.tag || 'na', // note: "/" char is not allowed in release
    enableTracing: environment.sentry.enableSentryPerformance,
    integrations: [
      ...(environment.sentry.enableSentryPerformance
        ? [Sentry.browserTracingIntegration()]
        : []),
      ...(environment.sentry.enableSentryReplays
        ? [Sentry.replayIntegration()]
        : []),
    ],
    ...(environment.sentry.enableSentryPerformance && {
      tracesSampleRate: environment.sentry.tracesSampleRate,
    }),
    tracePropagationTargets: [environment.ecomm.baseUrl],
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
    denyUrls: environment.sentry.denyUrls,
    maxValueLength: environment.sentry.maxValueLength,
    beforeSend(event) {
      if (event.breadcrumbs) {
        const hasGoogleAnalyticsBreadcrumb = event.breadcrumbs.some(breadcrumb =>
          breadcrumb.category === 'xhr' &&
          breadcrumb.data?.url && breadcrumb.data.url.includes('google-analytics.com')
        );
        if (hasGoogleAnalyticsBreadcrumb) {
          return null;
        }
      }
      return event
    }
  });

const setWindowWsData = function (key: string, data: any, subkey?: string) {
  if (!window.ws) {
    window.ws = {};
  }
  if (subkey) {
    if (!window.ws[key]) {
      window.ws[key] = {};
    }
    window.ws[key][subkey] = data;
  } else {
    window.ws[key] = data;
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {
    setWindowWsData('isNGFE', true);
    return console.log(`Bootstrap success`)
  })
  .catch((err) => console.error(err));
