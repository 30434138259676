<div class="wri-marketing-section" data-testid="marketing-section">
  <div class="wri-marketing-section-content">
    <span class="wri-marketing-section-title">Order Wings for Delivery or Carryout</span>
    <span class="wri-marketing-section-description">
      We're making it easier than ever to bring our iconic flavors straight to you.
      Build a custom order as bold as your crew on the Wingstop app.
    </span>
    <div class="logos-app d-flex">
      <a
        (click)="onAppDownloadClick('apple')"
        href="https://apps.apple.com/us/app/wingstop/id454719277"
        class="pointer"
        [attr.aria-label]="'logo'"
      >
        <img
          src="/assets/images/apple-icon.svg"
          usemap="#apple-map"
          alt="Download on the App Store"
          aria-hidden="true"
          class="social-icon apple-icon"
        />
      </a>
      <a
        (click)="onAppDownloadClick('google')"
        href="https://play.google.com/store/apps/details?id=com.mobilaurus.wingstopandroid&hl=en_US"
        class="pointer"
        [attr.aria-label]="'logo'"
      >
        <img
          src="/assets/images/google-icon.svg"
          usemap="#google-map"
          alt="Get it on Google Play"
          aria-hidden="true"
          class="social-icon google-icon"
        />
      </a>
    </div>
  </div>
</div>
