import { BaseModel } from '@wingstop/models/base.model';

export class State extends BaseModel {
  name: string;
  displayName: string;
  fullName: string;
  count: number;
  cities: [];

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
