<button
  *ngIf="mapEnabled && interactive && !showPreview && searchEnabled"
  data-testid="wri-map-search"
  class="wri-map wri-map--search-btn wri-btn wri-btn-secondary"
  (click)="onSearch()"
>
  search this area
</button>
<button
  *ngIf="mapEnabled && interactive && showPreview"
  data-testid="wri-start-search"
  class="wri-map wri-map--start-search-btn wri-btn wri-btn-secondary-icon"
  aria-label="tap to explore"
  (click)="onStartSearch()"
>
  <wri-icon icon="wri-map-view"></wri-icon>
  tap to explore
</button>

<div *ngIf="!mapEnabled" data-testid="map-disabled-section" class="coming-soon">
  Interactive map coming soon...
</div>
