import { BaseModel } from '@wingstop/models/base.model';
import { PaymentData } from '@wingstop/models/basket/payment-data.model';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';
import { Basket } from '@wingstop/models/basket.model';

export class LastSessionOrder extends BaseModel {
  paymentData: PaymentData = null;
  orderResponse: OrderResponse = null;
  basket: Basket = null;

  constructor(values?: any) {
    super();
    if (values.basket) {
      values.basket = new Basket(values.basket);
    }

    if (values.paymentData) {
      values.paymentData = new PaymentData(values.paymentData);
    }

    if (values.orderResponse) {
      values.orderResponse = new OrderResponse(values.orderResponse);
    }

    this.initialize(values);
  }
}
