import { BaseModel } from '@wingstop/models/base.model';

export class Image extends BaseModel {
  groupname: string = null;
  description: string = null;
  isdefault: boolean = true;
  filename: string = null;
  url: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
