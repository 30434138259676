import { Pipe, PipeTransform } from '@angular/core';
import { cond, constant, stubTrue } from 'lodash';
import moment from 'moment-timezone';

import { isToday } from '../../../ecomm/utils/moment';

@Pipe({
  name: 'orderScheduledTime'
})
export class OrderScheduledTimePipe implements PipeTransform {
  private readonly DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';
  private readonly TODAY_FORMAT = 'today';
  private readonly LATER_FORMAT = 'MMMM D, YYYY';
  private readonly TIME_FORMAT = 'LT';

  transform(scheduledDate: string, timeZone: string): string {
    if (timeZone) {
      /* convert API UTC to timeZone time */
      const date = moment.tz(scheduledDate, timeZone);

      /* get today using timeZone time */
      const today = moment(moment().tz(timeZone), this.DATE_TIME_FORMAT);

      const time = cond([
        [isToday(today), constant(this.TODAY_FORMAT)],
        [stubTrue, (d: moment.Moment) => d.format(this.LATER_FORMAT)]
      ])(date);
      const hasToday = time.includes(this.TODAY_FORMAT);
      if (hasToday) {
        return `Ordered ${time} at ${date.format(this.TIME_FORMAT)}`;
      }
      return `Ordered on ${time} at ${date.format(this.TIME_FORMAT)}`;
    }
    return '';
  }
}
