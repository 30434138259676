import { BaseModel } from '@wingstop/models/base.model';

export class UserFavoriteLocation extends BaseModel {
  id: number = null;
  name: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
