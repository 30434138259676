import { BaseModel } from '@wingstop/models/base.model';

export class BillingSchemeAccountBalance extends BaseModel {
  success: boolean = false;
  balance: number = 0;
  message: string = '';

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
