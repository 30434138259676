import { Component, OnInit } from '@angular/core';
import { FlavorSpiceHeading } from '../../../common';
import { ChangeContext, Options } from 'ngx-slider-v2';
import {
  MAX_HEAT_SCALE,
  MIN_HEAT_SCALE
} from '../../../common/constants/app-constants';
import { BehaviorSubject, combineLatest, map, Subscription, tap } from 'rxjs';
import { Flavor } from '../../../../ecomm/types/flavor.types';
import { Store } from '@ngrx/store';
import { FlavorsWorkflowService } from '../../../../ecomm/workflows/flavors/flavors-workflow.service';
import { StoreInfoFeature } from '../../../../ecomm/store/features/store-info';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';

@Component({
  selector: 'wri-home-page-flavor-section',
  templateUrl: './home-page-flavor-section.component.html',
  styleUrls: ['./home-page-flavor-section.component.scss']
})
export class HomePageFlavorSectionComponent implements OnInit {
  readonly flavorSpiceHeading = FlavorSpiceHeading;
  public readonly heatScaleSliderConfig: Options = {
    floor: MIN_HEAT_SCALE,
    ceil: MAX_HEAT_SCALE,
    showOuterSelectionBars: false
  };

  public selectedHeatScaleCount$ = new BehaviorSubject(MIN_HEAT_SCALE);
  public allFlavors$ = new BehaviorSubject<Flavor[]>([]);

  public selectedHeatScaleCount = MIN_HEAT_SCALE;
  public allFlavors: Flavor[] = [];
  loading = true;

  // Slide config
  public slideConfig = {
    slidesToScroll: 1,
    variableWidth: false,
    infinite: false,
    slidesToShow: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          arrows: true
        }
      }
    ]
  };

  public selectedFlavors$ = combineLatest([
    this.selectedHeatScaleCount$,
    this.allFlavors$
  ]).pipe(
    tap(([heatScale, flavors]) => {
      this.selectedHeatScaleCount = heatScale;
      this.allFlavors = flavors;
    }),
    map(([heatScale, flavors]) => {
      return flavors
        .filter((f) => f.isActive)
        .filter((f) => f.heatScale === heatScale);
    })
  );
  public selectedFlavors: Flavor[] = [];

  public storeInfo$ = this.store.select(StoreInfoFeature.selectStoreInfo);
  public storeSlug?: string;

  private subscription = new Subscription();

  constructor(
    private flavorsService: FlavorsWorkflowService,
    private store: Store,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectedFlavors$.subscribe((selectedFlavors) => {
        this.selectedFlavors = selectedFlavors;
      })
    );
    this.subscription.add(
      this.storeInfo$.subscribe(
        (storeInfo) =>
          (this.storeSlug = storeInfo?.storeDetails.slug ?? undefined)
      )
    );

    this.flavorsService.getFlavors(false).then((flavors) => {
      this.allFlavors$.next(flavors ?? []);
    });
  }

  onUserChange(changeContext: ChangeContext): void {
    this.selectedHeatScaleCount$.next(changeContext.value ?? MIN_HEAT_SCALE);
    this.analyticsService.logGaEvent({
      event: 'flavor_slide',      
    });
  }

  trackByItem(index: number, data: Flavor) {
    return `${index}-${data.id}`;
  }

  handleInit() {
    this.loading = false;
  }

  navigateToMenu() {
    this.router.navigate(['menu']);
  }
}
