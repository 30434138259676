import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DomService } from '@wingstop/services/dom.service';

@Directive({
  selector: '[trapFocus]',
})
export class TrapFocusDirective implements OnChanges, OnInit, OnDestroy {
  @Input() shouldTrapFocus = true;
  @Input() shouldHideRecaptcha = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    const isTabPressed = e.key === 'Tab' && !e.shiftKey;
    const isShiftTabPressed = e.shiftKey && e.key === 'Tab';

    if (!isTabPressed && !isShiftTabPressed) {
      return;
    }

    if (isTabPressed && document.activeElement === this.lastFocusableEl) {
      this.firstFocusableEl.focus();
      e.preventDefault();
    } else if (
      isShiftTabPressed &&
      document.activeElement === this.firstFocusableEl
    ) {
      this.lastFocusableEl.focus();
      e.preventDefault();
    }
  }

  public firstFocusableEl: HTMLElement;
  public lastFocusableEl: HTMLElement;

  constructor(private domService: DomService, private elRef: ElementRef) {}

  ngOnInit() {
    if (this.shouldTrapFocus) {
      this.setAriaHidden(true);
    } else {
      this.setAriaHidden(false);
    }

    setTimeout(() => {
      this.setFocusableElements();
    }, 0);
  }

  /**
   * The OnChanges method is useful when the shouldTrapFocus input is passed in
   * by an async pipe
   *
   * ex: <div trapFocus [shouldTrapFocus]="modalIsOpen$ | async"></div>
   **/
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldTrapFocus && changes.shouldTrapFocus.currentValue) {
      this.setAriaHidden(true);
      this.setFocusableElements();
    } else {
      this.setAriaHidden(false);
    }
  }

  ngOnDestroy() {
    this.setAriaHidden(false);
  }

  setAriaHidden(state: boolean): void {
    const stateString = `${state}`;
    const body =
      document.getElementsByClassName('modal-open')[0] ||
      document.getElementsByClassName('bottom-sheet-open')[0];
    if (!body) {
      return;
    }
    const container = body.querySelector('.container-fluid');
    if (container.getAttribute('aria-hidden') !== stateString) {
      container.setAttribute('aria-hidden', stateString);
    }

    if (this.shouldHideRecaptcha) {
      this.toggleCaptchaElements(stateString);
    }
  }

  setFocusableElements() {
    const [first, last] = this.domService.getFocusableElements(
      this.elRef.nativeElement
    );
    this.firstFocusableEl = first;
    this.lastFocusableEl = last;
  }

  toggleCaptchaElements(stateString: string) {
    const recaptchaContainer = document.querySelector(
      '.recaptcha-container'
    ) as HTMLElement;
    const iframes = document.getElementsByTagName('iframe');

    const toggleElements = (container: HTMLElement, className: string) => {
      if (!container) {
        return;
      }

      if (stateString === 'true') {
        container.classList.add(className);
      } else {
        container.classList.remove(className);
      }
    };

    toggleElements(recaptchaContainer, 'hideCaptcha');

    for (let i = 0; i < iframes.length; i++) {
      const recaptchaIframe =
        iframes[i].getAttribute('title') &&
        iframes[i].getAttribute('title').includes('captcha');

      if (!recaptchaIframe) {
        continue;
      }

      toggleElements(iframes[i], 'hideCaptcha');
    }
  }
}
