import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rx from 'rxjs/operators';

import { RegionalConfigurationService } from '../../../../ecomm/services/regional-configuration/regional-configuration.service';
import { StoreInfoService } from '../../../../ecomm/services/store-info/store-info.service';
import { MaybeResponse } from '../../../../ecomm/types/maybe-response';
import { RegionalConfiguration } from '../../../../ecomm/types/regional-configuration.types';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';

@Injectable({
  providedIn: 'root'
})
export class WingCalculatorService {
  private static readonly INIT_ERROR =
    'We ran into an issue loading the Wing Calculator. Please feel free to try again.';
  private static readonly SERVICE_MODE = 'carryout';
  private static readonly GLOBAL_MENU_SLUG_PROP = 'global_menu_slug';

  constructor(
    private regionalConfigurationService: RegionalConfigurationService,
    private storeInfoService: StoreInfoService,
    private notificationService: NotificationService,
    private redirectService: RedirectService
  ) {}

  private handleInitWingCalculatorError = <T>() =>
    rx.tap((res: MaybeResponse<T>) => {
      if (res.error) {
        this.notificationService.showError(WingCalculatorService.INIT_ERROR);
      }
    });

  private globalMenuFromRegionalConfig = () =>
    rx.switchMap(
      (
        rc: MaybeResponse<RegionalConfiguration>
      ): Observable<MaybeResponse<StoreInfo>> => {
        const options = rc.data?.regionalConfigurationOptions;
        if (
          options &&
          options['feature_enable_wing_calculator']?.value === 'false'
        ) {
          this.redirectService.redirectToLegacy('order/wing-calculator');
        }
        const globalMenuSlug =
          options?.[WingCalculatorService.GLOBAL_MENU_SLUG_PROP]?.value;
        return globalMenuSlug
          ? this.storeInfoService.getStoreInfoBySlug(
              globalMenuSlug,
              WingCalculatorService.SERVICE_MODE
            )
          : of({ error: WingCalculatorService.INIT_ERROR });
      }
    );

  init = () =>
    this.regionalConfigurationService
      .getRegionalConfiguration(WingCalculatorService.SERVICE_MODE)
      .pipe(
        this.globalMenuFromRegionalConfig(),
        this.handleInitWingCalculatorError()
      );
}
