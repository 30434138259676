import { AppState } from './app-state.model';
import { AppStateActions } from './app-state.actions';

export const AppStateInitial = new AppState();

export const AppStateReducer = (
  state: AppState = AppStateInitial,
  action: any
): AppState => {
  switch (action.type) {
    case AppStateActions.SET_BASKET_HANDOFF_MODE:
      // copy the basket
      let basketCopy = Object.assign({}, state.basket);
      // change the delivery mode
      basketCopy.deliverymode = action.payload;
      // update the state
      return { ...state, basket: basketCopy };

    case AppStateActions.ADD_GUEST_ADDRESS:
      // get the current list of addresses
      let addressesGuest = state.addressesGuest;
      // if the list isn't set, then the user has no guest addresses
      if (!addressesGuest) {
        // start the list
        addressesGuest = [];
      }

      // check to see if this new address is already in the list
      let result = addressesGuest.findIndex((current) => {
        return current.equals(action.payload);
      });

      // if this address is new
      if (result < 0) {
        // push the new address in
        addressesGuest.unshift(action.payload);
      }

      return { ...state, addressesGuest };
    case AppStateActions.MARK_BASKET_TIP:
      return { ...state, ...{ defaultTipAppliedTo: action.payload } };
    case AppStateActions.SEARCH_LOCATIONS:
      return { ...state, ...{ searchLocations: action.payload } };
    case AppStateActions.SET_LOCATION:
      return { ...state, ...state, ...{ selectedLocation: action.payload } };
    case AppStateActions.SET_MAP:
      return { ...state, ...state, ...{ googleMap: action.payload } };
    case AppStateActions.SET_MENU:
      return { ...state, ...state, ...{ selectedMenu: action.payload } };
    case AppStateActions.SET_GLOBAL_MENU:
      return { ...state, ...state, ...{ globalMenu: action.payload } };
    case AppStateActions.SET_PRODUCT:
      return {
        ...state,
        ...state,
        ...{ selectedProductModifiers: action.payload },
      };
    case AppStateActions.SET_PRODUCT_REDIRECT:
      return { ...state, ...state, ...{ productRedirect: action.payload } };
    case AppStateActions.SET_BASKET:
      return { ...state, ...state, ...{ basket: action.payload } };
    case AppStateActions.SET_DETAILED_BASKET_DELIVERY_ADDRESS:
      return {
        ...state,
        ...state,
        ...{ detailedBasketDeliveryAddress: action.payload },
      };
    case AppStateActions.CONFIRMATION_PAGE_VIEWED:
      return {
        ...state,
        ...state,
        ...{ confirmationPageViewed: action.payload },
      };
    case AppStateActions.BASKET_TRANSFER:
      return {
        ...state,
        ...state,
        ...{
          basket: action.payload,
          itemsnottransferred: action.payload.itemsnottransferred,
        },
      };
    case AppStateActions.CLEAR_NON_TRANSFERABLE:
      return { ...state, ...state, ...{ itemsnottransferred: null } };
    case AppStateActions.SET_ERRORS:
      return { ...state, ...state, ...{ errors: action.payload } };
    case AppStateActions.USER_ADDRESSES:
      return { ...state, ...state, ...{ addresses: action.payload } };
    case AppStateActions.USER_BILLING_ACCOUNTS:
      return { ...state, ...state, ...{ billingAccounts: action.payload } };
    case AppStateActions.USER_FAVORITE_LOCATIONS:
      return { ...state, ...state, ...{ favoriteLocations: action.payload } };
    case AppStateActions.FAVORITE_ORDERS:
      return { ...state, ...state, ...{ favoriteOrders: action.payload } };
    case AppStateActions.AUTHENTICATE:
      return { ...state, ...state, ...{ authentication: action.payload } };
    case AppStateActions.RECENT:
      return { ...state, ...state, ...{ recent: action.payload } };
    case AppStateActions.USER_CONTACT_OPTIONS:
      return { ...state, ...state, ...{ contactOptions: action.payload } };
    case AppStateActions.USER_CONTACT_DETAILS:
      return { ...state, ...state, ...{ contactDetails: action.payload } };
    case AppStateActions.ADD_RECENT:
      return {
        ...state,
        ...state,
        ...{ recent: [...state.recent, action.payload] },
      };
    case AppStateActions.OPEN_ALERT_MODAL:
      return {
        ...state,
        ...state,
        ...{ openAlert: true, alertContent: action.payload },
      };
    case AppStateActions.CLOSE_ALERT_MODAL:
      return { ...state, ...state, ...{ openAlert: false } };
    case AppStateActions.CONFIRM_ALERT:
      return { ...state, ...state, ...{ confirmAlert: true } };
    case AppStateActions.DISCARD_ALERT:
      return { ...state, ...state, ...{ confirmAlert: false } };
    case AppStateActions.SET_FAVORITE_NAME:
      return { ...state, ...state, ...{ favoriteName: action.payload } };
    case AppStateActions.SET_REDIRECT:
      return { ...state, ...state, ...{ redirect: action.payload } };
    case AppStateActions.SET_REMEMBER_ME:
      return { ...state, ...state, ...{ rememberMe: action.payload } };
    case AppStateActions.USER_LOGGED_OUT:
      return { ...state, ...state, ...{ logout: true } };
    case AppStateActions.SET_LAST_SESSION_ORDER:
      return { ...state, ...state, ...{ lastSessionOrder: action.payload } };
    case AppStateActions.SET_CLUB_FORM:
      return { ...state, ...state, ...{ lastSessionOrder: action.payload } };
    case AppStateActions.SET_DEFAULT_TIP:
      return { ...state, ...state, ...{ defaultTip: true, userTip: false } };
    case AppStateActions.SET_USER_TIP:
      return { ...state, ...state, ...{ defaultTip: false, userTip: true } };
    case AppStateActions.SET_TIP_INDEX:
      return { ...state, ...state, ...{ tipIndex: action.payload } };
    case AppStateActions.SET_CMS_OFFER:
      return { ...state, ...state, ...{ cmsOffer: action.payload } };
    case AppStateActions.SET_CMS_OFFER_REDEEM_CODE:
      return { ...state, ...state, ...{ cmsOfferRedeemCode: action.payload } };
    case AppStateActions.SET_CMS_OFFER_TIMESTAMP:
      return { ...state, ...state, ...{ cmsOfferTimestamp: action.payload } };
    case AppStateActions.SET_TOOLTIP_SEEN:
      return { ...state, ...state, ...{ toolTipSeen: action.payload } };
    case AppStateActions.GEOLOCATION_ALLOWED:
      return { ...state, ...state, ...{ geolocationAllowed: action.payload } };
    case AppStateActions.GUEST_CHECKOUT_EMAIL:
      return { ...state, ...state, ...{ guestCheckoutEmail: action.payload } };
    case AppStateActions.SET_CURRENT_PRODUCT_SELECTIONS:
      return {
        ...state,
        ...state,
        ...{ currentProductSelections: action.payload },
      };
    case AppStateActions.ADD_UPSELL_PRODUCT:
      return { ...state, ...state, ...{ upsellProducts: action.payload } };
    case AppStateActions.REMOVE_UPSELL_PRODUCT:
      return { ...state, ...state, ...{ upsellProducts: action.payload } };
    case AppStateActions.CLEAR_UPSELL_PRODUCTS:
      return { ...state, ...state, ...{ upsellProducts: action.payload } };
    case AppStateActions.SET_SECRET_MENU_FEATURE_FLAG:
      return { ...state, ...state, ...{ showSecretMenu: action.payload } };
    case AppStateActions.SET_CREATE_USER_ERROR:
      return { ...state, ...{ createUserError: action.payload } };
    case AppStateActions.USER_HAS_SEEN_LOCALE_MODAL:
      return {
        ...state,
        ...state,
        ...{ userHasSeenTheLocaleModal: action.payload },
      };
    case AppStateActions.SET_USER_REGISTRATION_FEATURE_FLAG:
      return {
        ...state,
        ...state,
        ...{ showUserRegistration: action.payload },
      };
    case AppStateActions.SET_USER_FIRST_THREE_MONTHS:
      return {
        ...state,
        ...state,
        ...{ userFirstThreeMonths: action.payload },
      };
    case AppStateActions.SET_IS_DIGITAL_MENU:
      return { ...state, ...state, ...{ isDigitalMenu: action.payload } };
    case AppStateActions.SET_START_ORDER_SOURCE:
      return { ...state, ...state, ...{ startOrderSource: action.payload } };
    case AppStateActions.SET_IS_REORDER:
      return { ...state, ...state, ...{ isReorder: action.payload } };
    case AppStateActions.SET_DIGITAL_MENU_SCROLL_TO:
      return { ...state, ...state, ...{ scrollToCategory: action.payload } };
    case AppStateActions.SET_DIGITAL_MENU_LOADED:
      return { ...state, ...state, ...{ digitalMenuLoaded: action.payload } };
    case AppStateActions.SET_PAYMENT_PROVIDER:
      return { ...state, ...state, ...{ paymentProvider: action.payload } };
    case AppStateActions.SET_FISERV_USER:
      return { ...state, ...state, ...{ fiservUser: action.payload } };
    case AppStateActions.SET_FISERV_SESSION:
      return { ...state, ...{ fiservSession: action.payload } };
    case AppStateActions.SET_LOCATION_SUPPORTS_PREPAID:
      return { ...state, ...{ locationSupportsPrepaid: action.payload } };
    case AppStateActions.SET_FISERV_IFRAME_RESPONSE:
      return { ...state, ...{ fiservIframeResponse: action.payload } };
    case AppStateActions.SET_IN_SUBMISSION_FLOW:
      return { ...state, ...{ inSubmissionFlow: action.payload } };
    case AppStateActions.SET_ROUND_UP_SELECTED:
      return { ...state, ...state, ...{ roundUpSelected: action.payload } };
    case AppStateActions.SET_ROUND_UP_AMOUNT:
      return { ...state, ...state, ...{ roundUpAmount: action.payload } };
    case AppStateActions.SET_COKE_FREESTYLE:
      return { ...state, ...state, ...{ cokeFreestyle: action.payload } };
    case AppStateActions.SET_ROUND_UP_DONATION_FEATURE_FLAG:
      return { ...state, ...state, ...{ showRoundUp: action.payload } };
    case AppStateActions.SET_COKE_FREESTYLE_FEATURE_FLAG:
      return { ...state, ...state, ...{ showCokeFreestyle: action.payload } };
    case AppStateActions.SET_APPLE_PAY_FEATURE_FLAG:
      return { ...state, ...state, ...{ showApplePay: action.payload } };
    case AppStateActions.SET_SHOW_APP_BANNER:
      return { ...state, ...state, ...{ showAppBanner: action.payload } };
    case AppStateActions.SET_APP_BANNER_CLOSED_BY_USER:
      return {
        ...state,
        ...state,
        ...{ appBannerClosedByUser: action.payload },
      };
    case AppStateActions.SET_APP_DOWNLOAD_BANNER_FEATURE_FLAG:
      return {
        ...state,
        ...state,
        ...{ appDownloadBannerEnabled: action.payload },
      };
    case AppStateActions.SET_USER_LOCALE:
      return { ...state, ...state, ...{ userLocale: action.payload } };
    case AppStateActions.SET_S3_SEO_METADATA:
      return { ...state, ...state, ...{ s3SeoMetadata: action.payload } };
    case AppStateActions.SET_PILOT_PROGRAM_FEATURE_FLAGS:
      return { ...state, ...state, ...{ pilotProgram: action.payload } };
    case AppStateActions.SET_PILOT_PROGRAM_USER:
      return { ...state, ...state, ...{ pilotProgramUser: action.payload } };
    case AppStateActions.SET_LAST_FORCE_LOGOUT_DATE:
      return { ...state, ...{ lastForceLogoutDate: action.payload } };
    default:
      return state;
  }
};
