import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wri-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextInputComponent)
    }
  ]
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() id = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() maxlength = NaN;
  @Input() formatter = (input: string) => input;
  @Input() type = 'text';
  @Input() ariaLabel = `Input for ${this.label}`;
  @Input() ariaPressed = false;
  @Input() isToggleable = false;
  @Input() autoComplete = 'off';
  @Input() disabled = false;
  @Input() showIcon = false;
  @Input() showLockIcon = false;
  @Input() icon = '';
  @Input() preventSlashKey = false;

  /** For inline errors when form invalidity triggered externally */
  @Input()
  public isFormValid = true;

  private _textValue = '';

  set textValue(textValue: string) {
    this._textValue = textValue;
    this.onChange(this.textValue);
  }

  get textValue(): string {
    return this._textValue;
  }

  @Output()
  blurTextValue = new EventEmitter<string>();

  @Output()
  focusTextValue = new EventEmitter<string>();

  @Output()
  iconClick = new EventEmitter<string>();

  public updateValue(event: Record<string, Record<string, string>>) {
    const textValue = event['target']['value'];
    const trimmed = this.trimToMax(textValue);
    const formatted = this.formatter(trimmed);
    event['target']['value'] = formatted;
    this.textValue = formatted;
  }

  @Input()
  public canShowErrors = false;
  public hideErrors() {
    this.canShowErrors = false;
    this.onTouched();
  }
  public onFocus() {
    this.onTouched();
    this.focusTextValue.emit(this.textValue);
  }
  public showErrors() {
    this.canShowErrors = true;
    this.onTouched();
    this.blurTextValue.emit(this.textValue);
  }

  /** Implement ControlValueAccessor */
  touched = false;
  onChange: (v: string) => void = () => void 0;
  onTouched: () => void = () => void 0;

  writeValue(newValue: string): void {
    this.textValue = newValue;
  }
  registerOnChange(onChange: (v: string) => void): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private trimToMax(value: string) {
    return Number.isNaN(this.maxlength)
      ? value
      : value.substring(0, Math.abs(this.maxlength));
  }

  preventNonNumericalInput = (e: KeyboardEvent) => {
    const target = e.target as HTMLTextAreaElement;
    if (target.type === 'number' && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
    if (this.preventSlashKey && e.code == 'Slash') e.preventDefault();
  };
}
