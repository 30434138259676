<ng-container [ngSwitch]="template">
  <ng-container *ngSwitchCase="'offerModal'">
    <ng-container *ngTemplateOutlet="offerModal"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'errorOverrideModal'">
    <ng-container *ngTemplateOutlet="errorOverrideModal"></ng-container>
  </ng-container>
</ng-container>

<ng-template #offerModal>
  <div
    class="card cms-offer-modal text-center"
    [id]="'cms_offer_modal_' + modalData.id"
    trapFocus>
    <button (click)="closeModal(true)" class="close-btn" aria-label="Close">
      <svg type="close" aria-hidden="true"></svg>
    </button>
    <img
      class="card-img-top"
      [ngBusy]="{ busy: imgLoading }"
      [src]="backgroundImage"
      [alt]="modalData.imageAlt" />
    <div class="card-body">
      <h1 class="card-title h3" id="cms-offer-modal-title">
        {{ modalData.offerTitle }}
      </h1>
      <div class="card-text" [innerHTML]="modalData.body"></div>
      <div
        class="row button-row mb-2"
        *ngIf="modalData.cta1Label || modalData.cta2Label">
        <div
          *ngIf="modalData.cta1Label"
          [ngClass]="!modalData.cta2Label ? 'col-12' : 'col-md-6 mb-3 mb-md-0'">
          <button
            class="btn btn-outline-primary w-100"
            (click)="ctaAction(modalData.cta1Uri, 'primary')"
            id="cms_offer_modal_primary_cta">
            {{ modalData.cta1Label }}
          </button>
        </div>
        <div
          *ngIf="modalData.cta2Label"
          [ngClass]="!modalData.cta1Label ? 'col-12' : 'col-md-6'">
          <button
            class="btn btn-primary w-100"
            (click)="ctaAction(modalData.cta2Uri, 'secondary')"
            id="cms_offer_modal_secondary_cta">
            {{ modalData.cta2Label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #errorOverrideModal>
  <div
    class="card cms-offer-modal text-center"
    [id]="'cms_error_override_modal_' + modalData.id"
    trapFocus>
    <button (click)="closeModal()" class="close-btn" aria-label="Close">
      <svg type="close" aria-hidden="true"></svg>
    </button>
    <img class="card-img-top" [src]="modalData.codeApplyErrorImage" alt="" />
    <div class="card-body">
      <h3 class="card-title" id="error-override-modal-title">
        {{ modalData.codeApplyErrorTitle }}
      </h3>
      <p class="card-text">{{ modalData.codeApplyErrorMessageTextOverride }}</p>
      <div
        class="row button-row mb-2"
        *ngIf="
          modalData.codeApplyErrorCta1Label || modalData.codeApplyErrorCta2Label
        ">
        <div
          *ngIf="modalData.codeApplyErrorCta1Label"
          [ngClass]="
            !modalData.codeApplyErrorCta2Label
              ? 'col-12'
              : 'col-md-6 mb-3 mb-md-0'
          ">
          <button
            class="btn btn-outline-primary w-100"
            (click)="errorCtaAction(modalData.codeApplyErrorCta1Uri)"
            id="cms_error_override_modal_primary_cta">
            {{ modalData.codeApplyErrorCta1Label }}
          </button>
        </div>
        <div
          *ngIf="modalData.codeApplyErrorCta2Label"
          [ngClass]="
            !modalData.codeApplyErrorCta1Label ? 'col-12' : 'col-md-6'
          ">
          <button
            class="btn btn-primary w-100"
            (click)="errorCtaAction(modalData.codeApplyErrorCta2Uri)"
            id="cms_error_override_modal_secondary_cta">
            {{ modalData.codeApplyErrorCta2Label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
