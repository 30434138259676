import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'oidc-client-ts';
import { filter, firstValueFrom, Subscription } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { StoreInfoWorkflowService } from '../../../../../public-api';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { RegionalConfigurationFeature } from '../../../../ecomm/store/features/regional-configuration';
import { Store } from '@ngrx/store';
import {
  AuthFeature,
  AuthFeatureState
} from '../../../../ecomm/store/features/auth';

@Component({
  selector: 'wri-auth-navigation',
  templateUrl: './auth-navigation.component.html',
  styleUrls: ['./auth-navigation.component.scss']
})
export class AuthNavigationComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  showOnlyLogoutButton = false;
  isUserLoggedIn = false;
  private PING_AUTH_COOKIE = 'pingAuth';
  private PING_AUTH_PROFILE_COOKIE = 'pingAuthProfile';

  @Output()
  loginButtonClick = new EventEmitter<boolean>(false);

  @Input() test: string | undefined;

  @ViewChild('logoutConfirmationPopupModal')
  logoutConfirmationPopupModal!: TemplateRef<HTMLElement>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private redirectService: RedirectService,
    private analyticsService: AnalyticsService,
    private storeInfoService: StoreInfoWorkflowService,
    private featureFlagService: FeatureFlagService,
    private store: Store
  ) {}

  openCommonModal($event: MouseEvent) {
    $event.preventDefault();
    this.modalService.open(this.logoutConfirmationPopupModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
    this.analyticsService.logGaEvent({
      event: 'account_click_option',
      account_option_value: 'Logout'
    });
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToAuthState();
    await this.getUserFromOidc();
    this.subscription.add(
      await this.authService.signInEvents.subscribe(() =>
        this.getUserFromOidc()
      )
    );
  }

  private subscribeToAuthState(): void {
    const authState$ = this.store
      .select(AuthFeature.selectAuthState)
      .pipe(filter<AuthFeatureState>(Boolean));

    this.subscription.add(
      authState$.subscribe((state) => {
        this.isUserLoggedIn = state.isAuthenticated;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private async getUserFromOidc() {
    const cookiePingAuth = this.cookieService.get(this.PING_AUTH_COOKIE);
    const cookiePingAuthProfile = this.cookieService.get(
      this.PING_AUTH_PROFILE_COOKIE
    );

    if (cookiePingAuth) {
      const credentialsPingAuth = JSON.parse(cookiePingAuth);

      const { profile } = JSON.parse(cookiePingAuthProfile);
      if (!this.isTokenExpired(credentialsPingAuth.access_token)) {
        const user = new User({
          ...credentialsPingAuth,
          profile
        });

        await this.authService.userManager.storeUser(user).then(async () => {
          await this.authService.saveAuthState();
          this.isUserLoggedIn = true;
          this.deleteAuthCookies();
        });
      } else {
        await this.ifCookieNotExist();
      }
    } else {
      await this.ifCookieNotExist();
    }
  }

  private async ifCookieNotExist() {
    this.deleteAuthCookies();
    const auth = await this.authService.getCurrentAuth();
    this.isUserLoggedIn = auth.isAuthenticated;
  }

  private deleteAuthCookies() {
    this.cookieService.delete(this.PING_AUTH_COOKIE, '/', '.wingstop.com');
    this.cookieService.delete(
      this.PING_AUTH_PROFILE_COOKIE,
      '/',
      '.wingstop.com'
    );
  }

  async onCtaClick($event: MouseEvent, s: string) {
    $event.preventDefault();
    const encodeUrlPath = encodeURIComponent(this.router.url);
    const regionalConfigOptions = await firstValueFrom(
      this.store.select(
        RegionalConfigurationFeature.selectRegionalConfigurationOptions
      )
    );
    if (s === '/account/login') {
      if (this.featureFlagService.featureFlags['enableLogin']) {
        if (
          regionalConfigOptions &&
          regionalConfigOptions['feature_enable_login']?.value === 'false'
        ) {
          this.redirectService.redirectToLegacy(
            `account/login?ngfe_auth&ngfe_url=${encodeUrlPath}`
          );
        } else {
          this.loginButtonClick.emit(true);
          await this.authService.login(this.router.url);
          this.loginButtonClick.emit(false);
        }
      } else {
        this.redirectService.redirectToLegacy(
          `account/login?ngfe_auth&ngfe_url=${encodeUrlPath}`
        );
      }
      this.analyticsService.logGaEvent({
        event: 'account_click_option',
        account_option_value: 'Log In'
      });
    }

    if (s === '/account/signup') {
      if (this.featureFlagService.featureFlags['enableAccountRegister']) {
        if (
          regionalConfigOptions &&
          regionalConfigOptions['feature_enable_account_register']?.value ===
            'false'
        ) {
          this.redirectService.redirectToLegacy(
            `account/signup?ngfe_auth&ngfe_url=${encodeUrlPath}`
          );
        } else {
          this.router.navigate(['/account/signup']);
        }
      } else {
        this.redirectService.redirectToLegacy(
          `account/signup?ngfe_auth&ngfe_url=${encodeUrlPath}`
        );
      }

      this.analyticsService.logGaEvent({
        event: 'account_click_option',
        account_option_value: 'Sign Up'
      });
    }

    if (s === '/account/settings') {
      this.analyticsService.logGaEvent({
        event: 'account_click_option',
        account_option_value: 'My Account'
      });
      this.router.navigate(['/account/settings']);
    }

    if (s === '/order/recent') {
      this.analyticsService.logGaEvent({
        event: 'account_click_option',
        account_option_value: 'My Orders'
      });
      this.router.navigate(['/order/recent']);
    }
  }

  handleOnLogout($event: MouseEvent) {
    $event.preventDefault();
    this.deleteAuthCookies();
    this.isUserLoggedIn = false;
    this.authService.logout().then(async () => {
      // Some issues, it's not coming in success block
    });
    this.analyticsService.logGaEvent({
      event: 'logout'
    });
    this.analyticsService.logGaEvent({
      user_id: null
    });
  }

  private isTokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
