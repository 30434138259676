import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  Subscription,
  switchMap,
  tap
} from 'rxjs';

import {
  Config,
  CONFIG
} from '../../../../ecomm/providers/config/config.provider';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataState,
  structuredDataThing
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { RegionSummary } from '../../../../ecomm/types/search-location.types';
import { SearchLocationWorkflowService } from '../../../../ecomm/workflows/search-location/search-location-workflow.service';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { Store } from '@ngrx/store';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { UNKNOWN_ERROR } from '../../../../ecomm/utils/throw-error';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { PartialOutageModalComponent } from '../../../common';

type StateDetailsData = {
  stateSlug: string;
  stateName: string;
};

@Component({
  selector: 'wri-state-details',
  styleUrls: ['./state-details.component.scss'],
  template: `
    <wri-partial-outage-modal></wri-partial-outage-modal>
    <ng-container *ngIf="isLoading; then loading; else loaded"></ng-container>
    <ng-template #loading>
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>
    </ng-template>

    <ng-template #loaded>
      <div>
        <section class="location-banner">
          <div class="location-name">
            {{ bannerTitle }}
          </div>
          <button
            class="wri-btn wri-btn-secondary wri-btn-override-hover"
            aria-label="Find a location"
            (click)="navigateToOrder()"
          >
            Find a location
          </button>
        </section>
        <section
          *ngIf="!details?.cities || details.cities.length === 0"
          class="empty-state"
        >
          <div class="no-locations-found">
            <div class="icon-round">
              <wri-icon icon="wri-location"></wri-icon>
            </div>
            <div>NO AVAILABLE LOCATIONS</div>
            <p>
              Looks like there are no Wingstop locations yet in Maine.
              <a
                wriFocusOutline
                role="button"
                [attr.aria-label]="'Search a new area'"
                (click)="navigateToOrder()"
                class="btn-search-new-area"
              >
                Search a new area.</a
              >
            </p>
          </div>
        </section>
        <section *ngIf="details?.cities?.length > 0" class="cities-container">
          <div *ngFor="let city of details | pick : 'cities'" class="city-item">
            <a
              class="city-name"
              wriFocusOutline
              tabindex="0"
              (click)="navigateToLocality(city.name)"
            >
              {{ city.name }}
            </a>
            <div class="city-counter">({{ city.count }})</div>
          </div>
        </section>
        <section class="about-section">
          <div class="about-title">
            {{ aboutSectionTitle }}
          </div>
          <div class="about-desc" [innerHTML]="formattedAboutSection"></div>
        </section>
      </div>
    </ng-template>
  `
})
export class StateDetailsComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public data: StateDetailsData | null = null;
  public details: RegionSummary | null | undefined = null;
  public storeInfo: StoreInfo | null = null;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchLocationService: SearchLocationWorkflowService,
    private store: Store,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(CONFIG) private config: Config,
    private notificationService: NotificationService,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private redirectService: RedirectService
  ) {
    const data$: Observable<StateDetailsData> = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.params
    ]).pipe(
      map(([data, params]) => ({ ...data, ...params } as StateDetailsData)),
      tap((data) => (this.data = data))
    );

    const details$ = data$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap(() =>
        this.searchLocationService
          .getRegionSummary()
          .then(this.reportErrorIfNull.bind(this))
      ),
      tap((allRegions) => {
        const details = allRegions?.find(
          (region) => region.slug === this.data?.stateSlug
        );
        if (details?.cities) {
          details.cities.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.details = details;
      }),
      tap(() => (this.isLoading = false)),
      tap(this.setSeoMetaData.bind(this)),
      tap(this.setStructuredData.bind(this))
    );

    this.subscription.add(details$.subscribe());
  }

  public get stateName() {
    return this.data?.stateName;
  }

  public get aboutSectionTitle() {
    return `For Great Wings in ${this.stateName}: Get Wingstop`;
  }

  public get formattedAboutSection() {
    return this.config.baseAboutSection.replace(
      /\[State]/gi,
      this.stateName ?? ''
    );
  }

  public get getFriendlyLastBreadcrumbUrl() {
    return this.document.location.origin + this.document.location.pathname;
  }

  public get bannerTitle() {
    return `Wingstop Locations in ${this.stateName}`;
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToStoreInfoState();
    this.subscribeToRegionalConfigState();

    const regionalConfig =
      await this.storeInfoWorkflowService.getRegionalConfigurationAndSave(
        'carryout',
        false
      );

    if (
      regionalConfig?.regionalConfigurationOptions[
        'feature_enable_state_routes'
      ]?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy();
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  public navigateToOrder() {
    this.router.navigate(['/order/']);
  }

  public navigateToLocality(city: string) {
    const url = `/${this.data?.stateSlug}/${this.removeSpaces(city)}`;
    this.router.navigate([url]);
  }

  public removeSpaces(string: string) {
    return string.replace(/ /g, '-').toLowerCase();
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfo = state.storeInfo;
      })
    );
  }

  private reportErrorIfNull = (
    res: RegionSummary[] | null
  ): RegionSummary[] | null => {
    if (res === null) {
      this.notificationService.showError(UNKNOWN_ERROR);
    }
    return res;
  };

  private setSeoMetaData = () => {
    this.seoService.setMetaData({
      title: `${this.stateName} - Information - Wings Restaurant | Wingstop`,
      description: `View all Wingstop wing locations in ${this.stateName}`
    });
  };

  private setStructuredData = () => {
    const regionName = this.stateName;
    if (regionName) {
      this.seoService.addStructuredData([
        structuredDataThing(
          this.document.location.origin,
          this.formattedAboutSection
        ),
        structuredDataState(regionName),
        structuredDataBreadcrumbList([
          {
            name: 'Home',
            url: this.document.location.origin
          },
          {
            name: regionName,
            url: this.getFriendlyLastBreadcrumbUrl
          }
        ])
      ]);
    }
  };
}
