import { BaseModel } from '@wingstop/models/base.model';
import { Reward } from '@wingstop/models/loyalty/reward.model';

export class Membership extends BaseModel {
  balance: number;
  description: string;
  id: number;
  membershipnumber: string;
  rewards: Reward[];

  constructor(values?: any) {
    super();
    if (values) {
      if (values.rewards) {
        values.rewards = values.rewards.map((value: any) => new Reward(value));
      }
    }
    this.initialize(values);
  }
}
