import {
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferDetails } from '../../../../ecomm/types/offer-details.types';
import { ActiveOfferFeature } from '../../../../ecomm/store/features/active-offer';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { FeaturesState } from '../../../../ecomm/store/types/features-state';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { AsynchronousDispatcher } from '../../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { Router } from '@angular/router';
import { EcommAPIError } from '../../../../ecomm/types/common.types';

@Component({
  selector: 'wri-active-offer',
  templateUrl: './active-offer.component.html',
  styleUrls: ['./active-offer.component.scss']
})
export class ActiveOfferComponent implements OnInit {
  public activeOffer: string | null = null;
  public storeInfoState: StoreInfoFeatureState | undefined;
  public cartState: CartFeatureState | undefined;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  public offerDetails: OfferDetails | null = null;
  public offerErrorResponse: EcommAPIError | null = null;
  public isLoading = false;
  private subscription = new Subscription();

  private static readonly ALLOWED_TIMEZONES = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu'
  ];

  @Input() showTag = true;

  constructor(
    private store: Store,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private featureFlagService: FeatureFlagService,
    @Inject(AsynchronousDispatcher)
    private asynchronousDispatcher: AsynchronousDispatcher<FeaturesState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToActiveOfferState();
    this.subscribeToStoreInfoState();
    this.subscribeToCartState();
  }

  async openOfferModal() {
    this.isLoading = true;
    this.modalService.open(this.commonModal, {
      windowClass: 'common-modal active-offer-modal',
      centered: true,
      size: 'md'
    });
    await this.getOfferDetails();
    this.analyticsService.logGaEvent({
      event: 'active_offer_icon_tap'
    });
  }

  private subscribeToStoreInfoState(): void {
    const storeInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter(Boolean));

    this.subscription.add(
      storeInfo$.subscribe((state) => {
        this.storeInfoState = state;
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartState = state;
      })
    );
  }

  private async getOfferDetails() {
    this.offerErrorResponse = null;
    if (!this.activeOffer) return;
    const storeId = this.storeInfoState?.storeInfo?.storeDetails.id;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const hasTimeZone =
      ActiveOfferComponent.ALLOWED_TIMEZONES.includes(timeZone);
    const getOfferDetails = storeId
      ? await this.storeInfoWorkflowService.getOfferDetailsWithErrorObject(
          this.activeOffer,
          storeId
        )
      : await this.storeInfoWorkflowService.getOfferDetailsByTimezoneWithErrorObject(
          this.activeOffer,
          hasTimeZone ? timeZone : ''
        );
    console.log('getOfferDetails', getOfferDetails);

    if (getOfferDetails?.data) {
      this.offerDetails = getOfferDetails.data;
      this.logGaEventOnGetOfferDetails(getOfferDetails.data);
    } else if (getOfferDetails?.error) {
      this.handleErrorState(getOfferDetails.error);
    }
    this.isLoading = false;
  }

  private handleErrorState(error: EcommAPIError) {
    this.offerErrorResponse = error;
    this.asynchronousDispatcher.dispatch(
      ActiveOfferFeature.actions.setState({
        activeOffer: null,
        activeOfferDetails: null
      })
    );
  }

  private logGaEventOnGetOfferDetails(result: OfferDetails) {
    try {
      this.analyticsService.logGaEvent({
        event: 'view_promotion',
        ecommerce: {
          items: [
            {
              item_id: result.id,
              item_name: result.name == '' ? result.code : result.name,
              promotion_id: result.id,
              promotion_name: result.name == '' ? result.code : result.name,
              location_id:
                this.cartState?.cart?.locationId == undefined
                  ? ''
                  : this.cartState?.cart?.locationId
            }
          ]
        }
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  private subscribeToActiveOfferState(): void {
    const activeOfferState$ = this.store
      .select(ActiveOfferFeature.selectActiveOfferState)
      .pipe(filter(Boolean));

    this.subscription.add(
      activeOfferState$.subscribe((state) => {
        this.activeOffer = state.activeOffer;
      })
    );
  }

  onCtaClick($event: MouseEvent, url: string) {
    $event.preventDefault();
    // universal, non case-sensitive, protocol-agnostic regex
    // eg: ftp://example.com/file.txt - isAbsoluteUrl
    // eg: HTTP://EXAMPLE.COM | https://www.exmaple.com - isAbsoluteUrl
    // eg: ftp://example.com/file.txt | //cdn.example.com/lib.js - isAbsoluteUrl
    // eg: /myfolder/test.txt - !isAbsoluteUrl
    const isAbsoluteUrl = new RegExp('^(?:[a-z+]+:)?//', 'i').test(url)
      ? true
      : false;
    if (isAbsoluteUrl) {
      window.location.href = url;
    } else {
      this.modalService.dismissAll();
      this.router.navigate([url]);
    }
  }

  get isEnableOfferModalWithMetadata() {
    return this.featureFlagService.featureFlags.enableOfferModalWithMetadata;
  }
}
