import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  componentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  appendComponentToBody(component: any) {
    // Create a component reference from the component
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);
  }

  removeComponent() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
    }
  }

  componentInit(component: any) {
    this.appendComponentToBody(component);
    return this.componentRef.instance;
  }

  /**
   * Returns first and last focusable elements inside of a given element based on specific CSS selector
   */
  getFocusableElements(element: HTMLElement): HTMLElement[] {
    const FOCUSABLE_ELEMENTS_SELECTOR = [
      'a[href]',
      'button:not([disabled])',
      'input:not([disabled]):not([type="hidden"])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      '[contenteditable]',
      '[tabindex]:not([tabindex="-1"])',
    ].join(', ');

    const list: HTMLElement[] = Array.from(
      element.querySelectorAll(
        FOCUSABLE_ELEMENTS_SELECTOR
      ) as NodeListOf<HTMLElement>
    ).filter((el) => el.tabIndex !== -1);
    return [list[0], list[list.length - 1]];
  }
}
