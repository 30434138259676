import {
  FiservGiftCard,
  FiservGiftCardResponse,
  FiservPaymentDTO,
  FiservSessionResponse,
  FiservUser,
} from '@wingstop/models/order/fiserv-payments.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Authentication } from '@wingstop/models/login/authentication.model';
import { Banner } from '@wingstop/models/cms/banner.model';
import { Basket } from '@wingstop/models/basket.model';
import { BasketProduct } from '@wingstop/models/basket/basket-product.model';
import { BillingScheme } from '@wingstop/models/order/billing-scheme.model';
import { BillingSchemeAccountBalance } from '@wingstop/models/order/billing-scheme-account-balance.model';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';
import { City } from '@wingstop/models/location/location-city.model';
import { ClubModel } from '@wingstop/models/club.model';
import { CmsFeatureFlag } from '@wingstop/models/cms/feature-flag.model';
import { ContactDetails } from '@wingstop/models/login/contact-details.model';
import { ContactOptions } from '@wingstop/models/login/contact-options.model';
import { CustomField } from '@wingstop/models/order/custom-field.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { DeliveryStatus } from '@wingstop/models/order/delivery-status.model';
import { FavoriteOrder } from '@wingstop/models/order/favorite-order.model';
import { Flavor } from '@wingstop/models/cms/flavor.model';
import { GeocodeResponse } from '@wingstop/models/geocode-response.model';
import { GlobalService } from '@wingstop/services/global.services';
import { Injectable } from '@angular/core';
import { Location } from '@wingstop/models/location/location.model';
import { LocationDisclaimer } from '@wingstop/models/location/location-disclaimer.model';
import { Login } from '@wingstop/models/login.model';
import { LoyaltyScheme } from '@wingstop/models/loyalty/loyalty-scheme.model';
import { Menu } from '@wingstop/models/menu.model';
import { Modal } from '@wingstop/models/modal.model';
import { Modifier } from '@wingstop/models/menu/option.model';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';
import { PaymentData } from '@wingstop/models/basket/payment-data.model';
import { PlacesResponse } from '@wingstop/models/places-response.model';
import { ResponseCacheService } from '@wingstop/services/response-cache.service';
import { Reward } from '@wingstop/models/loyalty/reward.model';
import { SSOUser } from '@wingstop/models/SSOUser.model';
import { Schedule } from '@wingstop/models/order/schedule.model';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { SignUp } from '@wingstop/models/login/sign-up.model';
import { SocialUser } from '@abacritt/angularx-social-login';
import { State } from '@wingstop/models/location/location-state.model';
import { TimeWanted } from '@wingstop/models/time-wanted.model';
import { TokenizedCreditCard } from '@wingstop/models/basket/tokenized-credit-card.model';
import { UpdateContactOptions } from '@wingstop/models/login/update-contact-options.model';
import { UpdatePassword } from '@wingstop/models/login/update-password.model';
import { UpdateUser } from '@wingstop/models/login/update-user.model';
import { UserBillingAccount } from '@wingstop/models/login/user-billing-account.model';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';
import { UserFavoriteLocation } from '@wingstop/models/login/user-favorite-location.model';
import { environment } from '@wingstop/environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment-mini';
import { noop } from 'rxjs';

enum SortDirection {
  'ASC' = 'asc',
  'DESC' = 'desc',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public nomnomHeaders = new HttpHeaders().set(
    'locale',
    navigator && navigator.language
      ? navigator.language.toLowerCase()
      : 'en-us'.toLowerCase()
  );

  constructor(
    protected http: HttpClient,
    protected responseCache: ResponseCacheService
  ) { }

  private sortByWeightAndDirection = (
    data: any[],
    sortDirection: SortDirection = SortDirection.DESC
  ) => {
    data.sort((a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.weight < b.weight ? -1 : 0;
      }
      return a.weight > b.weight ? -1 : 0;
    });
    return data;
  };

  async search(
    latitude: number,
    longitude: number,
    radius = 20,
    limit = 20
  ): Promise<Location[]> {
    // Construct an encoded query string parameter
    let params = new HttpParams().set('lat', String(latitude));
    params = params.append('long', String(longitude));
    params = params.append('radius', String(radius));
    params = params.append('limit', String(limit));
    params = params.append('nomnom', 'calendars');
    params = params.append(
      'nomnom_calendars_from',
      moment().format('YYYYMMDD')
    );
    params = params.append(
      'nomnom_calendars_to',
      moment().add(7, 'days').format('YYYYMMDD')
    );

    let headers = this.createHeaders([
      ['ui-cache-ttl', '300'],
      ['ui-transformer', 'restaurants'],
    ]);

    return this.http
      .get<Location[]>(environment.apiBaseUrl + '/restaurants/near', {
        headers: headers,
        params: params,
        observe: 'response',
      })
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async locations(): Promise<Location[]> {
    // Construct an encoded query string parameter
    let params = new HttpParams().set('nomnom', 'calendars');
    params = params.append(
      'nomnom_calendars_from',
      moment().format('YYYYMMDD')
    );
    params = params.append(
      'nomnom_calendars_to',
      moment().add(7, 'days').format('YYYYMMDD')
    );

    let headers = this.createHeaders([
      ['ui-cache-ttl', '300'],
      ['ui-transformer', 'restaurants'],
    ]);

    return this.http
      .get<Location[]>(environment.apiBaseUrl + '/restaurants/', {
        headers: headers,
        params: params,
        observe: 'response',
      })
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async location(id: string | number): Promise<Location> {
    // Construct an encoded query string parameter
    let params = new HttpParams().set('nomnom', 'calendars');
    params = params.append(
      'nomnom_calendars_from',
      moment().format('YYYYMMDD')
    );
    params = params.append(
      'nomnom_calendars_to',
      moment().add(7, 'days').format('YYYYMMDD')
    );

    let headers = this.createHeaders([
      ['ui-cache-ttl', '300'],
      ['ui-transformer', 'restaurant'],
    ]);

    if (typeof id === 'number' || !isNaN(id as any)) {
      return this.http
        .get<Location>(environment.apiBaseUrl + '/restaurants/' + id, {
          params: params,
          headers: headers,
          observe: 'response',
        })
        .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
        .toPromise();
    }
    return this.http
      .get<Location>(environment.apiBaseUrl + '/restaurants/byslug/' + id, {
        params: params,
        headers: headers,
        observe: 'response',
      })
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async menu(
    location: Location | number | string,
    isDigitalMenu = false
  ): Promise<Menu> {
    if (location instanceof Location) {
      location = location.id;
    }
    let headers = this.createHeaders([
      ['ui-cache-ttl', '300'],
      ['ui-transformer', 'menu'],
    ]);
    const value = isDigitalMenu ? 'digital-menu' : 'menu-products';
    const params = new HttpParams().set('nomnom', value);
    return this.http
      .get<Menu>(
        environment.apiBaseUrl + '/restaurants/' + location + '/menu',
        { headers, params, observe: 'response' }
      )
      .pipe(
        map((r) => {
          return this.responseCache.get(r.headers.get('ui-cache-key'));
        })
      )
      .toPromise()
      .catch((e) => console.log(e));
  }

  async contactOptions(
    authentication: Authentication
  ): Promise<ContactOptions> {
    return this.http
      .get<ContactOptions>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/contactoptions',
        { headers: this.nomnomHeaders }
      )
      .pipe(map((r) => new ContactOptions(r)))
      .toPromise();
  }

  async modifier(
    location: Location,
    categoryProduct: CategoryProduct
  ): Promise<Modifier[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('ui-cache-ttl', '300');
    return this.http
      .get<Modifier[]>(
        environment.apiBaseUrl +
        '/products/' +
        categoryProduct.id +
        '/modifiers',
        { headers: headers }
      )
      .pipe(map((r) => r['optiongroups'].map((p: any) => new Modifier(p))))
      .toPromise();
  }

  async createBasket(
    location: Location | number | string,
    authentication?: Authentication
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);

    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/create',
        {
          vendorid: location instanceof Location ? location.id : location,
          authtoken: authentication ? authentication.authtoken : null,
        },
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async createBasketFromOrder(
    order: OrderResponse,
    authentication: Authentication
  ): Promise<Basket> {
    let params = new HttpParams().set('authtoken', authentication.authtoken);
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/createfromorder',
        { id: order.id, ignoreunavailableproducts: true },
        {
          headers: headers,
          observe: 'response',
          params: params,
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async createBasketFromFave(
    order: FavoriteOrder,
    authentication: Authentication
  ): Promise<Basket> {
    let params = new HttpParams().set('authtoken', authentication.authtoken);
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/createfromfave',
        { faveid: order.id, ignoreunavailableproducts: true },
        {
          headers: headers,
          observe: 'response',
          params: params,
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async updateInBasket(
    basket: Basket,
    basketProducts: BasketProduct[]
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-response-transformer', 'addToBasket'],
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);

    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/products/batch',
        {
          products: basketProducts,
        },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async deleteFromBasket(
    basket: Basket,
    basketProduct: BasketProduct
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .delete<Basket>(
        environment.apiBaseUrl +
        '/baskets/' +
        basket.id +
        '/products/' +
        basketProduct.id,
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async getBasket(basket: Basket): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
    ]);
    return this.http
      .get<Basket>(environment.apiBaseUrl + '/baskets/' + basket.id, {
        headers: headers,
        observe: 'response',
      })
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async disclaimers(
    location: Location | number | string
  ): Promise<LocationDisclaimer> {
    if (location instanceof Location) {
      location = location.id;
    }
    let headers = this.createHeaders([['ui-cache-ttl', '600']]);
    return this.http
      .get<LocationDisclaimer>(
        environment.apiBaseUrl + '/restaurants/' + location + '/disclaimers',
        { headers: headers }
      )
      .pipe(map((r) => new LocationDisclaimer(r)))
      .toPromise();
  }

  async addToBasket(
    basket: Basket,
    basketProducts: BasketProduct[]
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-response-transformer', 'addToBasket'],
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/products/batch',
        {
          products: basketProducts,
        },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async timeWanted(basket: Basket, timeWanted: TimeWanted): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/timewanted',
        timeWanted,
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async asap(basket: Basket): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .delete<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/timewanted',
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async dispatchAddress(
    basket: Basket,
    address: DeliveryAddress
  ): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/dispatchaddress',
        address,
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async deliveryMode(basket: Basket, mode: string): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/deliverymode',
        { deliverymode: mode },
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async validate(basket: Basket, userData: any = null): Promise<Basket> {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    let params = new HttpParams();
    // if (userData) {
    //   // if you pass int userData that assumes you want to create a Fiserv customer
    //   // in the background BEFORE checking out
    //   params = params.append('nomnom', 'wwt-create-customer');
    // }
    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/validate',
        userData,
        {
          headers: headers,
          params: params,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async giftCardBalance(
    basket: Basket | string,
    cardNumber: string,
    pin: string,
    billingScheme: BillingScheme | number,
    authentication: Authentication | string
  ) {
    let params = null;
    if (typeof basket !== 'string') {
      basket = basket.id;
    }
    if (typeof billingScheme !== 'number') {
      billingScheme = billingScheme.id;
    }
    if (authentication && typeof authentication !== 'string') {
      authentication = authentication.authtoken;
      params = new HttpParams().set('authtoken', authentication);
    }

    return this.http
      .post<BillingSchemeAccountBalance>(
        environment.apiBaseUrl +
        '/baskets/' +
        basket +
        '/billingschemes/' +
        billingScheme +
        '/retrievebalance',
        { cardnumber: cardNumber, pin: pin },
        { params: params }
      )
      .pipe(map((r) => new BillingSchemeAccountBalance(r)))
      .toPromise();
  }

  // FISERV / WWT Payments

  // start payment session and get iframe responses
  async startFiservPaymentSession(
    oloCustomerId: string
  ): Promise<FiservSessionResponse> {
    // this call returns vaulted payment methods
    return this.http
      .post<FiservSessionResponse>(
        `${environment.apiBaseUrl}/payment/start?clientid=wingstop`,
        { oloCustomerId }
      )
      .toPromise();
  }

  // This will probably not be used anywhere but it will exist in NN
  async submitWwtFiservPayments(paymentData: FiservPaymentDTO) {
    return this.http
      .post(
        `${environment.apiBaseUrl}/payment/submit?clientid=wingstop`,
        paymentData
      )
      .toPromise();
  }

  async getFiservGiftcardBalance(
    giftCardData: FiservGiftCard
  ): Promise<FiservGiftCardResponse> {
    return this.http
      .post<FiservGiftCardResponse>(
        `${environment.apiBaseUrl}/wwt/get-gift-card-balance`,
        giftCardData
      )
      .toPromise();
  }

  async wwtMultiplePayments(basket: Basket, paymentData: PaymentData) {
    let params = new HttpParams();
    // if (
    //   this.store.getValue().appState.paymentProvider ===
    //     PaymentProviders.WWT_FISERV &&
    //   this.store.getValue().appState.locationSupportsPrepaid
    // ) {
    //   params = params.append('nomnom', 'wwt-submit-payment'); // used to trigger NN action
    // }

    return this.http
      .post<OrderResponse>(
        `${environment.apiBaseUrl}/baskets/${basket.id}/submit/multiplepayments`,
        paymentData,
        { headers: this.nomnomHeaders, params }
      )
      .pipe(map((r) => new OrderResponse(r)))
      .toPromise();
  }

  async wwtSubmit(basket: Basket, paymentData: PaymentData) {
    let params = new HttpParams();
    // if (
    //   this.store.getValue().appState.paymentProvider ===
    //     PaymentProviders.WWT_FISERV &&
    //   this.store.getValue().appState.locationSupportsPrepaid
    // ) {
    //   params = params.append('nomnom', 'wwt-submit-payment'); // used to trigger NN action
    // }

    return this.http
      .post<OrderResponse>(
        `${environment.apiBaseUrl}/baskets/${basket.id}/submit`,
        paymentData,
        { headers: this.nomnomHeaders, params }
      )
      .pipe(map((r) => new OrderResponse(r)))
      .toPromise();
  }

  // Fiserv customer endpoints will mostly be automatically on (sign-up, log-in) on the backend
  // The only exception is this fallback endpoint to call /customer POST before payment/start is called (if !nomnom.payment.customer)
  async wwtCreateCustomer(user: FiservUser) {
    return this.http
      .post(`${environment.apiBaseUrl}/payment/customer`, user)
      .toPromise();
  }

  // Apple Pay Session endpoint
  async createApplePaySession(validationUrl: string): Promise<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/apple-pay/create-session`, {
        validationUrl: validationUrl,
        displayName: 'Wingstop',
      })
      .toPromise();
  }

  // Used for olo external payment providers (i.e Apple Pay)
  async externalSinglePayment(basket: Basket, paymentData: PaymentData) {
    return this.http
      .post<OrderResponse>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/submit',
        paymentData,
        { headers: this.nomnomHeaders }
      )
      .pipe(map((r) => new OrderResponse(r)))
      .toPromise();
  }

  async multiplePayments(basket: Basket, paymentData: PaymentData) {
    return this.http
      .post<OrderResponse>(
        environment.apiBaseUrl + '/pci/submit/' + basket.id,
        paymentData,
        { headers: this.nomnomHeaders }
      )
      .pipe(map((r) => new OrderResponse(r)))
      .toPromise();
  }

  // TODO: incomplete
  async migration(email: string) {
    let params = new HttpParams().set('email', email);

    return this.http
      .get<any>(environment.apiBaseUrl + '/migration', { params: params })
      .pipe(map((r) => noop()))
      .toPromise();
  }

  async createUserFromOrder(
    orderResponse: OrderResponse | string,
    password: string,
    optin = false
  ) {
    if (typeof orderResponse !== 'string') {
      orderResponse = orderResponse.id;
    }
    return this.http
      .post<Authentication>(
        environment.apiBaseUrl + '/orders/' + orderResponse + '/createuser',
        {
          password: password,
          optin: optin,
          nomnom: {
            country: 'USA',
          },
        },
        { headers: this.nomnomHeaders }
      )
      .pipe(map((r) => new Authentication(r)))
      .toPromise();
  }

  async authenticate(login: Login | SSOUser, captcha?: string) {
    let params = new HttpParams();
    if (captcha) {
      params = params.append('challenge_response', captcha);
    }
    if (login instanceof SSOUser) {
      return this.authenticateWithSocial(login);
    }
    return this.http
      .post<Authentication>(
        environment.apiBaseUrl + '/users/authenticate',
        login,
        { params: params, headers: this.nomnomHeaders }
      )
      .pipe(map((r) => new Authentication(r['user'])))
      .toPromise();
  }

  async authenticateWithSocial(ssoUser: SSOUser) {
    return this.http
      .post<SSOUser>(environment.apiBaseUrl + '/users/getorcreate', ssoUser, {
        headers: this.nomnomHeaders,
      })
      .pipe(map((r) => new SSOUser(r)))
      .toPromise()
      .then((u) => {
        return this.http
          .get<Authentication>(
            environment.apiBaseUrl + '/users/' + u.authtoken,
            { headers: this.nomnomHeaders }
          )
          .pipe(map((r) => new Authentication(r)))
          .toPromise();
      });
  }

  async contactDetails(authentication: Authentication) {
    return this.http
      .get<ContactDetails>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/contactdetails',
        { headers: this.nomnomHeaders }
      )
      .pipe(map((c) => new ContactDetails(c)))
      .toPromise();
  }

  async updateContactDetails(
    authentication: Authentication,
    contactDetails: ContactDetails
  ) {
    return this.http
      .put<ContactDetails>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/contactdetails',
        contactDetails,
        { headers: this.nomnomHeaders }
      )
      .pipe(map((c) => new ContactDetails(c)))
      .toPromise();
  }

  async logout(authentication: Authentication) {
    return this.http
      .delete(environment.apiBaseUrl + '/users/' + authentication.authtoken)
      .toPromise();
  }

  // Log customer out of Ping
  async revokePingToken(authentication: Authentication) {
    await this.http
      .post(environment.apiBaseUrl + `/ping/as/revoke`, {
        token: authentication.nomnom.ping.refresh_token,
      })
      .toPromise();
  }

  async authenticateWithGoogle(socialUser: SocialUser) {
    return this.http
      .post<Authentication>(
        environment.apiBaseUrl + '/users/google/' + socialUser.authToken,
        socialUser
      )
      .pipe(map((r) => new Authentication(r)))
      .toPromise();
  }

  async authenticateWithFacebook(socialUser: SocialUser) {
    return this.http
      .post<Authentication>(
        environment.apiBaseUrl + '/users/authenticate' + socialUser.authToken,
        socialUser
      )
      .pipe(map((r) => new Authentication(r)))
      .toPromise();
  }

  async recent(authentication: Authentication) {
    return this.http
      .get<OrderResponse[]>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/recentorders'
      )
      .pipe(map((r) => r['orders'].map((o: any) => new OrderResponse(o))))
      .toPromise();
  }

  async order(
    order: OrderResponse | string,
    authentication?: Authentication | string
  ) {
    if (typeof order !== 'string') {
      order = order.id;
    }
    if (authentication && typeof authentication !== 'string') {
      authentication = authentication.authtoken;
    }
    let params = authentication
      ? new HttpParams().set('authToken', authentication as string)
      : null;
    return this.http
      .get<OrderResponse>(environment.apiBaseUrl + '/orders/' + order)
      .pipe(map((o) => new OrderResponse(o)))
      .toPromise()
      .then(async (o) => {
        await this.http
          .get<DeliveryStatus[]>(
            environment.apiBaseUrl + '/orders/' + order + '/deliverystatus',
            { params: params }
          )
          .pipe(
            map((response) => {
              if (response && Array.isArray(response['deliveries'])) {
                response['deliveries'].forEach((delivery: any) => {
                  if (!Array.isArray(o.deliveries)) {
                    o.deliveries = [];
                  }
                  o.deliveries.push(new DeliveryStatus(delivery));
                });
              }
            })
          )
          .toPromise();
        return o;
      })
      .catch((err: any) => {
        console.log('error in api', err);
        throw err;
      });
  }

  async modifyOrder(order: OrderResponse | string) {
    if (typeof order !== 'string') {
      order = order.id;
    }
    return this.http
      .post<OrderResponse>(
        environment.apiBaseUrl + '/orders/' + order + '/edit',
        null
      )
      .pipe(map((o: any) => new Basket(o)))
      .toPromise();
  }

  async cancelOrder(order: OrderResponse | string, invoiceId?: string) {
    if (typeof order !== 'string') {
      order = order.id;
    }
    const body = invoiceId
      ? {
        nomnom: {
          payment: {
            invoiceId,
          },
        },
      }
      : null;
    return this.http
      .post<OrderResponse>(
        environment.apiBaseUrl + '/orders/' + order + '/cancel',
        body
      )
      .pipe(map((o: any) => new OrderResponse(o)))
      .toPromise();
  }

  async schedule(location: Location) {
    // Construct an encoded query string parameter
    let params = new HttpParams().set(
      'from',
      GlobalService.getNow().format('YYYYMMDD')
    );
    params = params.append(
      'to',
      GlobalService.getNow().add(7, 'd').format('YYYYMMDD')
    );
    let headers = this.createHeaders([['ui-cache-ttl', '600']]);
    return this.http
      .get<Schedule[]>(
        environment.apiBaseUrl + '/restaurants/' + location.id + '/calendars',
        {
          headers: headers,
          params: params,
        }
      )
      .pipe(map((r) => r['calendar'].map((s: any) => new Schedule(s))))
      .toPromise();
  }

  async scheduleWeekly(location: Location) {
    let now = moment();
    let nextSunday = now.add(7 - now.isoWeekday(), 'days');
    let sixDaysLatter = nextSunday.clone().add(6, 'days');

    // Construct an encoded query string parameter
    let params = new HttpParams().set('from', nextSunday.format('YYYYMMDD'));
    params = params.append('to', sixDaysLatter.format('YYYYMMDD'));

    return this.http
      .get<Schedule[]>(
        environment.apiBaseUrl + '/restaurants/' + location.id + '/calendars',
        { params: params }
      )
      .pipe(map((r) => r['calendar'].map((s: any) => new Schedule(s))))
      .toPromise();
  }

  async createUser(create: SignUp) {
    return this.http
      .post<Authentication>(environment.apiBaseUrl + '/users/create', create, {
        headers: this.nomnomHeaders,
      })
      .pipe(map((r) => new Authentication(r)))
      .toPromise();
  }

  async updateUser(authentication: Authentication, update: UpdateUser) {
    return this.http
      .put<Authentication>(
        environment.apiBaseUrl + '/users/' + authentication.authtoken,
        update,
        { headers: this.nomnomHeaders }
      )
      .pipe(map((r) => new Authentication(r)))
      .toPromise();
  }

  async updatePassword(authentication: Authentication, update: UpdatePassword) {
    return this.http
      .post<any>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/password',
        update
      )
      .pipe(map((r) => r))
      .toPromise();
  }

  async forgotPassword(emailAddress: string) {
    // In NomNom API, it's "email" not "emailaddress".  This seems like an unnecessary departure from OLO API.
    return this.http
      .post<any>(environment.apiBaseUrl + '/users/forgotpassword', {
        emailaddress: emailAddress,
      })
      .pipe(map((r) => r))
      .toPromise();
  }

  async updateContactOptions(
    authentication: Authentication,
    update: UpdateContactOptions
  ) {
    return this.http
      .put<any>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/contactoptions',
        update,
        { headers: this.nomnomHeaders }
      )
      .pipe(map((r) => r))
      .toPromise();
  }

  async billingAccounts(authentication: Authentication) {
    return this.http
      .get<UserBillingAccount[]>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/billingaccounts',
        { headers: this.nomnomHeaders }
      )
      .pipe(
        map((r) =>
          r['billingaccounts'].map((s: any) => new UserBillingAccount(s))
        )
      )
      .toPromise();
  }

  async getBillingSchemes(basket: Basket) {
    return this.http
      .get<BillingScheme[]>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/billingschemes',
        { headers: this.nomnomHeaders }
      )
      .pipe(
        map((r) => r['billingschemes'].map((s: any) => new BillingScheme(s)))
      )
      .toPromise();
  }

  async deleteBillingAccount(
    authentication: Authentication,
    billingAccount: UserBillingAccount
  ) {
    return this.http
      .delete(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/billingaccounts/' +
        billingAccount.accountid
      )
      .toPromise();
  }

  async deleteFiservAccount(oloCustomerId: string, fdAccountId: string) {
    return this.http
      .delete(
        environment.apiBaseUrl + `/payment/${oloCustomerId}/${fdAccountId}`
      )
      .toPromise();
  }

  async deleteAccount(authentication: Authentication) {
    return this.http
      .delete(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/account',
        { headers: this.nomnomHeaders }
      )
      .toPromise();
  }

  async deliveryAddresses(authentication: Authentication) {
    return this.http
      .get<UserDeliveryAddress[]>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/userdeliveryaddresses'
      )
      .pipe(
        map((r) =>
          r['deliveryaddresses'].map((s: any) => new UserDeliveryAddress(s))
        )
      )
      .toPromise();
  }

  async deleteDeliveryAddress(
    authentication: Authentication,
    deliveryAddress: UserDeliveryAddress
  ) {
    return this.http
      .delete(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/userdeliveryaddresses/' +
        deliveryAddress.id
      )
      .toPromise();
  }

  async addFavoriteLocation(
    authentication: Authentication,
    location: Location
  ) {
    return this.http
      .post<UserFavoriteLocation>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/favelocations/' +
        location.id,
        null
      )
      .pipe(map((r) => new UserFavoriteLocation(r)))
      .toPromise();
  }

  async deleteFavoriteLocation(
    authentication: Authentication,
    location: Location
  ) {
    return this.http
      .delete(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/favelocations/' +
        location.id
      )
      .toPromise();
  }

  async favoriteLocations(authentication: Authentication) {
    let params = new HttpParams().set('nomnom', 'calendars');
    params = params.append(
      'nomnom_calendars_from',
      moment().format('YYYYMMDD')
    );
    params = params.append(
      'nomnom_calendars_to',
      moment().add(7, 'days').format('YYYYMMDD')
    );

    return this.http
      .get<Location[]>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/favelocations',
        { params: params }
      )
      .pipe(
        map((r) =>
          r['favelocations'].map((s: any) => new Location(s['restaurant']))
        )
      )
      .toPromise();
  }

  async addFavoriteOrder(
    authentication: Authentication,
    basket: Basket | string,
    description: string
  ) {
    if (typeof basket !== 'string') {
      basket = basket.id;
    }
    return this.http
      .post<FavoriteOrder[]>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/faves',
        {
          basketid: basket,
          description: description,
        }
      )
      .pipe(map((r) => r['faves'].map((s: any) => new FavoriteOrder(s))))
      .toPromise();
  }

  async deleteFavoriteOrder(
    authentication: Authentication,
    favoriteOrder: FavoriteOrder | number
  ) {
    if (typeof favoriteOrder !== 'number') {
      favoriteOrder = favoriteOrder.id;
    }
    return this.http
      .delete(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/faves/' +
        favoriteOrder
      )
      .toPromise();
  }

  async favoriteOrders(authentication: Authentication) {
    return this.http
      .get<FavoriteOrder[]>(
        environment.apiBaseUrl + '/users/' + authentication.authtoken + '/faves'
      )
      .pipe(map((r) => r['faves'].map((s: any) => new FavoriteOrder(s))))
      .toPromise();
  }

  async qualifyingRewards(
    authentication: Authentication,
    location: Location | number
  ) {
    if (typeof location !== 'number') {
      location = location.id;
    }
    let params = new HttpParams().set('vendorid', location.toString());
    return this.http
      .get<Reward[]>(
        environment.apiBaseUrl +
        '/users/' +
        authentication.authtoken +
        '/qualifyingrewards',
        { params: params }
      )
      .pipe(map((r) => r['rewards'].map((s: any) => new Reward(s))))
      .toPromise();
  }

  async loyaltySchemes(authentication: Authentication, basket: Basket) {
    let params = new HttpParams().set('authtoken', authentication.authtoken);
    return this.http
      .get<LoyaltyScheme>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/loyaltyschemes',
        { params: params }
      )
      .pipe(map((r) => new LoyaltyScheme(r['schemes'][0] || null)))
      .toPromise();
  }

  async applyReward(basket: Basket, reward: Reward) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl +
        '/baskets/' +
        basket.id +
        '/loyaltyrewards/byref',
        {
          membershipid: reward.membershipid,
          references: [reward.reference],
        },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async removeReward(basket: Basket, reward: Reward) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .delete<Basket>(
        environment.apiBaseUrl +
        '/baskets/' +
        basket.id +
        '/loyaltyrewards/' +
        reward.rewardid,
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async getTokenizedTransaction(transactionId: string) {
    return this.http
      .get<TokenizedCreditCard[]>(
        environment.apiBaseUrl + '/pci/tokens/' + transactionId
      )
      .pipe(map((r) => r.map((s) => new TokenizedCreditCard(s))))
      .toPromise();
  }

  async transfer(basket: Basket, location: Location): Promise<Basket> {
    if (!basket || !location) {
      throw "Warning: Attempted basket transfer but basket or new location isn't set";
    }
    let headers = this.createHeaders([
      ['ui-response-transformer', 'transferBasket'],
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .post<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/transfer',
        { vendorid: location.id },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async addressSearch(address: string): Promise<Location[]> {
    if (
      environment.geocodeProvider &&
      environment.geocodeProvider === 'mapbox'
    ) {
      // Construct an encoded query string parameter
      let params = new HttpParams();
      params = params.append('country', 'us,pr,vi,gu,mp,ca');

      return this.http
        .get<any>(
          environment.apiBaseUrl +
          '/mapbox/geocoding/v5/mapbox.places/' +
          encodeURI(address) +
          '.json',
          { params: params }
        )
        .pipe(
          map((r) => {
            if (Array.isArray(r.features) && r.features.length > 0) {
              return this.search(
                r.features[0].geometry.coordinates[1],
                r.features[0].geometry.coordinates[0]
              );
            }
          })
        )
        .toPromise();
    } else {
      // Construct an encoded query string parameter
      let params = new HttpParams().set('address', address);

      return this.http
        .get<any>(environment.apiBaseUrl + '/google/maps/api/geocode/json', {
          params: params,
        })
        .pipe(
          map((r) => {
            if (Array.isArray(r.results) && r.results.length > 0) {
              return this.search(
                r.results[0].geometry.location.lat,
                r.results[0].geometry.location.lng
              );
            }
          })
        )
        .toPromise();
    }
  }

  placesSearch(
    input: string,
    sessionToken?: string,
    latitude?: any,
    longitude?: any,
    regionOnly?: boolean,
    fallback?: boolean
  ) {
    const provider = fallback
      ? environment.geocodeFallback || environment.geocodeProvider
      : environment.geocodeProvider;
    if (provider && provider === 'mapbox') {
      // Construct an encoded query string parameter
      let params = new HttpParams();

      if (!regionOnly) {
        params = params.append('types', 'address');
      } else {
        params = params.append('types', 'country,region,postcode,place');
      }

      params = params.append('country', 'us,pr,vi,gu,mp,is');

      if (latitude && longitude) {
        params = params.append('proximity', longitude + ',' + latitude);
      } else {
        params = params.append('proximity', -98.55562 + ',' + 39.809734);
      }

      return this.http
        .get<any>(
          environment.apiBaseUrl +
          '/mapbox/geocoding/v5/mapbox.places/' +
          encodeURI(input) +
          '.json',
          { params: params }
        )
        .pipe(
          map((r) =>
            r['features'].map(
              (s: any) =>
                new PlacesResponse({
                  ...s,
                  ...{ provider: environment.geocodeProvider },
                })
            )
          )
        );
    } else {
      // Construct an encoded query string parameter
      let params = new HttpParams().set('input', input);
      params = params.append('radius', '32187');
      if (regionOnly) {
        params = params.append('types', '(regions)');
      }
      params = params.append(
        'components',
        'country:us|country:pr|country:vi|country:gu|country:mp'
      );

      if (latitude && longitude) {
        params = params.append('location', latitude + ',' + longitude);
      } else {
        params = params.append('location', 39.809734 + ',' + -98.55562);
      }

      if (sessionToken) {
        params = params.append('sessiontoken', sessionToken);
      }

      return this.http
        .get<any>(
          environment.apiBaseUrl + '/google/maps/api/place/autocomplete/json',
          { params: params }
        )
        .pipe(
          map((r) => r['predictions'].map((s: any) => new PlacesResponse(s)))
        );
    }
  }

  geocodeByPlaceId(placeId: string) {
    // Construct an encoded query string parameter
    let params = new HttpParams().set('place_id', placeId);

    return this.http
      .get<any>(environment.apiBaseUrl + '/google/maps/api/geocode/json', {
        params: params,
      })
      .pipe(map((r) => r['results'].map((s: any) => new GeocodeResponse(s))));
  }

  reverseGeocodeByCoords(lat: any, long: any) {
    let params = new HttpParams().set('types', 'country');

    return this.http
      .get<any>(
        environment.apiBaseUrl +
        `/mapbox/geocoding/v5/mapbox.places/${long},${lat}.json`,
        { params }
      )
      .pipe(map((r) => r['features'].map((s: any) => new GeocodeResponse(s))));
  }

  placesDetail(placeId: string, sessionToken?: string) {
    // Construct an encoded query string parameter
    let params = new HttpParams().set('placeid', placeId);
    params = params.append('fields', 'address_component,geometry');

    if (sessionToken) {
      params = params.append('sessiontoken', sessionToken);
    }

    return this.http
      .get<any>(
        environment.apiBaseUrl + '/google/maps/api/place/details/json',
        { params: params }
      )
      .pipe(map((r) => new GeocodeResponse(r['result'])));
  }

  geocodeSearch(
    address: string,
    mapBounds?: google.maps.LatLngBounds,
    fallback?: boolean
  ) {
    const provider = fallback
      ? environment.geocodeFallback || environment.geocodeProvider
      : environment.geocodeProvider;
    if (provider && provider === 'mapbox') {
      // Construct an encoded query string parameter
      let params = new HttpParams();

      params = params.append('country', 'us,pr,vi,gu,mp,ca');

      return this.http
        .get<any>(
          environment.apiBaseUrl +
          '/mapbox/geocoding/v5/mapbox.places/' +
          encodeURI(address) +
          '.json',
          { params: params }
        )
        .pipe(
          map((r) => r['features'].map((s: any) => new GeocodeResponse(s)))
        );
    } else {
      // Construct an encoded query string parameter
      let params = new HttpParams().set('address', address);
      params = params.append('region', 'us');
      params = params.append('components', 'country:us|country:ca');

      if (mapBounds && mapBounds.getSouthWest() && mapBounds.getNorthEast()) {
        params = params.append(
          'bounds',
          mapBounds.getSouthWest().lat() +
          ',' +
          mapBounds.getSouthWest().lng() +
          '|' +
          mapBounds.getNorthEast().lat() +
          ',' +
          mapBounds.getNorthEast().lng()
        );
      }

      return this.http
        .get<GeocodeResponse[]>(
          environment.apiBaseUrl + '/google/maps/api/geocode/json',
          { params: params }
        )
        .pipe(map((r) => r['results'].map((s: any) => new GeocodeResponse(s))));
    }
  }

  async applyTip(basket: Basket, tip: number) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/tip',
        { amount: tip },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async applyCoupon(basket: Basket, coupon: string) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/coupon',
        { couponcode: coupon },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async removeCoupon(basket: Basket) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .delete<Basket>(
        environment.apiBaseUrl + '/baskets/' + basket.id + '/coupon',
        {
          headers: headers,
          observe: 'response',
        }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  createHeaders(headers: [string, string][]) {
    let _headers: HttpHeaders = new HttpHeaders();
    headers.forEach((h) => {
      _headers = _headers.append(h[0], h[1]);
    });
    return _headers;
  }

  async clubForm(data: any) {
    return this.http
      .post<ClubModel>(environment.apiBaseUrl + '/the-club', { data })
      .pipe(map((r) => new ClubModel(r)))
      .toPromise();
  }

  async getModal() {
    let request = await this.getAllModals();
    if (request && request.length) {
      return request[0];
    }
    return request;
  }

  async getAllModals() {
    return this.http
      .get(environment.apiBaseUrl + '/content/type/WingstopContentModal/list')
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          let response = r['content'].map((x: any[]) => {
            return new Modal(x);
          });
          return response;
        })
      )
      .toPromise();
  }

  async getFlavorById(id: string) {
    return this.http
      .get(
        environment.apiBaseUrl + '/content/type/WingstopContentFlavor/id/' + id
      )
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          return new Flavor(r['content'][0]);
        })
      )
      .toPromise();
  }

  async getFlavorByRef(ref: string) {
    return this.http
      .get(
        environment.apiBaseUrl +
        '/content/type/WingstopContentFlavor/ref/' +
        ref
      )
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          return new Flavor(r['content'][0]);
        })
      )
      .toPromise();
  }

  async getAllFlavors(
    orderBy = 'weight',
    orderSortDirection: SortDirection = SortDirection.DESC
  ): Promise<Flavor[]> {
    if (
      environment.flavorsGetDataFromApi &&
      environment.flavorsGetDataFromApi === true
    ) {
      //respond with api data
      return this.http
        .get(
          `${environment.apiBaseUrl}/content/type/WingstopContentFlavor/list?order_by=${orderBy}&order_direction=${orderSortDirection}`
        )
        .pipe(
          map((r) => {
            if (r['content'].indexOf(null) > -1) {
              r['content'].splice(r['content'].indexOf(null), 0);
            }
            let _flavors: Flavor[] = r['content'].map((row: any) => {
              return new Flavor(row);
            });
            this.sortByWeightAndDirection(_flavors, orderSortDirection);
            return _flavors;
          })
        )
        .toPromise();
    } else {
      //respond with environment variables
      let _flavors = environment.flavors;
      _flavors = _flavors.filter((f: Flavor) => {
        return f.isActive && f.isActive === true;
      });
      _flavors = this.sortByWeightAndDirection(_flavors, orderSortDirection);
      return _flavors;
    }
  }

  async getOffer(id: string) {
    return this.http
      .get(environment.apiBaseUrl + '/content/type/WingstopOffer/id/' + id)
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          return new CMSPromoOffer(r['content'][0]);
        })
      )
      .toPromise();
  }

  async getStates(stateName: string) {
    return this.http
      .get(environment.apiBaseUrl + '/extras/restaurant/summary/state')
      .pipe(
        map((response) => {
          const states: State[] = [];
          response['data'].forEach((state: {}) => {
            state['displayName'] = environment.states[state['name']];
            state['fullName'] = environment.states[state['name']].replace(
              ' ',
              '-'
            );
            states.push(new State(state));
          });
          return states.find((x) => {
            return x.fullName.toLowerCase() === stateName.toLowerCase();
          });
        })
      )
      .toPromise();
  }

  async getCities(state: string, cityName: string) {
    const city: City = new City(null);
    return this.http
      .get(
        environment.apiBaseUrl + '/extras/restaurant/' + state + '/' + cityName
      )
      .pipe(
        map((response) => {
          city.stateAbbreviation = response['state'];
          Object.keys(response['data'][0]).forEach((data) => {
            city[data] = response['data'][0][data];
          });
          return city;
        })
      )
      .toPromise();
  }

  async updateCustomField(field: CustomField, basketId: number) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basketId + '/customfields',
        field,
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async deleteCustomField(field: CustomField, basketId: number) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .delete<Basket>(
        environment.apiBaseUrl +
        '/baskets/' +
        basketId +
        '/customfields/' +
        field.id,
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async getBannerByRef(ref: string) {
    return this.http
      .get(
        environment.apiBaseUrl +
        `/content/type/WingstopContentBanner/ref/` +
        ref
      )
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          if (r['content'].length > 0) {
            return new Banner(r['content'][0]);
          }
        })
      )
      .toPromise();
  }

  async getCmsFeatureFlags(): Promise<CmsFeatureFlag[]> {
    // return this.http
    //   .get<CmsFeatureFlag[]>(
    //     environment.apiBaseUrl + `/content/type/WingstopFeature/list`
    //   )
    //   .pipe(
    //     map((r) => {
    //       if (r['content'].indexOf(null) > -1) {
    //         r['content'].splice(r['content'].indexOf(null), 0);
    //       }
    //       return r['content'];
    //     })
    //   )
    //   .toPromise();
    return [];
  }

  async optOutDoorDash(emailAddress: string) {
    return this.http
      .put<string>(environment.apiBaseUrl + '/sessionm/doordash/optout', {
        email: emailAddress,
      })
      .pipe(map((r) => r))
      .toPromise();
  }

  async applyDonation(donationId: number, amount: number, basketId: string) {
    let headers = this.createHeaders([
      ['ui-cache-ttl', '600'],
      ['ui-transformer', 'basket'],
      ['ui-cache-key', 'basket'],
      ['ui-update-cache', '1'],
    ]);
    return this.http
      .put<Basket>(
        environment.apiBaseUrl + '/baskets/' + basketId + '/donations',
        { id: donationId, amount: amount },
        { headers: headers, observe: 'response' }
      )
      .pipe(map((r) => this.responseCache.get(r.headers.get('ui-cache-key'))))
      .toPromise();
  }

  async getLocales() {
    return this.http
      .get(environment.apiBaseUrl + `/content/type/WingstopContentLocale/list`)
      .pipe(
        map((r) => {
          if (r['content'].indexOf(null) > -1) {
            r['content'].splice(r['content'].indexOf(null), 0);
          }
          if (r['content'].length > 0) {
            return r['content'];
          }
        })
      )
      .toPromise();
  }

  async binValidation(cardNumber: string, billingSchemeId: number) {
    return this.http
      .post<boolean>(
        environment.apiBaseUrl +
        '/billingschemes/' +
        billingSchemeId +
        '/binvalidation',
        {
          cardnumber: cardNumber,
        }
      )
      .pipe(map((r: any) => (r ? r.ispinrequired : false)))
      .toPromise();
  }

  async getS3SeoMetadata(): Promise<SeoMetadata[]> {
    const s3Path: string = `${environment.ngfeBaseUrl}/resources/`;
    return this.http
      .get<SeoMetadata[]>(s3Path + 'seo-metadata.json?v=' + Date.now())
      .toPromise();
  }
}
