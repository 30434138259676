import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { FulfillmentTimes } from '../../../../ecomm/types/fulfillment-times.types';
import { ReadyTimes } from '../../../common';
import {
  FutureDateTimeChangedEvent,
  FutureFullDateChangedEvent
} from '../../../common/types/cart.types';
import { Cart } from '../../../../ecomm/types/cart.types';

@Component({
  selector: 'wri-cart-fulfillment-time',
  templateUrl: './cart-fulfillment-time.component.html',
  styleUrls: ['./cart-fulfillment-time.component.scss']
})
export class CartFulfillmentTimeComponent implements OnChanges {
  @Input() public fulfillmentTime: FulfillmentTimes | null = null;
  @Input() public handoffMode: string | undefined;
  @Input() public ftForLater = '';
  @Input() public isReadOnly = false;
  @Input() public cartFulfillmentTime: string | null = null;
  @Input() public cartReadyTimes: ReadyTimes | null = null;
  @Input() public cartASAP: boolean | undefined;
  @Input() public error$ = new BehaviorSubject('');

  public prepTimeText = '';
  public radioMode: string | undefined; // 'asap' or 'later'
  public asapIsAvailable: boolean | undefined;
  public showFutureDateTime = false;
  @Input() public fulfillmentLaterFlowStateOpen: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  isLoading = false; // internal;
  futureFulfillmentTime = ''; // internal; for detecting changes

  private static readonly ASAP = 'asap';
  private static readonly LATER = 'later';

  @Output()
  public futureDateTimeSelect: EventEmitter<FutureFullDateChangedEvent> =
    new EventEmitter<FutureFullDateChangedEvent>();

  @Output()
  public selectedDateTimeValid: EventEmitter<boolean> =
    new EventEmitter<boolean>(true);

  constructor(private analyticsService: AnalyticsService) {}

  ngOnChanges() {
    this.ifStoreAcceptingAsapOrders();
    this.showFT();
  }

  private ifStoreAcceptingAsapOrders() {
    const cartFulfillmentTimeExists = this.cartFulfillmentTime;
    this.asapIsAvailable =
      this.fulfillmentTime?.isStoreAcceptingAsapOrders ?? true;
    this.radioMode =
      cartFulfillmentTimeExists || !this.asapIsAvailable
        ? CartFulfillmentTimeComponent.LATER
        : CartFulfillmentTimeComponent.ASAP;
  }

  getReadyTimeRange() {
    const { minMinutes, maxMinutes } = this.cartReadyTimes || {};
    return minMinutes && maxMinutes
      ? `${minMinutes} - ${maxMinutes} minutes`
      : '0 minutes';
  }

  getFormattedDateTimeLabel() {
    return this.ftForLater === ''
      ? 'Select a time'
      : 'Scheduled for ' + this.handoffMode + ' ' + this.ftForLater;
  }

  showFT() {
    this.prepTimeText =
      this.handoffMode === 'carryout'
        ? 'Ready for carryout in about '
        : 'Delivery in about ';

    // for read only mode
    if (this.isReadOnly) {
      if (this.cartASAP) {
        this.prepTimeText += this.getReadyTimeRange();
      } else {
        this.prepTimeText = this.getFormattedDateTimeLabel();
      }
    } else {
      // for write mode
      if (this.radioMode == CartFulfillmentTimeComponent.ASAP) {
        this.prepTimeText += this.getReadyTimeRange();
      } else {
        this.showFutureDateTime = true;
      }
    }
  }

  public onApiResponse(asap: boolean, response: Cart | null) {
    this.isLoading = false;
    if (asap && !response) {
      this.showFutureDateTime = true;
    }

    if (!asap && !response) {
      this.radioMode = CartFulfillmentTimeComponent.ASAP;
      this.showFT();
    }
  }

  public onRadioChange(event: { target: { value: string } }) {
    this.error$.next('');
    this.radioMode = event.target.value;
    this.showFT();

    if (this.radioMode === CartFulfillmentTimeComponent.ASAP) {
      this.fulfillmentLaterFlowStateOpen.next(false);
      this.showFutureDateTime = false;

      if (this.cartFulfillmentTime) {
        this.error$.next('');
        this.isLoading = true;
        this.futureDateTimeSelect.emit({
          asap: true
        });
      }
    } else {
      this.fulfillmentLaterFlowStateOpen.next(true);

      this.showFutureDateTime = true;
      this.error$.next('');
      this.analyticsService.logGaEvent({
        event: 'time_change'
      });
    }
  }

  public onFutureDateChange(event: FutureDateTimeChangedEvent) {
    this.isLoading = true;

    const timeZone = this.fulfillmentTime?.locationTimeZone ?? '????';

    if (timeZone) {
      const futureDatewithUTC = moment
        .tz(event.date + ' ' + event.time, timeZone)
        .utc()
        .format();
      this.futureDateTimeSelect.emit({
        futureDate: futureDatewithUTC,
        asap: false
      });

      this.futureFulfillmentTime = futureDatewithUTC;
    }
    this.showFutureDateTime = false;
  }

  public isSelectedDateTimeValid(event: boolean) {
    this.selectedDateTimeValid.emit(event);
  }
}
