import { BaseModel } from '@wingstop/models/base.model';
import { Metadata } from '@wingstop/models/metadata.model';

export class Option extends BaseModel {
  id: any = null;
  optiongroup_id: any = null;
  children: number = null;
  cost: number = null;
  displayid: string = null;
  isdefault: number = null;
  metadata: Metadata[] = [];
  name: string = null;
  created_at: string = null;
  updated_at: string = null;
  basecalories: number = 0;
  maxcalories: number = 0;
  menuitemlabels: { code: string; images: any[]; name: string }[] = [];
  modifiers: Modifier[] = [];
  selected: boolean | number;
  quantity: number = 0;
  chainoptionid: any;

  constructor(values?: any) {
    super();
    if (values) {
      values.basecalories = Number(values.basecalories);
      values.maxcalories = Number(values.maxcalories);

      if (values.description) {
        values.description = values.description.replace(':', '');
      }
      if (values.modifiers) {
        values.modifiers = values.modifiers.map(
          (value: any) => new Modifier(value)
        );
      }
      if (values.metadata) {
        if (Array.isArray(values.metadata)) {
          values.metadata = values.metadata.map(
            (value: any) => new Metadata(value)
          );
        }
      }
    }
    this.initialize(values);
  }
}

export class Modifier extends BaseModel {
  id: any = null;
  product_id: any = null;
  choicequantityincrement: number = null;
  description: string = null;
  mandatory: number = null;
  maxaggregatequantity: number = null;
  maxchoicequantity: number = null;
  maxselects: number = null;
  metadata: Metadata[] = [];
  minaggregatequantity: number = null;
  minchoicequantity: string = null;
  minselects: number = null;
  parentchoiceid: any = null;
  supportschoicequantities: number = null;
  created_at: string = null;
  updated_at: string = null;
  parent_option_id: number = null;
  options: Option[] = [];
  subText: string[];
  chainmodifierid: number;

  constructor(values?: any) {
    super();
    if (values) {
      values.minchoicequantity = Number(values.minchoicequantity);
      values.maxchoicequantity = Number(values.maxchoicequantity);
      values.minaggregatequantity = Number(values.minaggregatequantity);
      values.maxaggregatequantity = Number(values.maxaggregatequantity);
      values.maxchoicequantity = Number(values.maxchoicequantity);
      values.supportschoicequantities = Number(values.supportschoicequantities);
      values.minselects = Number(values.minselects);
      values.maxselects = Number(values.maxselects);
      values.choicequantityincrement = Number(values.choicequantityincrement);

      if (values.description) {
        values.description = values.description.replace(':', '');
      }
      if (values.options) {
        values.options = values.options.map((value: any) => new Option(value));
      }
      if (values.metadata) {
        if (Array.isArray(values.metadata)) {
          values.metadata = values.metadata.map(
            (value: any) => new Metadata(value)
          );
        }
      }
    }
    // Transform to numbers - no idea why they're strings anyway
    values.minselects = +values.minselects;
    values.maxselects = +values.maxselects;
    this.initialize(values);
  }
}
