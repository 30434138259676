<wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<div class="my-orders">
  <span class="my-orders-title" data-testid="my-orders-title">MY ORDERS</span>
</div>
<wri-feature-flags [with]="['enableOrderHistory']">
  <ng-container *ngIf="!isLoading && this.orderHistory?.length > 0;">
    <div data-testid="past-orders" class="order-history">
      <wri-past-order-summary
          [pastOrders]="orderHistory"
      ></wri-past-order-summary>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && (!this.orderHistory || this.orderHistory?.length===0)">
    <div class="start-order">
      <div class="icon-round spacing-bottom">
        <wri-icon icon="wri-bag"></wri-icon>
      </div>
      <div class="start-order-text spacing-bottom">
        You do not have any recent orders.
      </div>
      <button
          wriFocusOutline
          data-testid="start-order"
          [ngClass]="'wri-btn wri-btn-primary'"
          aria-label="Start Order"
          (click)="startOrder()"
      >
        Start Order
      </button>
    </div>
  </ng-container>
</wri-feature-flags>
