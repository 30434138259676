import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NgbModal,
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent
} from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  tap
} from 'rxjs';

import { LocationsResponseData } from '../../../../ecomm/types/search-location.types';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { SearchLocationWorkflowService } from '../../../../ecomm/workflows/search-location/search-location-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import {
  MapSearchEvent,
  MarkerData,
  PartialOutageModalComponent
} from '../../../common';
import {
  CONFIG,
  Config
} from '../../../../ecomm/providers/config/config.provider';
import { Store } from '@ngrx/store';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';

@Component({
  selector: 'wri-choose-job-location',
  templateUrl: './choose-job-location.component.html',
  styleUrls: ['./choose-job-location.component.scss']
})
export class ChooseJobLocationComponent
  implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy
{
  isLoading = false;
  searching = false;
  locationSearching = false;
  searchText: string | null = '';
  locationsList: LocationsResponseData | null | undefined;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  productRouteParam: string | null | undefined;
  @ViewChild('commonModal') commonModal!: TemplateRef<HTMLElement>;
  @ViewChild('confirmationPopupModal')
  confirmationPopupModal!: TemplateRef<HTMLElement>;
  showMapPreview = true;
  mapSearchEnabled = true;
  selectedMarker: string | null = null;
  mapLocations$: Subject<MarkerData[]> = new BehaviorSubject<MarkerData[]>([]);
  public useMyLocationIsSupported = false;
  @ViewChild('inputElem', { static: true }) inputElem: ElementRef | undefined;
  carrerUrl = '';
  selectedHandoffMode = 'carryout';
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private searchLocationWorkflowService: SearchLocationWorkflowService,
    private route: ActivatedRoute,
    private storeInfoService: StoreInfoWorkflowService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    @Inject(RedirectService)
    private redirectService: RedirectService,
    @Inject(CONFIG) public config: Config,
    private store: Store
  ) {}

  _showMobileMap = false;

  get showMobileMap() {
    return this._showMobileMap;
  }

  set showMobileMap(val: boolean) {
    this._showMobileMap = val;
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.queryParamMap.get('searchQuery') ?? '') {
      this.searchText = decodeURIComponent(
        this.route.snapshot.queryParamMap.get('searchQuery') ?? ''
      );
      this.inputElem?.nativeElement.dispatchEvent(new Event('input'));
      this.inputElem?.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async onMapSearch($event: MapSearchEvent) {
    this.searching = false;
    this.locationSearching = true;
    this.setLocations(
      await this.searchLocationWorkflowService.getLocationsByCarryout({
        latitude: $event.lat,
        longitude: $event.lng,
        radius: Math.min(Math.max($event.radius, 1), 100)
      })
    );
    this.locationSearching = false;
  }

  onMapStartSearch($event: boolean) {
    this.showMapPreview = !$event;
  }

  onMapMarkerSelect($event: string | null) {
    this.selectedMarker = $event;
  }

  onMobileMapViewToggle() {
    this.showMobileMap = !this._showMobileMap;
  }

  onLocationNavigate(selectedLocationCarrerUrl: string) {
    this.carrerUrl = selectedLocationCarrerUrl || this.config.defaultCareerUrl;
    this.modalService.open(this.confirmationPopupModal, {
      windowClass: 'common-modal',
      centered: true,
      size: 'sm'
    });
  }

  navigateToNewTab($event: MouseEvent) {
    $event.preventDefault();
    this.closeModal();
    window.open(this.carrerUrl, '_blank');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    this.getParamsFromRoute();
    const regionalConfig =
      await this.storeInfoService.getRegionalConfigurationAndSave('');
    //redirect to legacy
    if (
      regionalConfig?.regionalConfigurationOptions['feature_enable_job_search']
        ?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy('job-search');
    }
    if (navigator.geolocation) {
      this.useMyLocationIsSupported = true;
    }
    this.subscribeToRegionalConfigState();
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  private getParamsFromRoute(): void {
    this.productRouteParam = this.route.snapshot.queryParamMap.get('product');
    this.selectedHandoffMode =
      this.route.snapshot.queryParamMap.get('handoffMode') ?? 'carryout';
    this.searchText = decodeURIComponent(
      this.route.snapshot.queryParamMap.get('searchQuery') ?? ''
    );
  }

  resultsFormatter = (x: { name: string }) => {
    return typeof x === 'string' ? x : x.name;
  };

  inputFormatter = (x: { name: string }) => {
    return typeof x === 'string' ? x : x.name;
  };

  async selectedItem(item: NgbTypeaheadSelectItemEvent) {
    this.searching = false;
    this.locationsList = null;
    this.locationSearching = true;
    this.setLocations(
      await this.fetchStoreLocations(item.item?.latitude, item.item?.longitude)
    );

    this.locationSearching = false;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen()),
      filter(() => !this.searching)
    );
    const inputFocus$ = this.focus$.pipe(filter(() => !this.searching));
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((term) => {
        this.searchText = term;
        if (term && term.length >= 4)
          return from(
            this.performSearch(term).finally(() => (this.searching = false))
          );
        else return of([]);
      }),
      tap(() => (this.searching = false))
    );
  };

  getWidthOfInputQuickSearch() {
    let inputTextWidth = document.getElementById('input-wpr')?.offsetWidth;
    if (inputTextWidth) {
      inputTextWidth = inputTextWidth - 42;
    }
    return inputTextWidth;
  }

  public resetSearchData() {
    this.searchText = null;
    this.locationsList = null;
    this.mapLocations$.next([]);
  }

  showUseMyLocation(): boolean {
    return (
      this.useMyLocationIsSupported &&
      (this.locationsList === undefined ||
        this.locationsList === null ||
        this.locationsList?.locations?.length === 0)
    );
  }

  useMyCurrentLocation() {
    this.isLoading = true;
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        // perform store search with lat/long of position
        this.setLocations(
          await this.fetchStoreLocations(
            position.coords.latitude,
            position.coords.longitude
          )
        );
        this.searchText = 'Current Location';
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        // display error toast or error modal based on error.code
        switch (error.code) {
          case error.PERMISSION_DENIED:
            // display error modal
            this.modalService.open(this.commonModal, {
              windowClass: 'common-modal',
              centered: true,
              size: 'sm'
            });
            break;
          case error.POSITION_UNAVAILABLE:
            // display error toast
            this.notificationService.showError(
              'Location information is unavailable.'
            );
            break;
          case error.TIMEOUT:
            // display error toast
            this.notificationService.showError(
              'The request to get your location timed out.'
            );
            break;
          default:
            // display error toast
            this.notificationService.showError(
              'An unknown error occurred requesting your location.'
            );
        }
      },
      {
        timeout: 15000,
        enableHighAccuracy: true
      }
    );
  }

  public closeModal() {
    this.modalService.dismissAll();
  }

  private setLocations(locations: LocationsResponseData | null) {
    this.locationsList = locations;
    this.mapLocations$.next(
      locations?.locations.map((loc) => ({
        lng: loc.longitude,
        lat: loc.latitude,
        description: loc.name,
        id: loc.id
      })) ?? []
    );
  }

  private async performSearch(searchTerm: string) {
    return (
      await this.searchLocationWorkflowService.getGeocodePlaces(
        searchTerm,
        'carryout'
      )
    )?.places;
  }

  private async fetchStoreLocations(lat: number, long: number) {
    return await this.searchLocationWorkflowService.getLocationsByCarryout({
      latitude: lat,
      longitude: long,
      radius: 20
    });
  }
}
