import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { Customer } from '../../../types/customer.types';
import { FeatureState } from '../../types/feature-state';

export type CustomerFeatureState = FeatureState<Customer, 'customer'>;

const initialCustomerFeatureState: CustomerFeatureState = {
  error: null,
  customer: null,
  isLoading: false
};

const CustomerActions = createActionGroup({
  source: 'Customer Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{ error?: string; customer?: Customer }>(),
    'Reset To Default': emptyProps()
  }
});

const CustomerReducer = createReducer(
  initialCustomerFeatureState,
  on(
    CustomerActions.setIsLoading,
    (state): CustomerFeatureState => ({ ...state, isLoading: true })
  ),
  on(
    CustomerActions.setState,
    (state, action): CustomerFeatureState => ({
      ...state,
      isLoading: false,
      error: action.error ?? null,
      customer: action.customer ?? null
    })
  ),
  on(
    CustomerActions.resetToDefault,
    (state): CustomerFeatureState => ({
      ...state,
      ...initialCustomerFeatureState
    })
  )
);

const CustomerFeatureKey = 'customer';

const CustomerFeature = createFeature({
  name: CustomerFeatureKey,
  reducer: CustomerReducer
});

export default {
  ...CustomerFeature,
  actions: CustomerActions,
  initialState: initialCustomerFeatureState
};
