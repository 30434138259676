import { BaseModel } from '@wingstop/models/base.model';

export class ContactOptions extends BaseModel {
  followups: boolean = true;
  upsell: boolean = true;
  emailreceipts: boolean = null;
  optin: boolean = null;
  marketingsms: boolean = null;
  nomnom: any = {};

  constructor(values?: any) {
    super();

    if (values) {
      if (values.emailreceipts != null) {
        values.emailreceipts = values.emailreceipts === 'true';
      }
      if (values.optin != null) {
        values.optin = values.optin === 'true';
      }
      if (values.marketingsms != null) {
        values.marketingsms = values.marketingsms === 'true';
      }
    }

    this.initialize(values);
  }
}
