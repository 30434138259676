import { AlertModel } from '@wingstop/models/alert.model';
import { Basket } from '@wingstop/models/basket.model';
import { OrderResponse } from '@wingstop/models/basket/order-response.model';
import { ClubModel } from '@wingstop/models/club.model';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { Location } from '@wingstop/models/location/location.model';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { ContactDetails } from '@wingstop/models/login/contact-details.model';
import { ContactOptions } from '@wingstop/models/login/contact-options.model';
import { RememberMe } from '@wingstop/models/login/remember-me.model';
import { UserBillingAccount } from '@wingstop/models/login/user-billing-account.model';
import { UserDeliveryAddress } from '@wingstop/models/login/user-delivery-address.model';
import { Menu } from '@wingstop/models/menu.model';
import { Modifier } from '@wingstop/models/menu/option.model';
import { ProductRedirect } from '@wingstop/models/menu/product-redirect.model';
import { UpsellGaTypes } from '@wingstop/models/menu/upsell-product.model';
import { FavoriteOrder } from '@wingstop/models/order/favorite-order.model';
import {
  FiservHostedPageResponse,
  FiservSessionResponse,
} from '@wingstop/models/order/fiserv-payments.model';
import {
  FiservUser,
  PaymentProviders,
} from '@wingstop/models/order/fiserv-payments.model';
import { LastSessionOrder } from '@wingstop/models/order/last-session-order.model';
import { SeoMetadata } from '@wingstop/models/seo/seo-metadata.model';
import { Token } from '@wingstop/models/token.model';
import { IPilotProgram } from '@wingstop/models/pilot/pilot-program.model';
import { IPilotProgramUser } from '@wingstop/models/pilot/pilot-program-user.model';

export class AppState implements AppState {
  searchLocations: Location[];
  errors: any;
  googleMap: google.maps.Map;
  selectedLocation: Location;
  selectedMenu: Menu;
  globalMenu: Menu;
  selectedProductModifiers: Modifier[];
  basket: Basket;
  itemsnottransferred: string[]; // after a basket transfer, this may be populated
  token: Token;
  recent: OrderResponse[] = [];
  authentication: Authentication;
  fiservUser: FiservUser;
  paymentProvider: PaymentProviders;
  locationSupportsPrepaid: boolean;
  fiservSession: FiservSessionResponse;
  fiservIframeResponse: FiservHostedPageResponse;
  inSubmissionFlow: boolean = false;
  clubForm: ClubModel;

  addresses: UserDeliveryAddress[];
  addressesGuest: UserDeliveryAddress[]; // local storage address history (used for logged out users)

  // Flags to see if basket have been changed
  defaultTip: boolean;
  tipIndex: number;
  userTip: boolean;

  billingAccounts: UserBillingAccount[] = [];
  favoriteLocations: Location[];
  favoriteOrders: FavoriteOrder[];
  contactOptions: ContactOptions;
  contactDetails: ContactDetails;
  openAlert: boolean;
  confirmAlert: boolean = false;
  alertContent: AlertModel;
  logout: boolean;
  favoriteName: string = '';
  // Used when navigating from the global menu to a local menu to prime the history
  productRedirect: ProductRedirect;

  // This is the last order done in the CURRENT SESSION ONLY
  lastSessionOrder: LastSessionOrder;

  // Login / signup redirect
  redirect: string;

  // Remember Me
  rememberMe: RememberMe;

  // Has confirmation page been viewd
  confirmationPageViewed: false;

  // CMS Offer redemption
  cmsOffer: CMSPromoOffer;
  cmsOfferRedeemCode: string;
  cmsOfferTimestamp: number;

  // Coupon tooltip
  toolTipSeen: boolean;

  // Geolocation allowed
  geolocationAllowed: string = 'pending';

  //Store guest checkout details
  guestCheckoutEmail: string[];
  currentProductSelections: any;
  upsellProducts: {
    id: number;
    type: string;
    name: string;
    gaType?: UpsellGaTypes;
  }[] = [];
  upsellProduct: {
    id: number;
    type: string;
    name: string;
    gaType: UpsellGaTypes;
  };

  // User detection
  userHasSeenTheLocaleModal: boolean = false;
  userFirstThreeMonths: boolean;
  userLocale: string;

  // account creation errors that occur during the checkout process
  createUserError: any = null;

  // CMS feature flags
  showSecretMenu: boolean = false;
  showUserRegistration: boolean = false;
  showRoundUp: boolean = false;
  showCokeFreestyle: boolean = false;
  showApplePay: boolean = false;

  // Digital menu
  isDigitalMenu: boolean = false;
  startOrderSource: string;
  scrollToCategory: string;
  digitalMenuLoaded: boolean = false;

  // Reorder
  isReorder: boolean = false;

  // Round up
  roundUpSelected: boolean = false;
  roundUpAmount: number;

  // Location attributes
  cokeFreestyle: boolean = false;

  // App banner
  appDownloadBannerEnabled: boolean = false;
  showAppBanner: boolean = false;
  appBannerClosedByUser: boolean = false;

  // Seo
  s3SeoMetadata: SeoMetadata[];

  // Pilot Program Feature Flags
  pilotProgram: IPilotProgram = {
    programEnabled: undefined,
    forceLogoutEnabled: undefined,
    redirectEnabled: undefined,
  };

  // Pilot Program User Data
  pilotProgramUser: IPilotProgramUser = {
    ngfeOrderSession: false,
    ngfeOrderSessionExpires: null,
  };
  lastForceLogoutDate: number;

  detailedBasketDeliveryAddress: UserDeliveryAddress = null;
}
