import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { IAppStore } from '@wingstop/store/app-store';
import { State } from '@ngrx/store';
import { CMSPromoOffer } from '@wingstop/models/cms-promo-offer.model';
import { AnalyticsService } from '@wingstop/services/analytics.service.service';
import { Basket } from '@wingstop/models/basket.model';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss'],
})
export class OfferModalComponent implements OnInit {
  template: string;
  backgroundImage: string;
  modalData: CMSPromoOffer;
  basket: Basket = null;
  currentLocation: any;
  imgLoading: Promise<boolean>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.getMainImage();
  }

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private appStore: State<IAppStore>,
    private analyticsService: AnalyticsService,
    private appStateSelectors: AppStateSelectors
  ) {
    this.imgLoading = new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 1500);
    });
  }

  ngOnInit() {
    this.getMainImage();
    this.currentLocation = this.appStore.getValue().appState.selectedLocation;

    this.appStateSelectors.basket.subscribe((value) => {
      this.basket = value;
    });

    if (this.template === 'offerModal') {
      // GA Offer Modal View event
      try {
        this.analyticsService.logGaEvent({
          event: 'offer_modal_view',
          // 'page': this.analyticsService.formatPageUrl(this.router.routerState.snapshot.url),
          // 'campaign': this.appStore.getValue().appState.cmsOffer.id,
          // 'coupon': this.appStore.getValue().appState.cmsOfferRedeemCode,
          // 'order_method': this.analyticsService.getHandoffMode(this.basket),
        });
      } catch (ignore) {
        // if GA cannot log event (ie due to an ad-blocker), catch error and continue
      }
    }
  }

  closeModal(offerModal?: boolean) {
    this.modalService.dismissAll();

    if (offerModal) {
      // GA Offer Modal Dismiss event
      try {
        this.analyticsService.logGaEvent({
          event: 'offer_modal_dismiss',
          // page: this.analyticsService.formatPageUrl(this.router.routerState.snapshot.url),
          // campaign: this.appStore.getValue().appState.cmsOffer.id,
          // coupon: this.appStore.getValue().appState.cmsOfferRedeemCode,
          // order_method: this.analyticsService.getHandoffMode(this.basket)
        });
      } catch (ignore) {
        // if GA cannot log event (ie due to an ad-blocker), catch error and continue
      }
    }

    return setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  orderNow() {
    if (this.currentLocation) {
      this.router.navigate([
        '/location/' + this.currentLocation.slug + '/menu',
      ]);
    } else {
      this.router.navigate(['/order']);
    }
    this.closeModal();
  }

  ctaAction(route: string, rank: string) {
    if (route) {
      this.router.navigate([route]);
    } else if (!route) {
      this.closeModal();
    }
    this.closeModal();

    // GA Offer Modal Primary/Secondary Button Tap event
    try {
      this.analyticsService.logGaEvent({
        event: 'offer_modal_' + rank + '_button_tap',
        // page: this.analyticsService.formatPageUrl(this.router.routerState.snapshot.url),
        // campaign: this.appStore.getValue().appState.cmsOffer.id,
        // coupon: this.appStore.getValue().appState.cmsOfferRedeemCode,
        // order_method: this.analyticsService.getHandoffMode(this.basket)
      });
    } catch (ignore) {
      // if GA cannot log event (ie due to an ad-blocker), catch error and continue
    }
  }

  errorCtaAction(route: string) {
    if (route && route.includes('/')) {
      this.router.navigate([
        '/location/' + this.currentLocation.slug + '/menu/' + route,
      ]);
    } else if (!route) {
      this.closeModal();
    }

    this.closeModal();
  }

  getMainImage() {
    if (window.screen.width > 500) {
      this.backgroundImage = this.modalData.image1Uri;
    } else {
      this.backgroundImage = this.modalData.image2Uri;
    }
  }
}
