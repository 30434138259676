import { BaseModel } from '@wingstop/models/base.model';
import { BillingAccount } from './billing-account.model';

export class PaymentData extends BaseModel {
  firstname: string = null;
  lastname: string = null;
  emailaddress: string = null;
  contactnumber: string = null;
  reference: string = '';
  orderref: string = '';
  usertype: string = 'guest';
  billingaccounts: BillingAccount[] = [];
  saveonfile: boolean = false;
  authtoken: string = null;
  nomnom: any = {};

  constructor(values?: any) {
    super();

    if (values) {
      if (values.billingaccounts) {
        values.billingaccounts = values.billingaccounts.map(
          (value: any) => new BillingAccount(value)
        );
      }
    }

    this.initialize(values);
  }
}
