import { BaseModel } from '@wingstop/models/base.model';

export class ClubModel extends BaseModel {
  firstName: string = null;
  lastName: string = null;
  email: string = null;
  confirmEmail: string = null;
  birthDate: string = null;
  birthDateDay: string = null;
  birthDateMonth: string = null;
  birthDateYear: string = null;
  zipCode: string = null;
  preferredStore: string = null;
  gender: string = null;
  lunch: boolean = false;
  dinner: boolean = false;
  gameTime: boolean = false;
  unsubscribe: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
