import { BaseModel } from '@wingstop/models/base.model';

export class ProductRedirect extends BaseModel {
  category_slug: string = null;
  product_slug: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
