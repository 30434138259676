import { NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { cacheLoadedGuard } from '../common/guards/cache-loaded.guard';
import { combineGuard } from '../common/guards/combine.guard';
import { featureFlagRouteGuard } from '../common/guards/feature-flag.guard';
import { WingCalculatorComponent } from './components/wing-calculator/wing-calculator.component';
import { WingCalculatorModule } from './wing-calculator.module';
import { ACTIVE_OFFER, ActiveOfferResolver } from '../common';

export const wingCalculatorRoutes = [
  {
    path: 'order/wing-calculator',
    component: WingCalculatorComponent,
    canActivate: [
      combineGuard(
        cacheLoadedGuard,
        featureFlagRouteGuard('enableWingCalculator')
      )
    ],
    resolve: {
      [ACTIVE_OFFER]: ActiveOfferResolver
    }
  }
];

@NgModule({
  imports: [RouterModule, WingCalculatorModule]
})
export class WingCalculatorRoutingModule {
  constructor(router: Router) {
    router.resetConfig?.([...wingCalculatorRoutes, ...(router?.config ?? [])]);
  }
}
