import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { SearchLocationRepository } from '../../repositories/search-location/search-location.repository';
import * as MaybeResponse from '../../types/maybe-response';
import {
  GeocodePlaces,
  LocationResponseData,
  LocationsResponseData,
  RegionSummary,
  SearchByCarryoutRequest,
  SearchByDeliveryAddressRequest,
  SearchByIdRequest,
  SearchByIdsRequest,
  SearchByLocalityRequest,
  SearchBySlugRequest
} from '../../types/search-location.types';
import { fromDto } from '../../utils/from-dto';

@Injectable({ providedIn: 'root' })
export class SearchLocationService {
  constructor(private searchLocationRepository: SearchLocationRepository) {}

  public getGeocodePlaces(
    searchText: string,
    serviceMode: string
  ): Observable<MaybeResponse.MaybeResponse<GeocodePlaces>> {
    return this.searchLocationRepository
      .getGeocodePlaces(searchText, serviceMode)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationsByIds(
    params: SearchByIdsRequest
  ): Observable<MaybeResponse.MaybeResponse<LocationsResponseData>> {
    return this.searchLocationRepository
      .getLocationsByIds(params)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationById(
    params: SearchByIdRequest
  ): Observable<MaybeResponse.MaybeResponse<LocationResponseData>> {
    return this.searchLocationRepository
      .getLocationById(params)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationBySlug(
    params: SearchBySlugRequest
  ): Observable<MaybeResponse.MaybeResponse<LocationResponseData>> {
    return this.searchLocationRepository
      .getLocationBySlug(params)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationsByLocality(
    params: SearchByLocalityRequest
  ): Observable<MaybeResponse.MaybeResponse<LocationsResponseData>> {
    return this.searchLocationRepository
      .getLocationsByLocality(params)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getRegionSummary(): Observable<
    MaybeResponse.MaybeResponse<RegionSummary[]>
  > {
    return this.searchLocationRepository
      .getRegionSummary()
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationsByCarryout(
    params: SearchByCarryoutRequest
  ): Observable<MaybeResponse.MaybeResponse<LocationsResponseData>> {
    return this.searchLocationRepository
      .getLocationsByCarryout(params)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }

  public getLocationByDeliveryAddress(
    params: SearchByDeliveryAddressRequest
  ): Observable<MaybeResponse.MaybeResponse<LocationResponseData>> {
    return this.searchLocationRepository
      .getLocationByDeliveryAddress(params)
      .pipe(map(MaybeResponse.mapData(fromDto)));
  }
}
