import { BaseModel } from '@wingstop/models/base.model';

export class City extends BaseModel {
  stateAbbreviation: string = null;
  stateName: string = null;
  name: string = null;
  count: number = null;
  restaurants: any;

  constructor(values?: any) {
    super();
    // this.initialize(values);
  }
}
