import { Category } from './menu/category.model';
import { BaseModel } from '@wingstop/models/base.model';
import { CategoryProduct } from '@wingstop/models/menu/category-product.model';

export class Menu extends BaseModel {
  id: any = null;
  store_id: any = null;
  imagepath: string = null;
  categories: Category[] = [];

  constructor(values?: any) {
    super();

    if (values) {
      if (values.categories) {
        values.categories = values.categories.map(
          (value: any) => new Category(value)
        );
      }
    }

    this.initialize(values);
  }

  public getProductById(id: any): CategoryProduct {
    let result;

    // visit every product
    this.categories.every((category) => {
      // try to find a product that has a matching id
      result = category.products.find((current: CategoryProduct) => {
        return id === current.id;
      });
      // if we couldn't find one, we continue
      return result === undefined;
    });
    // return the outcome
    return result;
  }

  public getCategoryById(id: any): Category {
    return this.categories.find((current) => {
      return current.id === id;
    });
  }

  public getProductsInMetaCategory(key: string, value: string) {
    let result = this.getCategoryByMeta(key, value);
    let productList: CategoryProduct[] = [];

    result.forEach((current) => {
      productList = productList.concat(current.products.slice());
    });

    return productList;
  }

  public getCategoryByMeta(key: string, value: string) {
    return this.categories.filter((current) => {
      return (
        current.metadata &&
        current.metadata.find((meta) => {
          return (
            meta.key.trim().toLowerCase() === key.toLowerCase() &&
            meta.value.trim().toLowerCase() === value.toLowerCase()
          );
        }) !== undefined
      );
    });
  }

  public getCategoryByProduct(product: CategoryProduct): Category {
    return this.categories.find((category) => {
      // the category product may not have "category_id" so if that fails, try searching upon chain product id
      return (
        category.id === product.category_id ||
        category.products.find((categoryProduct) => {
          return categoryProduct.chainproductid === product.chainproductid;
        }) !== undefined
      );
    });
  }

  public getCategoryByProductId(productId: any): Category {
    return this.getCategoryByProduct(this.getProductById(productId));
  }

  public getProductsByCategorySlug(slug: string) {
    let result = this.categories.find((category) => {
      return category.slug === slug;
    });
    if (result) {
      return result.products;
    }
    return null;
  }
}
