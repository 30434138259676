import { BaseModel } from '@wingstop/models/base.model';

export class Flavor extends BaseModel {
  id: string = null;
  contentType: string = null;
  name: string = null;
  iconName: string = null;
  badgeIconName: string = null;
  description: string = null;
  backgroundImage: string = null;
  flavorType: string = null;
  spiceLevel: number = null;
  offer: string = null;
  cta_link: string = null;
  isActive: boolean = null;
  weight: number = null;
  createdAt: string = null;
  updatedAt: string = null;
  ref: string = null;
  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
