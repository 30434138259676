import { BaseModel } from '@wingstop/models/base.model';
import { Membership } from '@wingstop/models/loyalty/membership.model';

export class LoyaltyScheme extends BaseModel {
  cancheckbalance: boolean;
  id: number;
  label: string;
  membership: Membership = null;
  name: string;
  provider: string;

  constructor(values?: any) {
    super();
    if (values) {
      if (values.membership) {
        values.membership = new Membership(values.membership);
      }
    }
    this.initialize(values);
  }
}
