import { BaseModel } from '@wingstop/models/base.model';

export class BillingSchemeField extends BaseModel {
  name: string = null;
  type: string = null;
  label: string = null;
  minlength: number = 0;
  maxlength: number = 0;
  isMandatory: boolean = true;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
