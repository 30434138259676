import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';

import { AlertModel } from '@wingstop/models/alert.model';
import { AppStateActions } from '@wingstop/store/app/app-state.actions';
import { AppStateSelectors } from '@wingstop/store/app/app-state.selectors';
import { Authentication } from '@wingstop/models/login/authentication.model';
import { ContactDetails } from '@wingstop/models/login/contact-details.model';
import { Router } from '@angular/router';
import { UpdateUser } from '@wingstop/models/login/update-user.model';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-mini';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnDestroy {
  icons = {
    faCircle,
  };
  public content: AlertModel;
  public subscription: Subscription[] = [];
  public favoriteForm: UntypedFormGroup;
  public birthdayForm: UntypedFormGroup;
  public alertState: boolean = false;
  public month: string;
  public day: string;
  public nomnom: any = {};
  public authData: Authentication;
  public updateUser: UpdateUser;
  public contactDetails: ContactDetails;
  public birthdayUpdated: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activeModal: NgbActiveModal,
    private appStateSelector: AppStateSelectors,
    private appStateAction: AppStateActions,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.generateBirthdayForm();
    this.generateForm();

    this.subscription.push(
      this.appStateSelector.alertContent
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: AlertModel) => {
          this.content = data;
        })
    );
    this.subscription.push(
      this.appStateSelector.authentication
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(async (auth: Authentication) => {
          if (auth != null) {
            this.authData = auth;
          } else {
            this.authData = null;
          }
        })
    );
  }

  generateForm() {
    this.favoriteForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(24)]],
    });
  }

  generateBirthdayForm() {
    this.birthdayForm = this.formBuilder.group({
      birthday: [null, [this.validateBirthday()]],
    });
  }

  get form() {
    return this.favoriteForm.controls;
  }

  agreeWithRules() {
    this.submitForm();
    this.appStateAction.confirmAlert();
    this.appStateAction.closeAlertModal();
    this.activeModal.close(true);
    if (this.content.callback) {
      this.content.callback('ok');
    }
  }

  close() {
    this.appStateAction.discardAlert();
    this.appStateAction.closeAlertModal();
    this.activeModal.close(true);
    if (this.content.callback) {
      this.content.callback('cancel');
    }
  }

  submitForm() {
    if (this.content.hasFormField) {
      this.alertState = true;
      if (this.favoriteForm.invalid) {
        this.appStateAction.setFavoriteName(null);
        return;
      }
      this.appStateAction.setFavoriteName(
        this.favoriteForm.controls.name.value
      );
    }
  }

  navToAccount() {
    this.activeModal.close(true);
    this.router.navigate(['/account/settings']);
  }

  public reformatBday(event: any) {
    const current = this.birthdayForm.value.birthday;
    if (event.target.value.length === 1) {
      if (event.target.value >= '2' && event.target.value <= '9') {
        let newVal = [0, event.target.value, '/'].join('');
        this.birthdayForm.controls['birthday'].setValue(newVal);
      }
    } else if (
      event.key !== 'Backspace' &&
      event.which !== 191 &&
      event.target.value.length === 2 &&
      current[1] !== '/'
    ) {
      let newVal = event.target.value + '/';
      this.birthdayForm.controls['birthday'].setValue(newVal);
    } else if (event.target.value.length === 2 && event.which === 191) {
      let newVal = event.target.value + '/';
      this.birthdayForm.controls['birthday'].setValue(newVal);
    } else if (event.target.value.length === 3 && current[2] !== '/') {
      let newVal =
        event.target.value.substr(0, 2) + '/' + event.target.value.substr(2);
      this.birthdayForm.controls['birthday'].setValue(newVal);
    }
  }

  updateBirthday() {
    let birthdayFormData = this.getReducedFormGroupValue(this.birthdayForm);
    this.appStateAction
      .updateUser(new UpdateUser(birthdayFormData))
      .then(() => {
        this.birthdayUpdated = true;
      })
      .catch((e: any) => {
        console.log('e', e);
      });
  }

  viewAccountInfo() {
    this.close();
    this.router.navigate(['/account/settings']);
  }

  validateBirthday() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      let date = moment('1988' + control.value.replace('/', ''));
      if (!date.isValid() || control.value.length !== 5) {
        return { invalid: true };
      }
      const temp = control.value.split('/');
      this.month = temp[0];
      this.day = temp[1];
      return null;
    };
  }

  getReducedFormGroupValue = (frmGroup: UntypedFormGroup): any => {
    try {
      let newObject: any = {};
      Object.keys(this.authData).forEach((x) => {
        if (x && this.authData[x]) {
          newObject[x] = this.authData[x];
        }
      });
      newObject.nomnom = this.getNomNomData(frmGroup.value);
      return newObject;
    } catch (e) {
      return this.authData;
    }
  };

  getNomNomData = (formData: any): any => {
    const zip =
      this.authData.nomnom && this.authData.nomnom.zip
        ? this.authData.nomnom.zip
        : null;
    const dobmonth = this.month ? this.month : null;
    const dobday = this.day ? this.day : null;
    const dobyear = '1904';

    let output = { zip, dobday, dobmonth, dobyear };
    Object.keys(output).forEach((key, index) => {
      if (!output[key]) {
        delete output[key];
      }
    });
    return output;
  };

  ngOnInit() { }

  ngOnDestroy() {
    this.subscription.map((item: Subscription) => item.unsubscribe());

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
