import { BaseModel } from '@wingstop/models/base.model';
import { Image } from '@wingstop/models/image.model';

export class Disclaimer extends BaseModel {
  code: string = null;
  name: string = null;
  disclaimer: string = null;
  images: Image[] = [];

  constructor(values?: any) {
    super();
    if (values) {
      if (values.images) {
        values.images = values.images.map((value: any) => new Image(value));
      }
    }
    this.initialize(values);
  }
}
