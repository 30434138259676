<div class="guest-signup-form">
  <hr class="wri-divider edge-to-edge-divider" />
  <span class="required-field-disclaimer">
    NOTE: Fields marked with an asterisk (*) are required.
  </span>
  <div class="sign-up-form-header-wpr">
    <span class="form-title">Contact Info</span>
  </div>
  <form [formGroup]="signupForm">
    <wri-text-input
      [id]="'firstName'"
      label="First Name *"
      placeholder="First Name"
      autoComplete="given-name"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      formControlName="firstName"
      maxlength="50"
    >
      <wri-form-error
        [control]="signupForm.controls['firstName']"
        errorKey="required"
        display="First name is required"
      ></wri-form-error>
      <wri-form-error
        [control]="signupForm.controls['firstName']"
        errorKey="pattern"
        display="Must be a valid first name"
      ></wri-form-error>
    </wri-text-input>

    <wri-text-input
      [id]="'lastName'"
      label="Last Name *"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      placeholder="Last Name"
      autoComplete="family-name"
      formControlName="lastName"
      maxlength="50"
    >
      <wri-form-error
        [control]="signupForm.controls['lastName']"
        errorKey="required"
        display="Last name is required"
      ></wri-form-error>
      <wri-form-error
        [control]="signupForm.controls['lastName']"
        errorKey="pattern"
        display="Must be a valid last name"
      ></wri-form-error>
    </wri-text-input>

    <wri-text-input
      [disabled]="isUserLoggedIn"
      [id]="'email'"
      label="Email *"
      placeholder="Email"
      autoComplete="email"
      type="email"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      [showLockIcon]="isUserLoggedIn"
      formControlName="email"
      maxlength="128"
    >
      <wri-form-error
        [control]="signupForm.controls['email']"
        errorKey="required"
        display="Email is required"
      ></wri-form-error>
      <wri-form-error
        [control]="signupForm.controls['email']"
        errorKey="pattern"
        display="Must be a valid email"
      ></wri-form-error>
    </wri-text-input>

    <!-- Considering phone number maxlength as 12 considering the formatting "XXX XXX XXXX" -->
    <wri-text-input
      [id]="'phone'"
      label="Phone *"
      placeholder="XXX XXX XXXX"
      autoComplete="tel-national"
      type="tel"
      formControlName="phone"
      [isFormValid]="isFormValid"
      [canShowErrors]="canShowErrors"
      [formatter]="getPhoneFormatter()"
    >
      <wri-form-error
        [control]="signupForm.controls['phone']"
        errorKey="required"
        display="Phone number is required"
      ></wri-form-error>
      <wri-form-error 
        [control]="signupForm.controls['phone']" 
        errorKey="inValidNumber"
        display="Must be a valid phone number">
      </wri-form-error>
    </wri-text-input>

    <ng-container *ngIf="isEnableAccountRegisterCheckout && hasEmail !== null">
      <ng-container *ngIf="!hasEmail">
        <div class="create-account-wpr">
          <div class="create-account-title">
            Check out faster next time! Choose a password to create an account!
          </div>
          <div class="login-wrapper">
            <div class="already-have-account">
              Already have an account?
            </div>
            <button *ngIf="!isUserLoggedIn" wriFocusOutline data-testid="log-in" class="wri-btn wri-btn-text login-text" 
              aria-label="Log in" (click)="logIn($event)">
              Log In
            </button>
          </div>
        </div>
        <div>
          <wri-text-input
            [id]="'password'"
            label="Password"
            type="{{ hidePassword ? 'password' : 'text' }}"
            [icon]="hidePassword ? 'wri-eye' : 'wri-eye-strikethrough'"
            [isFormValid]="isFormValid"
            [canShowErrors]="canShowErrors"
            formControlName="password"
            (iconClick)="handlePasswordIconClick()"
            autoComplete="new-password"
            wriPasswordRequirements
            [value]="signupForm.value.password"
            [passwordRequirements]="passwordRequirements"
          >
            <wri-form-error 
              [control]="signupForm.controls['password']" 
              errorKey="notMetReq"
              display="Password doesn’t meet requirements">
            </wri-form-error>
          </wri-text-input>
        </div>
  
        <wri-text-input
          [id]="'confirmPassword'"
          label="Confirm Password"
          type="{{ hideConfirmPassword ? 'password' : 'text' }}"
          [icon]="hideConfirmPassword ? 'wri-eye' : 'wri-eye-strikethrough'"
          [isFormValid]="isFormValid"
          [canShowErrors]="canShowErrors"
          formControlName="confirmPassword"
          (iconClick)="handleConfirmPasswordIconClick()"
          autoComplete="new-password"
        >
          <div
            class="form-error"
            *ngIf="
              signupForm.controls['password'].value !== '' &&
              signupForm.controls['confirmPassword'].value === ''
            "
          >
            Confirm password is required
          </div>
          <div
            class="form-error"
            *ngIf="
              signupForm.controls['confirmPassword'].value !== '' &&
              signupForm.controls['confirmPassword'].errors &&
              !signupForm.controls['confirmPassword'].errors['pattern'] &&
              signupForm.controls['confirmPassword'].invalid
            "
          >
            Your password and confirmation password must match
          </div>
        </wri-text-input>
      </ng-container>
    </ng-container>

    <!-- Send me offers checkbox input field -->
    <wri-checkbox
      *ngIf="isEnableAccountRegisterCheckout && !hasEmail"
      [id]="'send-offers'"
      class="span-full-width-lg"
      label="Yes, bring me in the know with exclusive flavor news and offers."
      formControlName="sendOffers"
    ></wri-checkbox>
  </form>
</div>
