import { BaseModel } from '@wingstop/models/base.model';

export class BillingField extends BaseModel {
  name: string = null;
  value: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
