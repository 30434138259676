import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { ResponseCacheService } from '@wingstop/services/response-cache.service';
import { TransformerService } from '@wingstop/services/transformer.service';
import { ResponseTransformerService } from '@wingstop/services/response-transformer.service';

@Injectable({
  providedIn: 'root',
})
export class RequestCacheService {
  public cache = new Map<string, [Date, HttpResponse<any>]>();

  constructor(
    private responseCache: ResponseCacheService,
    private transformer: TransformerService,
    private responseTransformer: ResponseTransformerService
  ) {}

  get(request: HttpRequest<any>): HttpResponse<any> {
    const tuple = this.cache.get(request.urlWithParams);
    // Delete any associated cache entries
    if (request.headers && Number(request.headers.get('ui-update-cache'))) {
      // Delete any remaining associated cache entries
      this.cache.forEach((c, i) => {
        const response = c[1] as HttpResponse<any>;
        if (
          request.headers &&
          request.headers.get('ui-cache-key') &&
          response.headers.get('ui-cache-key') ===
            request.headers.get('ui-cache-key')
        ) {
          this.cache.delete(i);
        }
      });
    }
    // Return and send the request
    if (!tuple) {
      return null;
    }

    const expires = tuple[0];
    const httpResponse = tuple[1] as HttpResponse<any>;

    // Don't observe expired keys
    const now = new Date();
    // Delete the request cache
    if (
      (expires && expires.getTime() < now.getTime()) ||
      !Number(request.headers.get('ui-cache-ttl')) ||
      (request.headers && Number(request.headers.get('ui-update-cache')))
    ) {
      // Delete associated url
      this.cache.delete(request.urlWithParams);
      // Delete associated key
      this.responseCache.delete(httpResponse.headers.get('ui-cache-key'));
      // Return null to send the request
      return null;
    }

    return httpResponse;
  }

  set(request: HttpRequest<any>, value: HttpResponse<any>, ttl: number = null) {
    if (ttl) {
      // Setup the expiration
      const expires = new Date();
      expires.setSeconds(expires.getSeconds() + ttl);
      // Run the response transformer to move around properties if necessary
      value = this.transformResponse(value);
      // Update any associated requests
      this.cache.forEach((c, i) => {
        const httpResponse = c[1] as HttpResponse<any>;
        if (
          request.headers &&
          request.headers.get('ui-cache-key') &&
          httpResponse.headers.get('ui-cache-key') ===
            request.headers.get('ui-cache-key')
        ) {
          this.cache.set(i, [expires, value]);
        }
      });
      // Cache the request
      this.cache.set(request.urlWithParams, [expires, value]);
      // Cache the model
      if (value.headers) {
        this.responseCache.set(
          value.headers.get('ui-cache-key'),
          this.transform(value)
        );
      }
    }
  }

  transform(value: HttpResponse<any>) {
    return this.transformer.transform(value);
  }

  transformResponse(value: HttpResponse<any>) {
    return this.responseTransformer.transform(value);
  }
}
