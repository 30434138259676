import { BaseModel } from '@wingstop/models/base.model';

export class Discount extends BaseModel {
  description: string = null;
  amount: number = 0;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
