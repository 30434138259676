import { BaseModel } from '@wingstop/models/base.model';
import { Disclaimer } from '@wingstop/models/disclaimer.model';

export class LocationDisclaimer extends BaseModel {
  imagepath: string = null;
  disclaimers: Disclaimer[] = [];

  constructor(values?: any) {
    super();
    if (values) {
      if (values.disclaimers) {
        values.disclaimers = values.disclaimers.map(
          (value: any) => new Disclaimer(value)
        );
      }
    }
    this.initialize(values);
  }
}
