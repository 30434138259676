import { BaseModel } from '@wingstop/models/base.model';
import { DeliveryAddress } from '@wingstop/models/basket/delivery-address.model';
import { GeocodeResponse } from '@wingstop/models/geocode-response.model';

export class UserDeliveryAddress extends BaseModel {
  id: number = 0;
  building: string = null;
  streetaddress: string = null;
  city: string = null;
  state: string = null;
  zipcode: string = null;
  country: string = null;
  phonenumber: string = null;
  specialinstructions: string = null;
  geocode: GeocodeResponse;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }

  public equals(other: UserDeliveryAddress) {
    return (
      this.building === other.building &&
      this.streetaddress === other.streetaddress &&
      this.city === other.city &&
      this.zipcode === other.zipcode
    );
  }

  public toDeliveryAddress(): DeliveryAddress {
    return new DeliveryAddress({
      id: this.id,
      building: this.building,
      streetaddress: this.streetaddress,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
      phonenumber: this.phonenumber,
      specialinstructions: this.specialinstructions,
    });
  }

  public toGeocodeQuery(): string {
    const address = [this.streetaddress, this.city, this.state, this.zipcode];
    return address.filter((a) => a).join(', ');
  }
}
