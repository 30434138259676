import {
  createActionGroup,
  createFeature,
  createReducer,
  emptyProps,
  on,
  props
} from '@ngrx/store';

import { RegionalConfiguration } from '../../../types/regional-configuration.types';
import { FeatureState } from '../../types/feature-state';

const RegionalConfigurationFeatureKey = 'regionalConfiguration';

export type RegionalConfigurationFeatureState = FeatureState<
  RegionalConfiguration['regionalConfigurationOptions'],
  'regionalConfigurationOptions'
> &
  FeatureState<RegionalConfiguration['tipConfiguration'], 'tipConfiguration'> &
  FeatureState<RegionalConfiguration['featureFlags'], 'featureFlags'> &
  FeatureState<RegionalConfiguration['outageMessagePresentedDate'], 'outageMessagePresentedDate'>;

const initialRegionalConfigurationState: RegionalConfigurationFeatureState = {
  isLoading: false,
  error: null,
  regionalConfigurationOptions: null,
  tipConfiguration: null,
  featureFlags: null,
  outageMessagePresentedDate: null
};

const RegionalConfigurationActions = createActionGroup({
  source: 'RegionalConfiguration Feature',
  events: {
    'Set Is Loading': emptyProps(),
    'Set State': props<{
      error?: string | null;
      regionalConfigurationOptions?:
        | RegionalConfiguration['regionalConfigurationOptions']
        | null;
      tipConfiguration?: RegionalConfiguration['tipConfiguration'] | null;
      featureFlags?: RegionalConfiguration['featureFlags'] | null;
    }>(),
    'Set OutageMessagePresentedDate': props<{ outageMessagePresentedDate?: string | null }>(),
    'Reset To Default': emptyProps()
  }
});

const RegionalConfigurationReducer = createReducer(
  initialRegionalConfigurationState,
  on(
    RegionalConfigurationActions.setIsLoading,
    (state): RegionalConfigurationFeatureState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    RegionalConfigurationActions.setState,
    (state, action): RegionalConfigurationFeatureState => {
      return {
        ...state,
        isLoading: false,
        error: action.error ?? null,
        regionalConfigurationOptions:
          action.regionalConfigurationOptions ?? null,
        tipConfiguration: action.tipConfiguration ?? null,
        featureFlags: action.featureFlags ?? null
      };
    }
  ),
  on(
    RegionalConfigurationActions.setOutagemessagepresenteddate,
    (state, action): RegionalConfigurationFeatureState => ({
      ...state,
      outageMessagePresentedDate: action.outageMessagePresentedDate ?? null
    })
  ),
  on(
    RegionalConfigurationActions.resetToDefault,
    (state): RegionalConfigurationFeatureState => ({
      ...state,
      ...initialRegionalConfigurationState
    })
  )
);

const RegionalConfigurationFeature = createFeature({
  name: RegionalConfigurationFeatureKey,
  reducer: RegionalConfigurationReducer
});

export default {
  ...RegionalConfigurationFeature,
  actions: RegionalConfigurationActions,
  initialState: initialRegionalConfigurationState
};
