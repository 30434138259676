import { EcommFeatureFlags } from '../config/ecomm-feature-flags.provider';
import {
  RegionalConfiguration,
  RegionalConfigurationDto,
  TipConfiguration,
  TipConfigurationDto
} from '../types/regional-configuration.types';
import { fromDto } from './from-dto';

const canonicalizeKey = (rawKey: string) => {
  const [enable, ...rawFeatureNameParts] = rawKey.split('_').slice(1);
  const titlizedFeatureNameParts = rawFeatureNameParts.map((name) => {
    const [firstLetter, ...restOfName] = name.split('');
    return [firstLetter.toUpperCase(), ...restOfName].join('');
  });
  return [enable, ...titlizedFeatureNameParts].join('');
};

const asFeatureFlags = (
  input: RegionalConfigurationDto
): Partial<EcommFeatureFlags> => {
  const regionalConfigOptions = input.regionalConfigurationOptions;
  return Object.keys(regionalConfigOptions)
    .filter((k) => /^feature_enable/.test(k))
    .reduce(
      (acc, key) => ({
        ...acc,
        [canonicalizeKey(key)]: regionalConfigOptions[key].value === 'true'
      }),
      {} as Partial<EcommFeatureFlags>
    );
};

const asTipConfiguration =
  (handOffMode: string) =>
  (input: TipConfigurationDto): TipConfiguration => ({
    threshold: input.threshold,
    presetOptions:
      input?.presetOptions?.find((po) => po.handoffMode === handOffMode) ?? null
  });

export const fromRegionalConfigurationDto =
  (handoffMode: string) =>
  (input: RegionalConfigurationDto): RegionalConfiguration => ({
    regionalConfigurationOptions: fromDto(input.regionalConfigurationOptions),
    tipConfiguration: asTipConfiguration(handoffMode)(input.tipConfiguration),
    featureFlags: asFeatureFlags(input),
    outageMessagePresentedDate: input.outageMessagePresentedDate
  });
