import { BaseModel } from '@wingstop/models/base.model';
import { GeocodeAddressComponent } from '@wingstop/models/geocode-address-component.model';
import { GeocodeParsedAddress } from '@wingstop/models/geocode-parsed-address.model';

export class GeocodeResponse extends BaseModel {
  place_id: string;
  place_name: string;
  address: string;
  latitude: number;
  longitude: number;
  address_components: GeocodeAddressComponent[];
  parsedAddress: GeocodeParsedAddress;
  relevance: number;
  provider: string;

  constructor(values?: any) {
    super();

    if (values.formatted_address) {
      values.address = values.formatted_address;
    }

    if (values.geometry && values.geometry.location) {
      values.latitude = values.geometry.location.lat;
      values.longitude = values.geometry.location.lng;
    }

    if (values.geometry && values.geometry.coordinates) {
      values.latitude = values.geometry.coordinates[1];
      values.longitude = values.geometry.coordinates[0];
    }

    if (values.address_components && Array.isArray(values.address_components)) {
      values.address_components = values.address_components.map(
        (value: any) => new GeocodeAddressComponent(value)
      );
      values.parsedAddress = new GeocodeParsedAddress(values);
    } else if (values.place_name) {
      values.parsedAddress = new GeocodeParsedAddress(values);
      values.address = values.parsedAddress.format();
    }

    this.initialize(values);
  }
}
