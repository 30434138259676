import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ECOMM_API_CONFIG,
  EcommAPIConfig
} from '../../config/ecomm-config.provider';
import {
  CustomerDto,
  CustomerOrdersDto,
  CustomerOrdersResponse,
  CustomerPutRequest,
  CustomerResponse,
  DeleteAccountResponse,
  DeleteCustomerAddressResponse,
  DeleteCustomerFavoriteLocationsResponse,
  DeliveryAddressPostRequest,
  DeliveryAddressPostResponse,
  FavoriteLocationResponse,
  ReOrderPostRequest,
  SignupCustomerDto,
  SignupCustomerRequest,
  SignupCustomerResponse
} from '../../types/customer.types';
import { MaybeResponse } from '../../types/maybe-response';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';
import { handleAPIResponse } from '../../utils/handle-api-response';
import { CartDto, CartResponse } from '../../types/cart.types';

@Injectable({ providedIn: 'root' })
export class CustomerRepository {
  constructor(
    private http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService
  ) {}

  public getCustomer(
    accessToken: string
  ): Observable<MaybeResponse<CustomerDto>> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      sourcePlatform: 'web'
    };
    const url = `${this.config.baseUrl}/api/customer`;
    return this.http
      .get<CustomerResponse>(url, { headers: headers })
      .pipe(
        handleAPIResponse(
          CustomerResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public updateCustomer(
    accessToken: string,
    request: CustomerPutRequest
  ): Observable<MaybeResponse<CustomerDto>> {
    const url = `${this.config.baseUrl}/api/customer`;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };

    return this.http
      .put<CustomerResponse>(url, request, { headers })
      .pipe(
        handleAPIResponse(
          CustomerResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public getOrderHistory(
    accessToken: string
  ): Observable<MaybeResponse<CustomerOrdersDto>> {
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    const url = `${this.config.baseUrl}/api/customer/v2/orders`;

    return this.http
      .get<CustomerOrdersResponse>(url, {
        headers: headers
      })
      .pipe(
        handleAPIResponse(
          CustomerOrdersResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public deleteFavoriteLocation(
    locationId: string
  ): Observable<MaybeResponse<boolean>> {
    const url = `${this.config.baseUrl}/api/customer/favoritelocations/${locationId}`;
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http
      .delete<DeleteCustomerFavoriteLocationsResponse>(url, { headers })
      .pipe(
        map(() => ({ data: true, error: null })),
        handleAPIResponse(
          DeleteCustomerFavoriteLocationsResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public setFavoriteLocation(
    locationId: string
  ): Observable<MaybeResponse<boolean>> {
    const url = `${this.config.baseUrl}/api/customer/favoritelocations`;
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .put<FavoriteLocationResponse>(
        url,
        { locationId: locationId },
        { headers }
      )
      .pipe(
        map(() => ({ data: true, error: null })),
        handleAPIResponse(
          FavoriteLocationResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public saveDeliveryAddress(
    accessToken: string,
    request: DeliveryAddressPostRequest
  ): Observable<MaybeResponse<boolean>> {
    const url = `${this.config.baseUrl}/api/customer/addresses/delivery`;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    return this.http
      .post<DeliveryAddressPostResponse>(url, request, { headers })
      .pipe(
        map(() => ({ data: true, error: null })),
        handleAPIResponse(
          DeliveryAddressPostResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public deleteMyAddress(
    addressId: string
  ): Observable<MaybeResponse<boolean>> {
    const url = `${this.config.baseUrl}/api/customer/addresses/delivery/${addressId}`;
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http
      .delete<DeleteCustomerAddressResponse>(url, { headers })
      .pipe(
        map(() => ({ data: true, error: null })),
        handleAPIResponse(
          DeleteCustomerAddressResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public reorder(
    accessToken: string,
    request: ReOrderPostRequest
  ): Observable<MaybeResponse<CartDto>> {
    const url = `${this.config.baseUrl}/api/customer/reorder`;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    return this.http
      .post<CartResponse>(url, request, { headers })
      .pipe(
        handleAPIResponse(
          CartResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public signUp(
    request: SignupCustomerRequest,
    recaptchaToken: string
  ): Observable<MaybeResponse<SignupCustomerDto>> {
    const url = `${this.config.baseUrl}/api/customer`;
    const headers = {
      recaptchatoken: recaptchaToken
    };
    return this.http
      .post<SignupCustomerResponse>(url, request, { headers })
      .pipe(
        handleAPIResponse(
          SignupCustomerResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }

  public deleteAccount(
    recaptchatoken: string
  ): Observable<MaybeResponse<boolean>> {
    const url = `${this.config.baseUrl}/api/customer`;
    const headers = {
      'Content-Type': 'application/json',
      recaptchatoken
    };
    return this.http.delete<DeleteAccountResponse>(url, { headers }).pipe(
      map(() => ({ data: true, error: null })),
      handleAPIResponse(
        DeleteAccountResponse,
        [],
        this.featureFlagService.featureFlags.enableDevLogs
      )
    );
  }
}
