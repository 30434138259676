import { BaseModel } from '@wingstop/models/base.model';
import { BasketProduct } from '@wingstop/models/basket/basket-product.model';
import { Basket } from '@wingstop/models/basket.model';
import { Location } from '../location/location.model';

export class FavoriteOrder extends BaseModel {
  id: number = null;
  name: string = null;
  vendorid: number = null;
  vendorname: string = null;
  disabled: boolean = true;
  online: boolean = true;
  products: BasketProduct[] = [];

  storeInfo: Location; //added by ddenton to fix favorites bug; not in olo model

  // for displaying errors
  error: string = null;
  success: string = null;

  constructor(values?: any) {
    super();

    if (values.products) {
      values.products = values.products.map(
        (value: any) => new BasketProduct(value)
      );
    }

    this.initialize(values);
  }

  equals(basket: Basket) {
    // if we have an equals number of products, do some more checking
    if (basket.products.length === this.products.length) {
      // assume there's a match
      let success = true;
      // for every product in the basket
      basket.products.forEach((basketProduct) => {
        // verify that products is in this favorite order
        success =
          success &&
          this.products.find((favOrderProduct) => {
            return basketProduct.productId === favOrderProduct.productid;
          }) !== undefined;
      });
      return success;
    }

    return false;
  }
}
