import { BaseModel } from '@wingstop/models/base.model';

export class Reward extends BaseModel {
  applied: boolean;
  availableoffline: boolean;
  availableonline: boolean;
  categories: any;
  description: any;
  expirationdate: any;
  externalreference: string;
  fineprint: string;
  imageurl: string;
  items: any;
  label: string;
  membershipid: number;
  quantityapplied: number;
  quantityavailable: number;
  reference: string;
  rewardid: any;
  type: string;
  validminutes: any;
  value: number;
  vendorrefs: any;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
