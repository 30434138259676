/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { RegionalConfigurationRepository } from '../../repositories/regional-configuration/regional-configuration.repository';
import * as MaybeResponse from '../../types/maybe-response';
import { RegionalConfiguration } from '../../types/regional-configuration.types';
import { fromRegionalConfigurationDto } from '../../utils/from-regional-configuration-dto';

@Injectable({ providedIn: 'root' })
export class RegionalConfigurationService {
  constructor(
    private regionalConfigurationRepository: RegionalConfigurationRepository
  ) {}

  public getRegionalConfiguration(
    handoffMode: string
  ): Observable<MaybeResponse.MaybeResponse<RegionalConfiguration>> {
    return this.regionalConfigurationRepository
      .getRegionalConfiguration()
      .pipe(
        map(MaybeResponse.mapData(fromRegionalConfigurationDto(handoffMode)))
      );
  }
}
