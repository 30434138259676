import { BaseModel } from '@wingstop/models/base.model';

export class UpdateUser extends BaseModel {
  contactnumber: string = null;
  emailaddress: string = null;
  firstname: string = null;
  lastname: string = null;
  nomnom: any = {};

  constructor(values?: any) {
    super();
    this.initialize(values);
    this.initNomnom(values);
  }

  initNomnom = (values: any) => {
    Object.keys(values).forEach((prop) => {
      if (this.nomnom && this.nomnom[prop]) {
        delete this[prop];
      }
    });
  };
}
