import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'dev',

  ping: {
    clientId: '90e85093-4605-4342-8782-f9403d39953a',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  ecomm: {
    baseUrl: 'https://ecomm.dev.us.regional.dev.cf.10piececombo.com',
    menuUrl: 'https://ecomm.dev.us.regional.dev.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.dev.us.regional.dev.cf.10piececombo.com'
  },
  ngfeBaseUrl: 'https://dev.dev.ordering.wingstop.com',

};
