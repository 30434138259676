/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  from,
  map,
  NEVER,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';
import { PathParams } from '../../../ecomm/constants/params';
import { FeaturesState } from '../../../ecomm/store/types/features-state';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { CartWorkflowService } from '../../../ecomm/workflows/cart/cart-workflow.service';
import { CustomerWorkflowService } from '../../../ecomm/workflows/customer/customer-workflow.service';
import { SearchLocationWorkflowService } from '../../../ecomm/workflows/search-location/search-location-workflow.service';
import { SelectedHandoffModeService } from '../../../ecomm/workflows/selected-handoff-mode/selected-handoff-mode.service';
import { StoreInfoWorkflowService } from '../../../ecomm/workflows/store-info/store-info-workflow.service';

import { BasePageService } from './base-page-service';
import { HandoffMode, SelectedHandoffMode } from '../../../ecomm/types/selected-handoff-mode.types';

@Injectable()
export class LocationMenuPageService extends BasePageService {
  queryParams:
    | { handoffMode: SelectedHandoffMode; locationSlug: string | null }
    | undefined;
  constructor(
    private selectedHandoffModeService: SelectedHandoffModeService,
    private storeInfoService: StoreInfoWorkflowService,
    private cartService: CartWorkflowService,
    @Inject(AsynchronousDispatcher)
    asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    redirectService: RedirectService,
    store: Store,
    userAccountService: CustomerWorkflowService,
    private locationDetailsWorkflowService: SearchLocationWorkflowService
  ) {
    super(redirectService, store, userAccountService, asynchronusDispatcher);
  }

  private getLoadParams = (route: ActivatedRouteSnapshot) => {
    return combineLatest([
      from(this.selectedHandoffModeService.get(route)),
      of(route.paramMap.get(PathParams.locationSlug))
    ]).pipe(
      map(([handoffMode, locationSlug]) => ({ handoffMode, locationSlug }))
    );
  };

  timeout$ = () => NEVER;

  loadData$(route: ActivatedRouteSnapshot) {
    return new Observable((subscriber) =>
      this.getLoadParams(route)
        .pipe(
          tap((params) => {
            this.queryParams = params;
          }),
          switchMap((params) =>
            this.storeInfoService.getStoreInfoBySlug(
              params.locationSlug ?? '',
              params.handoffMode?.handoffMode ?? ''
            )
          ),
          switchMap((data) =>
            from(
              this.locationDetailsWorkflowService.getLocationById({
                locationId: data?.storeDetails.id ?? ''
              })
            ).pipe(
              switchMap(() =>
                this.storeInfoService.getRegionalConfigurationAndSave(
                  this.queryParams?.handoffMode?.handoffMode ?? HandoffMode.carryout
                )
              )
            )
          ),
          tap(() => subscriber.next(undefined)),
          switchMap(() => from(this.cartService.getOrCreate(route))),
          tap(() => subscriber.next(undefined))
        )
        .subscribe(() => subscriber.complete())
    );
  }

  isInInvalidState(state: FeaturesState): boolean {
    const cartError = (state as FeaturesState).cart.error;
    const storeInfoError = (state as FeaturesState).storeInfo.error;
    return !!cartError || !!storeInfoError;
  }
}
