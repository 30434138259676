import { BaseModel } from '@wingstop/models/base.model';

export class CustomField extends BaseModel {
  isrequired: boolean = true;
  scope: string = null;
  validationregex: string = null;
  value: string = null;
  label: string = null;
  id: any = 0;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
