import { BaseModel } from '@wingstop/models/base.model';
import { BillingField } from '@wingstop/models/basket/billing-field.model';

export class BillingAccount extends BaseModel {
  cardnumber: string = null;
  expiryyear: string = null;
  expirymonth: string = null;
  saveonfile: boolean = false;
  amount: number = null;
  tipportion: number = 0;
  streetaddress: string = '';
  streetaddress2: string = '';
  city: string = '';
  state: string = '';
  zip: string = null;
  cvv: string = null;
  transactionid: string = null;
  billingmethod: string = 'creditcard';
  billingfields: BillingField[] = [];

  constructor(values?: any) {
    super();

    if (values) {
      if (values.billingfields) {
        values.billingfields = values.billingfields.map(
          (value: any) => new BillingField(value)
        );
      }
    }

    this.initialize(values);
  }
}
