<wri-feature-flags [with]="['enableActiveOffer']">
  <div class="icon-wrapper" (click)="openOfferModal()"
       *ngIf="activeOffer !== null && showTag" data-testid="activeOffer">
    <a
      wriFocusOutline
      class="wri-active-offer-badge"
      data-testid="wri-active-offer-click"
      tabindex="0"
    >
      <wri-icon icon="wri-offer-badge" class="icon"></wri-icon>
      <span
        *ngIf="cartState?.cart?.offer === null || (cartState?.cart?.offer?.code| lowercase) !== (activeOffer| lowercase)"
        data-testid="offer-not-applied"
        class="offer-badge">
        1
      </span>
      <span *ngIf="(cartState?.cart?.offer?.code| lowercase) === (activeOffer| lowercase)"
        data-testid="offer-success"
        class="offer-badge offer-success">
        <wri-icon icon="wri-check" class="icon"></wri-icon>
      </span>

    </a>
  </div>
  <ng-template #commonModal let-close="close" let-dismiss="dismiss">
    <wri-common-modal [close]="close" [dismiss]="dismiss">
      <div *ngIf="isLoading" class="spinner-wrapper-overlay">
        <wri-spinner></wri-spinner>
        <span class="loading-indicator-text">Loading Offer</span>
      </div>
      <div class="offer-details-modal" *ngIf="!isLoading">
        <ng-container
          *ngIf="isEnableOfferModalWithMetadata;
          then offerModalWithMetadata;
          else offerModalWithoutMetadata"
        >
        </ng-container>
        <ng-template #offerModalWithoutMetadata>
          <span class="offer-details-title">{{ offerDetails?.name ? offerDetails?.name : offerDetails?.code  }}</span>
          <span class="offer-details-code">{{ offerDetails?.code}}</span>
          <span class="offer-details-description">{{ offerDetails?.customerDescription}}</span>
        </ng-template>

        <ng-template #offerModalWithMetadata>
          <div *ngIf="offerDetails?.images" class="wri-product-img">
            <img
              height="100%"
              src="{{ offerDetails?.images[0]?.uri }}"
              alt="{{ offerDetails?.images[0]?.alternateText }}"
            />
          </div>
          <span class="offer-details-title">
            {{  offerErrorResponse ? 'Oops!' : offerDetails?.customerDescription }}
          </span>
          <span class="offer-details-description">
            {{ offerDetails?.legalTerms || offerErrorResponse }}
          </span>
          <div class="offer-details-cta-buttons">
            <button
              *ngIf="offerDetails?.secondaryButtonText && offerDetails?.secondaryButtonUrl"
              class="wri-btn wri-btn-secondary"
              data-testid="wri-active-offer-secondary-button-click"
              (click)="onCtaClick($event, offerDetails?.secondaryButtonUrl)">
              {{ offerDetails?.secondaryButtonText }}
            </button>
            <button
              *ngIf="offerDetails?.primaryButtonText && offerDetails?.primaryButtonUrl"
              class="wri-btn wri-btn-primary"
              data-testid="wri-active-offer-primary-button-click"
              (click)="onCtaClick($event, offerDetails?.primaryButtonUrl)">
              {{ offerDetails?.primaryButtonText }}
            </button>
          </div>
        </ng-template>
      </div>
    </wri-common-modal>
  </ng-template>
</wri-feature-flags>
