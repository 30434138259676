import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import moment from 'moment-timezone';

import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import {
  FulfillmentTimeAvailableDateTime,
  FulfillmentTimes
} from '../../../../ecomm/types/fulfillment-times.types';
import { FutureDateTimeChangedEvent } from '../../../common/types/cart.types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wri-future-fulfillment-date-time',
  templateUrl: './future-fulfillment-date-time.component.html',
  styleUrls: ['./future-fulfillment-date-time.component.scss']
})
export class FutureFulfillmentDateTimeComponent implements OnInit, OnChanges {
  @Input() public fulfillmentTimes: FulfillmentTimes | null = null;
  @Input() public errorText: string | undefined;
  @Input() public fulfillmentLaterFlowStateOpen: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  @Input() public ftForLater = '';
  @Input() public cartFulfillmentTime: string | null = null;

  @Output()
  public futureDateTime: EventEmitter<FutureDateTimeChangedEvent> =
    new EventEmitter<FutureDateTimeChangedEvent>();

  @Output()
  public isSelectedDateTimeValid: EventEmitter<boolean> =
    new EventEmitter<boolean>(true);

  public selectedDateDropdownIndex = -1;
  public selectedTimeDropdownIndex = -1;

  public selectedDropdownTimes: string[] = [];

  private selectedDate = '';
  private selectedTime = '';

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.updateSelectedIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { fulfillmentTimes } = changes;
    if (fulfillmentTimes && fulfillmentTimes?.currentValue) {
      this.fulfillmentTimes = fulfillmentTimes.currentValue;
      this.updateSelectedIndex();
    }
  }

  updateSelectedIndex(): void {
    if (this.fulfillmentTimes) {
      const { days } = this.fulfillmentTimes as FulfillmentTimes;
      const selectedCartDateTime = this.ftForLater.split(' at ');
      const cartData = selectedCartDateTime[0];
      const cartTime = selectedCartDateTime[1];

      //date
      this.selectedDateDropdownIndex = this.findIndexForDayValue(cartData);
      const selectedDay =
        this.fulfillmentTimes?.days?.[this.selectedDateDropdownIndex];
      this.selectedDropdownTimes = selectedDay?.availableTimes ?? [];

      //time
      this.selectedTimeDropdownIndex = this.findIndexFotTimeValue(
        this.selectedDropdownTimes,
        cartTime
      );

      if (this.cartFulfillmentTime === null) {
        // initial loading of cart
        this.isSelectedDateTimeValid.emit(true);
      } else {
        // after cart is transferred
        if (
          this.selectedDateDropdownIndex > -1 &&
          this.selectedTimeDropdownIndex > -1
        ) {
          this.isSelectedDateTimeValid.emit(true);
        } else {
          this.isSelectedDateTimeValid.emit(false);
        }
      }

      this.selectedDate = days?.[this.selectedDateDropdownIndex]?.date;
      this.selectedTime =
        this.selectedDropdownTimes[this.selectedTimeDropdownIndex];
    }
  }

  findIndexFotTimeValue(dropdownTimes: string[], cartTime: string): number {
    const index = dropdownTimes?.findIndex((time) => {
      return this.getFormattedTime(time) === cartTime;
    });
    return index ?? -1;
  }

  findIndexForDayValue(value: string): number {
    const index =
      this.fulfillmentTimes?.days?.findIndex((day) => {
        const formattedDateLen = this.getFormattedDate(day.date)?.split(',')?.length || 0;
        const cartDateLen = value?.split(',')?.length || 0;
        return (
          (this.getFormattedDate(day.date)?.split(',')[0].toLowerCase() ===
          value.split(',')[0].toLowerCase()) &&
          (this.getFormattedDate(day.date)?.split(',')[formattedDateLen]?.toLowerCase() ===
          value.split(',')[cartDateLen]?.toLowerCase())
        );
      }) ?? -1;
    return index !== -1 ? index : -1;
  }

  getFormattedDate(date: string) {
    const timeZone =
      this.fulfillmentTimes && this.fulfillmentTimes.locationTimeZone;

    /* convert dropdown values to denver */
    return (
      timeZone &&
      moment.tz(date, timeZone).calendar(null, {
        sameDay: '[Today], dddd, MMMM Do',
        nextDay: '[Tomorrow], dddd, MMMM Do',
        nextWeek: 'dddd, MMMM Do',
        sameElse: 'dddd, MMMM Do'
      })
    );
  }

  getFormattedTime(time: string) {
    return moment(time, 'hh:mm').format('LT');
  }

  public onDayChange(event: { target: { value: number } }) {
    this.fulfillmentLaterFlowStateOpen.next(true);
    const { days } = this.fulfillmentTimes as FulfillmentTimes;
    this.selectedDateDropdownIndex = event.target.value;
    this.selectedTimeDropdownIndex = -1;

    //date
    this.selectedDate = days[this.selectedDateDropdownIndex].date;

    //selected times
    this.selectedDropdownTimes =
      days[this.selectedDateDropdownIndex]?.availableTimes;

    this.selectedTime =
      this.selectedDropdownTimes[this.selectedTimeDropdownIndex];
  }

  public onTimeChange(event: { target: { value: number } }) {
    const { days } = this.fulfillmentTimes as FulfillmentTimes;
    this.fulfillmentLaterFlowStateOpen.next(true);
    this.selectedTimeDropdownIndex = event.target.value;

    //time
    this.selectedTime =
      this.selectedDropdownTimes[this.selectedTimeDropdownIndex];

    //date
    this.selectedDate = days[this.selectedDateDropdownIndex].date;
    this.fulfillmentLaterFlowStateOpen.next(false);

    this.futureDateTime.emit({
      date: this.selectedDate,
      time: this.selectedTime
    });

    this.analyticsService.logGaEvent({
      event: 'order_time'
    });
  }

  trackByFn(index: number, dateTime: FulfillmentTimeAvailableDateTime) {
    return dateTime?.date || index;
  }
}
