import { BaseModel } from '@wingstop/models/base.model';
import { CategoryProduct } from './category-product.model';
import { environment } from '@wingstop/environments/environment';

export class Category extends BaseModel {
  id: any = null;
  menu_id: any = null;
  name: string = null;
  created_at: string = null;
  updated_at: string = null;
  description: string = null;
  slug: string = null;
  parent_category_id: number = null;
  products: CategoryProduct[] = [];
  categories: Category[] = [];
  category_images: any[] = [];
  pivot: any[] = [];
  images: any[] = [];
  metadata: { key: string; value: string }[];
  imagefilename: string = null;

  constructor(values?: any) {
    super();
    if (values) {
      if (values.name) {
        values.slug = this.slugify(values.name);
      }
      if (values.products) {
        values.products = values.products.map(
          (value: any) =>
            new CategoryProduct({ ...value, ...{ category_slug: values.slug } })
        );
      }
      if (values.categories) {
        values.categories = values.categories.map(
          (value: any) => new Category(value)
        );
      }

      if (values.images) {
        let image = values.images.find((image: any) => {
          return image.groupname === environment.categoryImageId;
        });

        if (image) {
          values.imagefilename = image.filename;
        }
      }
    }
    this.initialize(values);
  }

  private slugify(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
}
