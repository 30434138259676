import { BaseModel } from '@wingstop/models/base.model';

export class TimeWanted extends BaseModel {
  day: number = null;
  hour: number = null;
  minute: number = null;
  month: number = null;
  year: number = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
