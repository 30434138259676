<div [id]="'mod-group-section-' + modifierGroup.id">
  <div
    class="sticky-header"
    [id]="'header-' + modifierGroup.id"
    wriInteractionStudio="data-chainmodifierid"
    [interactionStudioId]="modifierGroup.id"
  >
    <div
      class="wri-mod-group"
      data-toggle="collapse"
      role="button"
      [attr.data-target]="'#mod-' + modifierGroup.id"
      [attr.controls]="'mod-' + modifierGroup.id"
      [attr.aria-expanded]="isModGroupFirstAndRequired ? 'true' : 'false'"
    >
      <wri-icon icon="wri-chevron-right" class="mod-group-chevron"></wri-icon>
      <span
        wriFocusOutline
        [attr.data-testid]="
          modifierGroup.id | prepend : 'mod-group-header-title-'
        "
        class="mod-group-header-title"
        aria-live="polite"
      >
        {{ modifierGroup.name }}</span
      >
      <span
        wriFocusOutline
        [attr.data-testid]="
          modifierGroup.id | prepend : 'mod-group-selection-msg-'
        "
        class="mod-group-selection-msg"
        aria-live="polite"
      >
        <ng-container
          *ngIf="
            modifierGroupFormData?.modifiers &&
              modifierGroupFormData?.modifiers.length > 0;
            then hasModifiersSelected;
            else noModifiersSelected
          "
        ></ng-container>
        <ng-template #noModifiersSelected>
          {{ modifierGroup.message }}: None selected
        </ng-template>
        <ng-template #hasModifiersSelected>
          {{ modifierGroup.message }}:
          <span
            *ngFor="let modifier of modifierGroupFormData.modifiers; index as i"
          >
            <span *ngIf="i > 0">, </span>
            <span
              *ngIf="
                modifier.quantity > 1;
                then multipleModifiers;
                else singleModifier
              "
            ></span>
            <ng-template #singleModifier>{{ modifier.name }}</ng-template>
            <ng-template #multipleModifiers
              >{{ modifier.quantity }} x {{ modifier.name }}</ng-template
            >
          </span>
        </ng-template>
      </span>
      <wri-badge
        *ngIf="modifierGroup.required"
        [icon]="areSelectionsValid() ? 'wri-check' : null"
        [type]="
          areSelectionsValid()
            ? 'success'
            : startPDPValidations
            ? 'error'
            : 'warn'
        "
        [label]="areSelectionsValid() ? 'Done' : 'Required'"
        class="mod-group-header-badge"
      ></wri-badge>
    </div>
  </div>
  <div
    [id]="'mod-' + modifierGroup.id"
    class="collapse {{
      isModGroupFirstAndRequired ? 'show' : ''
    }} wri-mod-group-options"
  >
    <form [formGroup]="modifierSelectionForm">
      <span class="mod-group-elements-container">
        <ng-container
          *ngFor="
            let modGroupElement of modifierGroup.modifierGroupElements
              | sort : 'asc' : 'sortOrder'
          "
        >
          <div
            class="mod-group-element"
            wriInteractionStudio="data-chainoptionid"
            [interactionStudioId]="
              isSimpleModifier(modGroupElement)
                ? modGroupElement.modifier.id
                : modGroupElement.item.id
            "
          >
            <div class="element-border">
              <wri-modifier-element
                [modGroupId]="modifierGroup.id"
                [selectedModifiers]="
                  modifierSelectionForm.value?.modifiers || []
                "
                formControlName="modifiers"
                [type]="
                  isSimpleModifier(modGroupElement)
                    ? 'simpleModifier'
                    : 'complexModifier'
                "
                [parentId]="
                  isSimpleModifier(modGroupElement)
                    ? modGroupElement.modifier.id
                    : modGroupElement.item.id
                "
                [simpleModifierData]="
                  isSimpleModifier(modGroupElement)
                    ? updateSimpleOrComplexModifierWithCart(
                        modGroupElement.modifier,
                        modifierGroup
                      )
                    : null
                "
                [complexModifierData]="
                  isSimpleModifier(modGroupElement)
                    ? null
                    : updateSimpleOrComplexModifierWithCart(
                        modGroupElement.item,
                        modifierGroup
                      )
                "
                [maxSelectionsAllowed]="modifierGroup.maxSelectionsAllowed"
                [class]="'modifier-element-group' + modifierGroup.id"
                (resetPDPValidations)="resetPDPValidations()"
              ></wri-modifier-element>
            </div>
            <hr class="wri-mod-divider" aria-hidden="true" />
          </div>
        </ng-container>
      </span>
    </form>
  </div>
</div>
