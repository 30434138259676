import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ResponseTransformerService {
  private robotsInDisguise = {
    addToBasket: (v: HttpResponse<any>) => {
      return v.clone({
        body: { ...v.body['basket'], ...{ errors: v.body['errors'] } },
      });
    },
    transferBasket: (v: HttpResponse<any>) => {
      return v.clone({
        body: { ...v.body['basket'], ...v.body['itemsnottransferred'] },
      });
    },
  };
  constructor() {}

  transform(value: HttpResponse<any>) {
    if (value.headers && value.headers.get('ui-response-transformer')) {
      return this.robotsInDisguise[
        value.headers.get('ui-response-transformer')
      ](value);
    }
    return value;
  }
}
