import { BaseModel } from '@wingstop/models/base.model';
import { GeocodeResponse } from '@wingstop/models/geocode-response.model';

export class PlacesResponse extends BaseModel {
  place_id: string;
  address: string;
  geocodeResponse: GeocodeResponse;

  constructor(values?: any) {
    super();

    if (values.description) {
      values.address = values.description;
    }

    if (values.geometry) {
      this.geocodeResponse = new GeocodeResponse(values);
    }

    this.initialize(values);
  }
}
