import { BaseModel } from '@wingstop/models/base.model';

/**
 * Need to define and match fields in
 * src/nomnom-api-v4/src/clients/wingstop/models/content-banner.js
 * src/app/modules/secret-menu/components/secret-menu-banner/secret-menu-banner.component.ts
 */

export enum BannerPage {
  HOME,
  MENU,
  PDP,
}

export enum BannerRef {
  SECRET_MENU_UNLOCKED_HOME_FIRST_TIME = 'secret-menu-unlocked-homepage-first-time',
  SECRET_MENU_UNLOCKED_HOME_RETURN_USER = 'secret-menu-unlocked-homepage-return-user',
  SECRET_MENU_LOCKED_HOME = 'secret-menu-locked-home',
  SECRET_MENU_UNLOCKED_MENU_FIRST_TIME = 'secret-menu-unlocked-menu-first-time',
  SECRET_MENU_UNLOCKED_MENU_RETURN_USER = 'secret-menu-unlocked-menu-return-user',
  SECRET_MENU_UNLOCKED_PDP_FIRST_TIME = 'secret-menu-unlocked-pdp-first-time',
  SECRET_MENU_UNLOCKED_PDP_RETURN_USER = 'secret-menu-unlocked-pdp-return-user',
  SECRET_MENU_LOCKED_PDP = 'secret-menu-locked-pdp',
}

export enum SecretMenuSignupSource {
  PENCIL_HOMEPAGE = 'Homepage Secret Menu Pencil Banner',
  PENCIL_PDP = 'PDP Secret Menu Pencil Banner',
}
export class Banner extends BaseModel {
  id: any = null;
  image1Uri: any = null;
  image2Uri: any = null;
  headlineText: any = null;
  bodyText: any = null;
  cta1Uri: any = null;
  cta1Label: any = null;
  cta1ImageUri: any = null;
  cta1Alt: any = null;
  cta1Icon: any = null;
  isActive: any = null;
  ref: any = null;
  backgroundColor: any = null;
  classes: any = null;
  startsAt: any = null;
  endsAt: any = null;
  startTime: any = null;
  endTime: any = null;
  daysOfWeek: any = null;
  createdAt: any = null;
  updatedAt: any = null;
  routerLink: any = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }

  init() {
    if (this.cta1Uri.includes('http') || this.cta1Uri.includes('https')) {
      this.routerLink = null;
    } else {
      this.routerLink = [this.cta1Uri];
    }
  }
}
