import { Pipe, PipeTransform } from '@angular/core';

import {
  TipConfiguration,
  TipConfigurationTipOption
} from '../../../ecomm/types/regional-configuration.types';

type TipConfigurationTipDisplayOption = Omit<
  TipConfigurationTipOption,
  'amount'
> & {
  amount: string;
};

@Pipe({
  name: 'tipOptions'
})
export class TipOptionsPipe implements PipeTransform {
  public static readonly DOLLAR = 'dollar';
  public static readonly PERCENT = 'percent';

  private format(
    options: TipConfigurationTipOption[],
    type: typeof TipOptionsPipe.DOLLAR | typeof TipOptionsPipe.PERCENT
  ): TipConfigurationTipDisplayOption[] | undefined {
    return options.map((opt) => ({
      ...opt,
      amount:
        type === TipOptionsPipe.DOLLAR ? `$${opt.amount}.00` : `${opt.amount}%`
    }));
  }

  transform(
    tipConfiguration: TipConfiguration | undefined,
    cartSubTotal: number
  ): TipConfigurationTipDisplayOption[] | undefined {
    if (!tipConfiguration) {
      return undefined;
    }

    const { presetOptions } = tipConfiguration;
    if (!presetOptions) {
      return undefined;
    }

    if (tipConfiguration.threshold) {
      return cartSubTotal <= tipConfiguration.threshold
        ? this.format(presetOptions.dollarOptions, TipOptionsPipe.DOLLAR)
        : this.format(presetOptions.percentOptions, TipOptionsPipe.PERCENT);
    }
    return undefined;
  }
}
