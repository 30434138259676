import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MetaReducer, StoreModule } from '@ngrx/store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { hydrationMetaReducer, reducers } from './store/app-store';

import { AnalyticsService } from '@wingstop/services/analytics.service.service';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routes';
import { AppStateActions } from './store/app/app-state.actions';
import { AppStateSelectors } from './store/app/app-state.selectors';
import { AuthGuardService } from './services/auth-guard.service';
import { BootstrapModule } from 'src/app/modules/bootstrap/bootstrap.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CacheInterceptor } from './providers/cache.interceptor';
import { ClientidInterceptor } from './providers/clientid.interceptor';
import { EffectsModule } from '@ngrx/effects'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './modules/bootstrap/components/footer/footer.component';
import { GoogleMapProviderService } from './providers/google-map-provider.service';
import { InteractionStudioInterceptor } from './providers/interaction-studio.interceptor';
import { InteractionStudioService } from '@wingstop/services/interaction-studio.service';
import { LocaleInterceptor } from './providers/locale.interceptor';
import { LottieModule } from 'ngx-lottie';
import { MobileAppBannerComponent } from './modules/bootstrap/components/mobile-app-banner/mobile-app-banner.component';
import { NavbarComponent } from './modules/bootstrap/components/navbar/navbar.component';
import { OfferDetailsResolver } from './resolvers/offerDetails.resolver';
import { OrderIdGuardService } from '@wingstop/services/order-id-guard.service';
import { PhoneFormatter } from './providers/phone-formatter.provider';
import { PilotAuthenticationResolver } from './resolvers/pilot-authentication.resolver';
import { PlatformInterceptor } from './providers/platform.interceptor';
import { ReduxProvider } from './providers/redux.provder';
import { ResizeService } from '@wingstop/services/resize.service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SeoService } from './services/seo.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { buildSpecificModuleConfigurations } from './build-specific-module-configurations/module';
import { environment } from '@wingstop/environments/environment';
import player from 'lottie-web';
import * as Sentry from "@sentry/angular-ivy";

import {
  AccountsModule,
  EcommModule,
  GlobalMenuModule,
  HomePageModule,
  HydrationEffects,
  LocationMenuModule,
  LocationsModule,
  LocationsRoutingModule,
  OrderModule,
  StaticSeoMetadataEffects,
  WingCalculatorModule,
  WingCalculatorRoutingModule,
  WriCommonModule
} from './lib/ngfe-app/src/public-api';

export function playerFactory() {
  return player;
}

const metaReducers: Array<MetaReducer<any, any>> = [hydrationMetaReducer];
@NgModule({
  imports: [
    NgbModule,
    BrowserModule,
    HttpClientModule,
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    AppRouting,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      metaReducers: metaReducers,
    }),
    EffectsModule.forRoot(HydrationEffects, StaticSeoMetadataEffects),
    EcommModule.withConfig(environment, { legacyAnalyticsServiceClass: AnalyticsService, legacySeoServiceClass: SeoService }),
    WriCommonModule,
    AccountsModule,
    GlobalMenuModule,
    HomePageModule,
    LocationMenuModule,
    LocationsModule,
    LocationsRoutingModule,
    OrderModule,
    WingCalculatorModule,
    WingCalculatorRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 6000,
      maxOpened: 1,
      enableHtml: true,
      autoDismiss: true
    }),
    ToastContainerModule,
    // SocialLoginModule,
    FontAwesomeModule,
    SlickCarouselModule,
    LottieModule.forRoot({ player: playerFactory }),
    ScrollToModule.forRoot(),
    BootstrapModule,
    ...buildSpecificModuleConfigurations,
  ],
  declarations: [AppComponent],
  bootstrap: [
    AppComponent,
    MobileAppBannerComponent,
    NavbarComponent,
    FooterComponent,
  ],
  providers: [
    AppStateActions,
    AppStateSelectors,
    AuthGuardService,
    OfferDetailsResolver,
    OrderIdGuardService,
    PhoneFormatter,
    ReduxProvider,
    GoogleMapProviderService,
    NgbActiveModal,
    WINDOW_PROVIDERS,
    NgbModal,
    ResizeService,
    InteractionStudioService,
    PilotAuthenticationResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientidInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InteractionStudioInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleOauthClientId, {
              oneTapEnabled: false,
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (reduxProvider: ReduxProvider) =>
        function () {
          return reduxProvider.load();
        },
      multi: true,
      deps: [ReduxProvider, Sentry.TraceService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.sentry.enableSentryFeedbackDialog,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class AppModule { }

Number.prototype.toFixedNumber = function (x, base) {
  const pow = Math.pow(base || 10, x);
  return +(Math.round(this * pow) / pow);
};

Number.prototype.toUSD = function () {
  return Number(this.toFixedNumber(2).toFixed(2));
};

String.prototype.pad = function (size: number) {
  let s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};
