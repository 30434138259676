import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Menu } from '@wingstop/models/menu.model';
import { Location } from '@wingstop/models/location/location.model';
import { environment } from '@wingstop/environments/environment';
import { Basket } from '@wingstop/models/basket.model';
import { ApiService } from '@wingstop/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TransformerService {
  private robotsInDisguise = {
    menu: (v: any) => {
      return new Menu(v);
    },
    restaurants: (v: any) => {
      return v['restaurants']
        .filter(
          (s: any) => !environment.hiddenLocationExtRefs.includes(s.extref)
        )
        .map((p: any) => new Location(p));
    },
    restaurant: async (v: any) => {
      const menu = v.id ? await this.apiService.menu(v.id) : null;
      return new Location({ ...v, ...{ menu: menu } });
    },
    basket: async (v: any) => {
      const restaurant = await this.apiService.location(v.vendorid);
      return new Basket({ ...v, ...{ restaurant: restaurant } });
    },
  };
  constructor(private apiService: ApiService) {}

  transform(value: HttpResponse<any>) {
    if (value.headers && value.headers.get('ui-transformer')) {
      return this.robotsInDisguise[value.headers.get('ui-transformer')](
        value.body
      );
    }
    return value.body;
  }
}
