import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ECOMM_API_CONFIG,
  EcommAPIConfig
} from '../../config/ecomm-config.provider';

import {
  RegionalConfigurationDto,
  RegionalConfigurationResponse
} from '../../types/regional-configuration.types';
import { handleAPIResponse } from '../../utils/handle-api-response';
import { MaybeResponse } from '../../types/maybe-response';
import { FeatureFlagService } from '../../utils/feature-flag/feature-flag.service';
import { WINDOW } from '../../providers/window/window.provider';
import { set } from 'lodash';

@Injectable({ providedIn: 'root' })
export class RegionalConfigurationRepository {
  constructor(
    private http: HttpClient,
    @Inject(ECOMM_API_CONFIG) private config: EcommAPIConfig,
    private featureFlagService: FeatureFlagService,
    @Inject(WINDOW) private window: Window
  ) {}

  public getRegionalConfiguration(): Observable<
    MaybeResponse<RegionalConfigurationDto>
  > {
    const url = `${this.config.baseUrl}/api/regional-configuration`;
    return this.http
      .get<RegionalConfigurationResponse>(url, { observe: 'response' })
      .pipe(
        map((response) => {
          const headers = response.headers;
          const customHeaderValue = headers.get('x-ip-regioncode');
          if (customHeaderValue) {
            set(
              this.window,
              ['ws', 'visitor', 'regioncode'],
              customHeaderValue
            );
          }
          return response.body as RegionalConfigurationResponse;
        }),
        handleAPIResponse(
          RegionalConfigurationResponse,
          [],
          this.featureFlagService.featureFlags.enableDevLogs
        )
      );
  }
}
