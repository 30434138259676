import { CategoryProduct } from './category-product.model';
export enum UpsellTypes {
  BUTTON = 'button',
  CAROUSEL = 'carousel',
}

export enum UpsellGaTypes {
  MAKE_IT_A_COMBO = 'make it a combo',
  UPSELL_WINGS = 'upsell wings',
  UPSELL_FRIES = 'upsell fries',
}
export interface UpsellProduct {
  value: string;
  type: UpsellTypes;
  cta: string;
  product: CategoryProduct;
}
