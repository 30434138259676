import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  Subscription,
  switchMap,
  tap
} from 'rxjs';

import { PathParams } from '../../../../ecomm/constants/params';
import {
  Config,
  CONFIG
} from '../../../../ecomm/providers/config/config.provider';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataRestaurantFromLocation
} from '../../../../ecomm/providers/legacy-providers/seo.service';
import { Address } from '../../../../ecomm/types/address';
import { LocationResponseData } from '../../../../ecomm/types/search-location.types';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { MarkerData, PartialOutageModalComponent } from '../../../common';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { StoreInfo } from '../../../../ecomm/types/store-info.types';
import { Store } from '@ngrx/store';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { UNKNOWN_ERROR } from '../../../../ecomm/utils/throw-error';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';

type LocationDetailsData = {
  stateSlug: string;
  stateName: string;
  [PathParams.citySlug]: string;
  [PathParams.locationSlug]: string;
};

@Component({
  selector: 'wri-location-details',
  styleUrls: ['./location-details.component.scss'],
  template: `
    <wri-partial-outage-modal></wri-partial-outage-modal>
    <ng-container *ngIf="isLoading; then loading; else loaded"></ng-container>
    <ng-template #loading>
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>
    </ng-template>

    <ng-template #loaded>
      <div>
        <wri-map
          height="254px"
          [interactive]="true"
          [showPreview]="false"
          [allowPopup]="false"
          [locations]="markerData"
        ></wri-map>
        <div
          *ngIf="location"
          class="location-info"
          [ngClass]="{
            'hide-store-hrs': location?.comingSoonStatus && location?.openDate
          }"
        >
          <div class="location-details">
            <h4 class="title">{{ location?.name }}</h4>
            <ng-container
              *ngIf="location?.comingSoonStatus && location?.openDate"
            >
              <div class="coming-soon-city-details-badge">
                <span class="coming-soon-city-details-badge-text"
                  >Coming Soon</span
                >
              </div>
            </ng-container>
            <wri-address-link
              [name]="location?.name"
              [address]="address"
            ></wri-address-link>
            <ng-container
              *ngIf="!(location?.comingSoonStatus && location?.openDate)"
            >
              <wri-todays-hours
                [businessDay]="location?.businessDay"
                [hours]="location?.storeHours"
              ></wri-todays-hours>
            </ng-container>
            <wri-phone-number
              [phoneNumber]="location?.phoneNumber"
            ></wri-phone-number>
            <ng-container
              *ngIf="!(location?.comingSoonStatus && location?.openDate)"
            >
              <wri-location-amenities
                [offersDelivery]="offersDelivery"
                [amenities]="location?.amenities"
              ></wri-location-amenities>
            </ng-container>
            <div
              *ngIf="!(location?.comingSoonStatus && location?.openDate)"
              class="location-actions"
            >
              <a
                class="btn carryout wri-btn wri-btn-primary-icon"
                [href]="carryoutUrl"
                (click)="logAnalytics('carryout')"
                *ngIf="location.handoffModes?.includes('carryout')"
              >
                <wri-icon icon="wri-carryout"></wri-icon>
                <span>carryout</span>
              </a>
              <a
                class="btn delivery wri-btn wri-btn-primary-icon"
                [href]="deliveryUrl"
                (click)="logAnalytics('delivery')"
                *ngIf="location.handoffModes?.includes('delivery')"
              >
                <wri-icon icon="wri-delivery"></wri-icon>
                <span>delivery</span>
              </a>
            </div>
          </div>
          <ng-container
            *ngIf="!(location?.comingSoonStatus && location?.openDate)"
          >
            <wri-location-hours
              [handoffModeCalendars]="location?.storeHandoffModeHours"
            ></wri-location-hours>
          </ng-container>
        </div>
        <section class="about-section">
          <div class="about-title">
            {{ aboutSectionTitle }}
          </div>
          <div class="about-desc" [innerHTML]="formattedAboutSection"></div>
        </section>
        <wri-marketing-section [page]="'store_details'"></wri-marketing-section>
        <wri-social-media-marketing-section></wri-social-media-marketing-section>
      </div>
    </ng-template>
  `
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  public storeInfo: StoreInfo | null = null;
  public isLoading = false;
  public data: LocationDetailsData | null = null;
  public details: LocationResponseData | null = null;
  public markerData: MarkerData[] = [];
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private redirectService: RedirectService,
    private store: Store,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(CONFIG) private config: Config,
    private analyticsService: AnalyticsService,
    private storeInfoService: StoreInfoWorkflowService,
    private notificationService: NotificationService,
    private storeInfoWorkflowService: StoreInfoWorkflowService
  ) {
    const data$: Observable<LocationDetailsData> = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.params
    ]).pipe(
      map(([data, params]) => ({ ...data, ...params } as LocationDetailsData)),
      tap((data) => (this.data = data))
    );

    const details$ = data$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap((data) =>
        this.storeInfoService
          .getLocationDetailsUsingMenuLocationId(data.locationSlug, 'carryout')
          .then(this.reportErrorIfNull.bind(this))
      ),
      tap((details) => (this.details = details)),
      tap(
        (details) =>
        (this.markerData = details?.location
          ? [
            {
              lat: details?.location.latitude,
              lng: details?.location.longitude,
              id: details?.location.id,
              description: details?.location?.name
            }
          ]
          : [])
      ),
      tap(() => (this.isLoading = false)),
      tap(this.setSeoMetaData.bind(this)),
      tap(this.setStructuredData.bind(this))
    );

    this.subscription.add(details$.subscribe());
  }

  public get location() {
    return this.details?.location;
  }

  public get locationName() {
    return this.details?.location?.name ?? '';
  }

  public get stateName() {
    return this.data?.stateName ?? '';
  }

  public get stateSlug() {
    return this.data?.stateSlug ?? '';
  }

  public get localityName() {
    return this.details?.location.locality ?? '';
  }

  public get localitySlug() {
    return this.data?.citySlug ?? '';
  }

  get offersDelivery() {
    return (
      this.location?.handoffModes.some((f) => f.toLowerCase() === 'delivery') ??
      false
    );
  }

  public get address(): Address | null {
    const address = this.location
      ? {
        streetAddress: this.location.streetAddress ?? '',
        locality: this.location.locality ?? '',
        region: this.location.region ?? '',
        postalCode: this.location.postalCode ?? '',
        countryCode: this.location.countryCode ?? '',
        latitude: this.location.latitude ?? 0,
        longitude: this.location.longitude ?? 0
      }
      : null;
    return address;
  }

  public get aboutSectionTitle() {
    return `BRING THE FLAVOR ${this.locationName}`;
  }

  get carryoutUrl(): string {
    const url = ['location', this.location?.slug, 'menu'].join('/');
    return `${url}?handoffMode=carryout`;
  }

  get deliveryUrl(): string {
    const url = ['order'].join('/');
    return `${url}?handoffMode=delivery`;
  }

  private get formattedAboutSection() {
    return this.config.locationPageAboutSection
      .replace(/\[Store]/gi, this.locationName)
      .replace(/\[State]/gi, this.stateName)
      .replace(/\[City]/gi, this.localityName);
  }

  private get getFriendlyLastBreadcrumbUrl() {
    return this.document.location.origin + this.document.location.pathname;
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToStoreInfoState();
    this.subscribeToRegionalConfigState();

    const regionalConfig =
      await this.storeInfoWorkflowService.getRegionalConfigurationAndSave(
        'carryout',
        false
      );

    if (
      regionalConfig?.regionalConfigurationOptions[
        'feature_enable_state_routes'
      ]?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy();
    }
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  reportErrorIfNull = (
    res: LocationResponseData | null
  ): LocationResponseData | null => {
    if (res === null) {
      this.notificationService.showError(UNKNOWN_ERROR);
    }
    return res;
  };

  logAnalytics(handoff: string) {
    this.analyticsService.logGaEvent({
      event: 'store_order_now',
      order_method: handoff
    });
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfo = state.storeInfo;
      })
    );
  }

  private setSeoMetaData = () => {
    this.seoService.setMetaData({
      title: `${this.locationName}, ${this.stateName} - Information - Wings Restaurant | Wingstop`,
      description: `Start your online order at ${this.locationName}, ${this.localityName}, ${this.stateName}`
    });
  };

  private setStructuredData = () => {
    if (this.location) {
      this.seoService.addStructuredData([
        structuredDataRestaurantFromLocation(
          this.document,
          this.location,
          this.formattedAboutSection
        ),
        structuredDataBreadcrumbList([
          {
            name: 'Home',
            url: this.document.location.origin
          },
          {
            name: this.stateName,
            url: `${this.document.location.origin}/${this.stateSlug}`
          },
          {
            name: this.localityName,
            url: `${this.document.location.origin}/${this.stateSlug}/${this.localitySlug}`
          },
          {
            name: this.locationName,
            url: this.getFriendlyLastBreadcrumbUrl
          }
        ])
      ]);
    }
  };
}
