import * as t from 'io-ts';

import { EcommFeatureFlags } from '../config/ecomm-feature-flags.provider';
import { ecommApiResponse, optional } from './common.types';

/* #region TipConfigurationTipOption */
export const TipConfigurationTipOptionDto = t.intersection([
  t.type({
    id: t.string,
    default: t.boolean,
    amount: t.number
  }),
  t.partial({})
]);

export type TipConfigurationTipOptionDto = t.TypeOf<
  typeof TipConfigurationTipOptionDto
>;
export type TipConfigurationTipOption = TipConfigurationTipOptionDto;

/* #endregion */

/* #region TipConfigurationPresetOption */
export const TipConfigurationPresetOptionDto = t.intersection([
  t.type({
    handoffMode: t.string,
    dollarOptions: t.array(TipConfigurationTipOptionDto),
    percentOptions: t.array(TipConfigurationTipOptionDto)
  }),
  t.partial({})
]);

export type TipConfigurationPresetOptionDto = t.TypeOf<
  typeof TipConfigurationPresetOptionDto
>;
export type TipConfigurationPresetOption = Omit<
  TipConfigurationPresetOptionDto,
  'dollarOptions' | 'percentOptions'
> & {
  dollarOptions: TipConfigurationTipOption[];
  percentOptions: TipConfigurationTipOption[];
};
/* #endregion */

/* #region TipConfiguration */
export const TipConfigurationDto = t.intersection([
  t.type({}),
  t.partial({
    threshold: t.number,
    presetOptions: t.array(TipConfigurationPresetOptionDto)
  })
]);
export type TipConfigurationDto = t.TypeOf<typeof TipConfigurationDto>;
export type TipConfiguration = Omit<TipConfigurationDto, 'presetOptions'> & {
  presetOptions: TipConfigurationPresetOption | null;
};
/* #endregion */

/* #region Configuration */
export const ConfigurationDto = t.record(
  t.string,
  t.intersection([
    t.type({
      value: t.string
    }),
    t.partial({
      description: optional(t.string)
    })
  ])
);

export type ConfigurationDto = t.TypeOf<typeof ConfigurationDto>;
export type Configuration = ConfigurationDto;
/* #endregion */

/* #region RegionalConfiguration */
export const RegionalConfigurationDto = t.intersection([
  t.type({
    regionalConfigurationOptions: ConfigurationDto,
    tipConfiguration: TipConfigurationDto
  }),
  t.partial({
    outageMessagePresentedDate: optional(t.string)
  })
]);

export type RegionalConfigurationDto = t.TypeOf<
  typeof RegionalConfigurationDto
>;
export type RegionalConfiguration = Omit<
  RegionalConfigurationDto,
  'tipConfiguration'
> & {
  featureFlags: Partial<EcommFeatureFlags>;
  tipConfiguration: TipConfiguration;
};
/* #endregion */

/* #region RegionalConfigurationResponse */
export const RegionalConfigurationResponse = ecommApiResponse(
  RegionalConfigurationDto
);
export type RegionalConfigurationResponse = t.TypeOf<
  typeof RegionalConfigurationResponse
>;
/* #endregion */
