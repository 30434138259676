import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, filter } from 'rxjs';

import { Order } from '../../../../ecomm/types/order.types';
import { LocationResponseData } from '../../../../ecomm/types/search-location.types';
import { OrderWorkflowService } from '../../../../ecomm/workflows/order/order-workflow.service';
import { SearchLocationWorkflowService } from '../../../../ecomm/workflows/search-location/search-location-workflow.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { RegionalConfigurationFeature, RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';
import { Store } from '@ngrx/store';
import { PartialOutageModalComponent } from '../../../common';

@Component({
  selector: 'wri-connected-order-details',
  template: `
    <wri-partial-outage-modal></wri-partial-outage-modal>
    <wri-page-loading-indicator *ngIf="isLoading"></wri-page-loading-indicator>
    <wri-confirmation
      (cancelOrderEmit)="cancelOrder($event)"
      [order]="orderWithLocation$ | async"
      [isPastOrder]="true"
    ></wri-confirmation>
  `
})
export class ConnectedOrderDetailsComponent implements OnInit, OnDestroy {
  orderWithLocation$ = new BehaviorSubject<
    (Order & { locationDetails: LocationResponseData | null }) | null
  >(null);
  isLoading = true;

  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;
  private orderId: string | undefined;
  private locationDetails: LocationResponseData | null = null;

  constructor(
    private route: ActivatedRoute,
    private orderWorkflowService: OrderWorkflowService,
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private locationDetailsWorkflowService: SearchLocationWorkflowService,
    private store: Store
  ) {}

  async ngOnInit() {
    this.getRouteParams();
    this.storeInfoWorkflowService.getRegionalConfigurationAndSave(
      'carryout',
      false
    );
    await this.getOrderDetails();
    this.subscribeToRegionalConfigState();
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async cancelOrder(orderId: string) {
    this.isLoading = true;
    const order = await this.orderWorkflowService.cancelOrder(orderId);

    if (order && this.locationDetails) {
      this.orderWithLocation$.next({
        ...(order as Order),
        locationDetails: this.locationDetails
      });
    }
    this.isLoading = false;
  }

  private async getOrderDetails() {
    if (this.orderId) {
      const orderDetails = await this.orderWorkflowService.getPastOrder(
        this.orderId
      );

      if (orderDetails?.location) {
        const locationDetails = await this.locationDetailsWorkflowService.getLocationByIdWithoutSavingState({
          locationId: orderDetails?.cart.locationId ?? ''
        });
        
        this.locationDetails = locationDetails;

        if (locationDetails && orderDetails) {
          this.orderWithLocation$.next({
            ...(orderDetails as Order),
            locationDetails: locationDetails
          });
        }
      }

      this.isLoading = false;
    }
  }

  private getRouteParams() {
    this.subscription.add(
      this.route.params.subscribe((param) => (this.orderId = param['id']))
    );
  }
}
