import { BaseModel } from '@wingstop/models/base.model';
import { BillingSchemeField } from '@wingstop/models/order/billing-scheme-field.model';
import { BillingSchemeAccount } from '@wingstop/models/order/billing-scheme-account.model';
import { BillingSetting } from '@wingstop/models/order/billing-setting.model';

export class BillingScheme extends BaseModel {
  id: number = null;
  name: string = null;
  type: string = null;
  cancheckbalance: boolean = true;
  supportsfulladdresscollection: boolean = true;
  fields: BillingSchemeField[] = [];
  accounts: BillingSchemeAccount[] = [];
  billingsettings: BillingSetting[] = [];

  constructor(values?: any) {
    super();

    if (values) {
      if (values.fields) {
        values.fields = values.fields.map(
          (value: any) => new BillingSchemeField(value)
        );
      }
      if (values.accounts) {
        values.accounts = values.accounts.map(
          (value: any) => new BillingSchemeAccount(value)
        );
      }
    }

    this.initialize(values);
  }
}
