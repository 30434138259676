<ng-container>
  <ng-container [ngSwitch]="content.name">
    <ng-container *ngSwitchCase="'birthday'">
      <ng-container *ngTemplateOutlet="birthdayTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultTemplate>
  <div class="common-modal" role="dialog" aria-modal="true" trapFocus>
    <div class="common-modal__header">
      <div class="common-modal__icon-wrapper">
        <a
          wriFocusOutline
          (click)="close()"
          [attr.aria-label]="'close modal'"
          data-testid="common-modal">
          <wri-icon
            class="common-modal__cross_icon"
            icon="wri-cross"></wri-icon>
        </a>
      </div>
    </div>
    <div class="wri-modal__wrapper">
      <div class="wri-modal__title">{{ content.title }}</div>
      <div
        data-testid="logout-popup-modal-description"
        class="wri-modal__description">
        <p>{{ content.description }}</p>
      </div>
      <div class="wri-modal__btn-wrapper">
        <button
          class="wri-btn wri-btn-primary"
          attr.aria-label="{{ content.buttonAriaLabel }}"
          (click)="agreeWithRules()">
          {{ content.button }}
        </button>
        <button
          class="wri-btn wri-btn-tertiary"
          aria-label="Close"
          (click)="close()">
          {{ content.closeButton }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #birthdayTemplate>
  <div class="wing-alert-modal text-center birthday-modal" trapFocus>
    <button
      *ngIf="content.topRightCloseButton"
      type="button"
      class="close"
      aria-label="Close"
      (click)="close()">
      <svg type="close" aria-hidden="true"></svg>
    </button>
    <img
      src="/assets/images/bday-fries.png"
      alt="birthday fries"
      class="bday-fries" />
    <div class="wing-alert-modal--inner">
      <ng-container *ngIf="!birthdayUpdated">
        <h5>Want a free gift on your birthday?</h5>
        <p>
          Tell us your birthday and get a free gift when it's time to celebrate!
        </p>
      </ng-container>
      <ng-container *ngIf="birthdayUpdated">
        <h5 class="">Your Birthday has been added to your account!</h5>
      </ng-container>

      <div class="d-flex flex-sm-column flex-lg-row row justify-content-center">
        <form
          [formGroup]="birthdayForm"
          class="text-left col-12 birthday-Form form-group mb-2"
          *ngIf="!birthdayUpdated">
          <label for="birthday">Enter Birthday</label>
          <input
            id="birthday"
            formControlName="birthday"
            type="tel"
            maxlength="5"
            class="form-control"
            placeholder="MM/DD"
            (keyup)="reformatBday($event)"
            onkeypress="return event.charCode > 47 && event.charCode < 58;" />
        </form>
        <div
          class="invalid-feedback"
          aria-live="polite"
          tabindex="0"
          *ngIf="birthdayForm.controls['birthday'].errors">
          <div data-cy="dobError">Enter a valid date</div>
        </div>
        <ng-container *ngIf="!birthdayUpdated">
          <button
            type="button"
            class="btn btn-outline mr-lg-2 mb-lg-0 mb-2"
            (click)="close()">
            No Thanks
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="
              !birthdayForm.valid || !birthdayForm.get('birthday').value
            "
            (click)="updateBirthday()">
            Add Your Birthday
          </button>
        </ng-container>
        <ng-container *ngIf="birthdayUpdated">
          <button
            type="button"
            class="btn btn-outline mr-lg-2 mb-lg-0 mb-2"
            (click)="close()">
            Thanks!
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="viewAccountInfo()">
            View Account Info
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
