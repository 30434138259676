import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  Observable,
  Subscription
} from 'rxjs';
import { DOCUMENT } from '../../../../ecomm/providers/document/document.provider';
import {
  ILegacySeoService,
  LEGACY_SEO_SERVICE,
  structuredDataBreadcrumbList,
  structuredDataCorporation
} from '../../../../ecomm/providers/legacy-providers/seo.service';

import {
  MenuCategory,
  MenuItem,
  StoreInfo
} from '../../../../ecomm/types/store-info.types';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import { RedirectService } from '../../../../ecomm/utils/redirect/redirect.service';
import { StoreInfoWorkflowService } from '../../../../ecomm/workflows/store-info/store-info-workflow.service';
import { ActiveOfferComponent, MenuItemWithSlug, PartialOutageModalComponent } from '../../../common';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import { Store } from '@ngrx/store';
import { Cart } from '../../../../ecomm/types/cart.types';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { CustomerFeature } from '../../../../ecomm/store/features/customer';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { RegionalConfigurationFeature, RegionalConfigurationFeatureState } from '../../../../ecomm/store/features/regional-configuration';
import { Customer } from '../../../../ecomm/types/customer.types';

@Component({
  selector: 'wri-connected-home-page',
  styleUrls: ['./connected-home-page.component.scss'],
  template: `
    <div [ngClass]="{ 'wri-homepage': userName }">
      <wri-page-loading-indicator
        *ngIf="isLoading"
      ></wri-page-loading-indicator>
      <ng-container *ngIf="userName">
        <div
          class="user-banner sticky-header"
          [ngClass]="{ 'wri-homepage-cart-unavailable': !cart }"
        >
          <span data-testid="user-banner-name" class="user-banner-name">{{
            userFirstName ? 'Hi, ' + userFirstName + '!' : 'Hi!'
          }}</span>
          <a
            wriFocusOutline
            tabindex="0"
            (click)="reorderClick()"
            class="user-banner-reorder-btn wri-btn wri-btn-text"
            aria-label="Reorder"
            data-testid="reorder-btn"
          >
            {{ (isReorderEnabledPromise | async) ? 'Reorder' : 'My Orders' }}
          </a>
        </div>
      </ng-container>
      <wri-home-page-hero></wri-home-page-hero>
      <wri-active-offer [showTag]="false"></wri-active-offer>
      <wri-partial-outage-modal></wri-partial-outage-modal>
      <div class="mobile-only sticky-footer">
        <div
          (click)="navigateTo('carryout')"
          class="wri-btn wri-btn-primary-icon"
          aria-label="Carryout"
          data-testid="sticky-carryout-cta"
        >
          <wri-icon
            icon="wri-carryout"
            class="wri-carryout"
            data-testid="carryout-img"
          ></wri-icon>

          Carryout
        </div>
        <div class="vertical-separator"></div>
        <div
          (click)="navigateTo('delivery')"
          class="wri-btn wri-btn-primary-icon"
          aria-label="Delivery"
          data-testid="sticky-delivery-cta"
        >
          <wri-icon icon="wri-delivery" data-testid="delivery-img"></wri-icon>

          Delivery
        </div>
      </div>
      <wri-home-page-product-carousel
        [sliderData]="itemsForMenuAdditionalRecommendation$ | async"
      >
      </wri-home-page-product-carousel>
      <wri-home-page-flavor-section>
        *ngIf="featureFlagService.featureFlags.enableFlavorsPage"
      </wri-home-page-flavor-section>
      <wri-feature-flags [with]="['enableHomePageFindYourMenuSection']">
        <wri-home-page-find-your-menu-section>
        </wri-home-page-find-your-menu-section>
      </wri-feature-flags>
      <wri-home-page-wing-calculator
        *ngIf="featureFlagService.featureFlags.enableWingCalculator"
      >
      </wri-home-page-wing-calculator>
    </div>
  `
})
export class ConnectedHomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading = false;
  userFirstName: string | null | undefined = null;
  userName: string | null | undefined = null;
  public storeInfo$ = new BehaviorSubject<
    StoreInfo | { categories: MenuCategory[] } | null
  >({ categories: [] });
  public storeInfoState: StoreInfo | null = null;
  public customerState: Customer | null = null;
  cart: Cart | null = null;
  itemsForMenuAdditionalRecommendation$: Observable<MenuItemWithSlug[]> =
    this.storeInfo$.pipe(
      map((storeInfo) => {
        const retVal: MenuItemWithSlug[] = [];
        storeInfo?.categories.forEach((category) => {
          category.products.forEach((product) => {
            if (
              product.item !== null &&
              product.item?.metadata &&
              product.item?.metadata?.['home-product-carousel'] === 'true'
            ) {
              retVal.push({
                categorySlug: category.slug,
                productSlug: product.slug,
                item: product.item
              });
            }

            if (product.itemGroup !== null) {
              product.itemGroup?.items.forEach((item) => {
                if (
                  (item as MenuItem | undefined)?.metadata &&
                  (item as MenuItem | undefined)?.metadata?.[
                    'home-product-carousel'
                  ] === 'true'
                ) {
                  retVal.push({
                    categorySlug: category.slug,
                    productSlug: product.slug,
                    itemSlug: item.slug,
                    item: item
                  });
                }
              });
            }
          });
        });

        return retVal;
      })
    );
  isReorderEnabledPromise: Promise<boolean> | undefined;
  private subscription = new Subscription();

  @ViewChild(ActiveOfferComponent) activeOfferComponent:
    | ActiveOfferComponent
    | undefined;

  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;

  constructor(
    private storeInfoWorkflowService: StoreInfoWorkflowService,
    private redirectService: RedirectService,
    public featureFlagService: FeatureFlagService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LEGACY_SEO_SERVICE)
    private seoService: ILegacySeoService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store
  ) {}

  public get storeInfo(): StoreInfo | { categories: MenuCategory[] } | null {
    return this.storeInfo$.value;
  }

  public set storeInfo(val: StoreInfo | { categories: MenuCategory[] } | null) {
    if (val) {
      this.storeInfo$.next(val);
    }
  }

  async ngOnInit() {
    addSpaceToBodyWithStickyFooter('home-page');
    this.isLoading = true;
    const regionalConfiguration =
      await this.storeInfoWorkflowService.getRegionalConfigurationAndSave(
        'carryout',
        false
      );

    //redirect to legacy
    if (
      regionalConfiguration?.regionalConfigurationOptions['feature_enable_home_page']
        ?.value === 'false'
    ) {
      this.redirectService.redirectToLegacy();
    }

    const gms =
    regionalConfiguration?.regionalConfigurationOptions?.['global_menu_slug'];
    if (gms && gms.value) {
      this.storeInfo =
        await this.storeInfoWorkflowService.getStoreInfoBySlugWithoutSavingState(
          gms.value,
          'carryout',
          false
        );
    }
    this.isLoading = false;

    this.seoService.addStructuredData([
      structuredDataCorporation(this.document),
      structuredDataBreadcrumbList([
        { name: 'Home', url: this.document.location.origin }
      ])
    ]);

    this.subscribeToCartState();
    this.subscribeToRegionalConfigState();
    this.subscribeToStoreInfoState();
    this.subscribeToCustomerState();
    await this.isReorderEnabled();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const redeemCode = this.route.snapshot.queryParamMap.get('redeem');
      if (redeemCode) {
        this.activeOfferComponent?.openOfferModal();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  navigateTo(handOffMode: string) {
    if (this.cart && this.cart.handoffMode === handOffMode) {
      this.router.navigate(['/menu']);
    } else {
      if (this.featureFlagService.featureFlags.enableOrderRoute) {
        this.router.navigate(['/order'], {
          queryParams: {
            handoffMode: handOffMode
          }
        });
      } else {
        this.redirectService.redirectToLegacy(`order/action/${handOffMode}`);
      }
    }
  }

  reorderClick() {
    this.logAnalytics();
    this.router.navigate([`/order/recent`]).then();
  }

  private logAnalytics() {
    this.analyticsService.logGaEvent({
      event: 'button_click',
      event_params: {
        section_title: 'homepage_user_banner',
        button_label: 'reorder'
      }
    });
  }
  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cart = state.cart;
      })
    );
  }

  private subscribeToRegionalConfigState(): void {
    const regionalConfigState$ = this.store
      .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
      .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

    this.subscription.add(
      regionalConfigState$.subscribe((state) => {
        this.partialOutageModalComponent?.showModal(state);
      })
    );
  }

  private subscribeToStoreInfoState(): void {
    const storeInfoState$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfoState$.subscribe((state) => {
        this.storeInfoState = state.storeInfo;
      })
    );
  }

  private subscribeToCustomerState(): void {
    const customerState$ = this.store
      .select(CustomerFeature.selectCustomerState)
      .pipe(filter(Boolean));

    this.subscription.add(
      customerState$.subscribe((state) => {
        this.customerState = state.customer;
        this.userName = state.customer?.userName;
        this.userFirstName = state.customer?.firstName;
      })
    );
  }

  async isReorderEnabled() {
    if (this.featureFlagService.featureFlags['enableReorder']) {
      const regionalConfigOptions = await firstValueFrom(
        this.store.select(
          RegionalConfigurationFeature.selectRegionalConfigurationOptions
        )
      );
      if (
        regionalConfigOptions &&
        regionalConfigOptions['feature_enable_reorder']?.value === 'false'
      ) {
        this.isReorderEnabledPromise = Promise.resolve(false);
      } else {
        this.isReorderEnabledPromise = Promise.resolve(true);
      }
    } else {
      this.isReorderEnabledPromise = Promise.resolve(false);
    }
  }
}
