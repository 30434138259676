import { BaseModel } from '@wingstop/models/base.model';

export class DeliveryStatus extends BaseModel {
  id: number = 0;
  status: string = null;
  drivername: string = null;
  driverphonenumber: string = null;
  deliveryservice: string = null;

  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
