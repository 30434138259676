import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  BehaviorSubject,
  firstValueFrom,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';

import distance from '@turf/distance';
import destination from '@turf/destination';
import bearing from '@turf/bearing';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import {
  Config,
  CONFIG
} from '../../../../ecomm/providers/config/config.provider';
import { MAPBOX } from '../../../../ecomm/providers/mapbox/mapbox.provider';
import { WINDOW } from '../../../../ecomm/providers/window/window.provider';
import mapboxgl from 'mapbox-gl';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';

export type MarkerData = {
  lat: number;
  lng: number;
  description: string;
  id: string;
};

type LatLng = {
  lat: number;
  lng: number;
};

export type MapSearchEvent = {
  lat: number;
  lng: number;
  radius: number;
  radiusUnit: 'Km' | 'Mi';
};

@Component({
  selector: 'wri-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements AfterViewInit, OnDestroy {
  private static readonly PATH_FILL = 'fill';
  private static readonly SVG_HEIGHT = 'height';
  private static readonly SVG_WIDTH = 'width';
  private static readonly MAX_MOBILE_WIDTH = 991;

  private _subscription = new Subscription();
  private _mapDataSubscription: Subscription | undefined;
  private _markerData$: Subject<MarkerData[]> = new ReplaySubject(1);
  private _mapMarkers: mapboxgl.Marker[] = [];
  private _mapPopups: mapboxgl.Popup[] = [];
  private _map!: mapboxgl.Map; // AfterViewInit
  private _interactive = true;
  private _showPreview = false;
  private _searchEnabled = false;
  private _innerWidth = 0;
  private _mapToggle$: Subject<boolean> = new BehaviorSubject(
    !this._showPreview && this.featureFlagService.featureFlags.enableMapView
  );

  public mapEnabled = this.featureFlagService.featureFlags.enableMapView;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this._innerWidth = this.window.innerWidth;
  }

  @Input()
  @HostBinding('attr.id')
  @HostBinding('attr.data-testid')
  id = 'wri-location-map';

  @Input()
  @HostBinding('style.height')
  @HostBinding('style.min-height')
  @HostBinding('style.max-height')
  height?: string = '100%';

  @Input()
  @HostBinding('style.width')
  @HostBinding('style.min-width')
  @HostBinding('style.max-width')
  width?: string = '100%';

  @Input() set locations(vals: MarkerData[]) {
    this._markerData$.next(vals);
  }

  @Input()
  @HostBinding('attr.interactive')
  set interactive(val: boolean) {
    this._interactive = val;
    this._mapToggle$.next(
      !this._showPreview &&
        this._interactive &&
        this.featureFlagService.featureFlags.enableMapView
    );
  }
  get interactive() {
    return this._interactive;
  }

  @Input()
  @HostBinding('attr.preview')
  set showPreview(val: boolean) {
    this._showPreview = val;
    this._mapToggle$.next(
      !this._showPreview &&
        this._interactive &&
        this.featureFlagService.featureFlags.enableMapView
    );
  }
  get showPreview() {
    return this._showPreview;
  }

  @Input() allowPopup = true;

  @Input() set searchEnabled(val: boolean) {
    this._searchEnabled =
      val && this.featureFlagService.featureFlags.enableMapSearch;
    if (this._searchEnabled !== val) {
      console.debug(
        'Could not enable map based search capability. Map based search is disabled for this environment'
      );
    }
  }
  get searchEnabled() {
    return this._searchEnabled;
  }

  @Output()
  search = new EventEmitter<MapSearchEvent>();

  @Output()
  startSearch = new EventEmitter<true>();

  @Output()
  markerSelect = new EventEmitter<string | null>();

  get isMobile() {
    return this._innerWidth <= MapComponent.MAX_MOBILE_WIDTH;
  }

  constructor(
    private featureFlagService: FeatureFlagService,
    private analyticsService: AnalyticsService,
    @Inject(CONFIG) private config: Config,
    @Inject(MAPBOX) private mapbox: typeof mapboxgl,
    @Inject(WINDOW) private window: Window
  ) {}

  ngAfterViewInit(): void {
    this._innerWidth = this.window.innerWidth;
    this._subscription.add(
      this._mapToggle$.subscribe((shouldInit) => {
        shouldInit ? this.init() : this.destroy();
      })
    );
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this.destroy();
  }

  private init() {
    try {
      console.debug('Initializing Mapbox');
      this.mapbox.accessToken = this.config.mapBox.key;
      this._map = new this.mapbox.Map({
        container: this.id,
        style: this.config.mapBox.defaults.style,
        center: this.config.mapBox.defaults.center,
        zoom: this.isMobile
          ? this.config.mapBox.defaults.mobileZoom
          : this.config.mapBox.defaults.zoom,
        attributionControl: this.config.mapBox.defaults.attributionControl
      });

      this._map.dragRotate.disable();
      this._map.touchZoomRotate.disableRotation();

      this._map.on('load', () => {
        this._map.resize();
      });

      this._map.on('resize', async () => {
        const markerData = await firstValueFrom(this._markerData$);
        this.setView(markerData);
      });

      this._mapDataSubscription = this._markerData$.subscribe((markerData) => {
        this.setPopups(markerData);
        this.setMarkers(markerData);
        this.setView(markerData);
      });
      this._subscription.add(this._mapDataSubscription);
    } catch (e) {
      console.error(e);
    }
  }

  private destroy() {
    try {
      console.debug('Destroying Mapbox');
      if (this._mapDataSubscription) {
        this._subscription.remove(this._mapDataSubscription);
        this._mapDataSubscription.unsubscribe();
      }
      this._mapPopups?.forEach((mp) => mp?.remove());
      this._mapMarkers?.forEach((mm) => mm?.remove());
      this._map?.remove();
    } catch (err) {
      /** noop */
    }
  }

  private setPopups(markerData: MarkerData[]) {
    if (!this._map || !this.allowPopup) return;

    this._mapPopups.forEach((mp) => mp.remove());
    this._mapPopups = markerData.map((data) =>
      new this.mapbox.Popup(this.config.mapBox.popup)
        .setText(data.description)
        .addTo(this._map)
    );

    this._mapPopups.forEach((mp, i) => {
      mp.on('open', () => {
        this.setActiveMarker(i, true);
      });

      mp.on('close', () => {
        this.setActiveMarker(i, false);
      });
    });
  }

  private async setActiveMarker(index: number, isActive: boolean) {
    const mapMarker = this._mapMarkers[index];
    if (!mapMarker) return;

    const markerEl = mapMarker.getElement();
    const svg = markerEl.getElementsByTagName('svg').item(0);
    const path = markerEl.getElementsByTagName('path').item(0);
    if (!svg || !path) return;

    const markerData = (await firstValueFrom(this._markerData$))?.[index];
    if (!markerData) return;

    path.setAttribute(
      MapComponent.PATH_FILL,
      isActive
        ? this.config.mapBox.activeMarker.color
        : this.config.mapBox.marker.color
    );

    svg.setAttribute(
      MapComponent.SVG_HEIGHT,
      isActive
        ? this.config.mapBox.activeMarker.height
        : this.config.mapBox.marker.height
    );

    svg.setAttribute(
      MapComponent.SVG_WIDTH,
      isActive
        ? this.config.mapBox.activeMarker.width
        : this.config.mapBox.marker.width
    );

    this.markerSelect.emit(isActive ? markerData.id : null);
    isActive && this._map.setCenter(markerData);
  }

  private setMarkers(markerData: MarkerData[]) {
    if (!this._map) return;

    this._mapMarkers.forEach((mm) => mm.remove());
    this._mapMarkers = markerData.map((data, i) =>
      new this.mapbox.Marker(this.config.mapBox.marker)
        .setLngLat(data)
        .setPopup(this.allowPopup ? this._mapPopups[i] : undefined)
        .addTo(this._map)
    );
  }

  private setView(markerData: MarkerData[]) {
    if (!this._map) return;

    if (markerData.length) {
      const center = this.getCenterLatLng(markerData);
      const bounds = this.getMarkerBounds(markerData, center);
      this._map.fitBounds(this.getNewBounds(center, bounds.ne, bounds.sw));
    } else {
      this._map.setCenter(this.config.mapBox.defaults.center);
      this._map.setZoom(
        this.isMobile
          ? this.config.mapBox.defaults.mobileZoom
          : this.config.mapBox.defaults.zoom
      );
    }

    this._map.resize();
  }

  private getCenterLatLng(markerData: MarkerData[]): LatLng {
    return {
      lat: markerData.reduce((sum, d) => sum + d.lat, 0) / markerData.length,
      lng: markerData.reduce((sum, d) => sum + d.lng, 0) / markerData.length
    };
  }

  private getMarkerBounds(
    markerData: MarkerData[],
    center: LatLng
  ): { ne: LatLng; sw: LatLng } {
    const points = [center, ...markerData];
    const lats = points.map((p) => p.lat);
    const lngs = points.map((p) => p.lng);
    return {
      ne: {
        lat: Math.max(...lats),
        lng: Math.max(...lngs)
      },
      sw: {
        lat: Math.min(...lats),
        lng: Math.min(...lngs)
      }
    };
  }

  private getNewBounds(
    center: LatLng,
    ne: LatLng,
    sw: LatLng
  ): [[number, number], [number, number]] {
    const minimumPadding = this.minimumPadding(ne, sw);
    const neWithPadding = this.padNE(ne, minimumPadding);
    const swWithPadding = this.padSW(sw, minimumPadding);
    const areBoundsTooSmall = this.areBoundsTooSmall(ne, sw);
    return areBoundsTooSmall
      ? this.getMinimumBounds(center)
      : [
          [swWithPadding.lng, swWithPadding.lat],
          [neWithPadding.lng, neWithPadding.lat]
        ];
  }

  public onSearch() {
    if (!this._map) return;

    const center = this._map.getCenter() as LatLng;
    const { _ne: p1, _sw: p2 } = this._map.getBounds();
    const diameter = distance([p1.lng, p1.lat], [p2.lng, p2.lat], {
      units: 'kilometers'
    });
    this.search.emit({
      ...center,
      radius: diameter / 2,
      radiusUnit: 'Km'
    });
  }

  public onStartSearch() {
    this.analyticsService.logGaEvent({ event: 'map_tab' });
    this.startSearch.emit(true);
  }

  private getMinimumBounds(
    center: LatLng
  ): [[number, number], [number, number]] {
    const ne = this.minNEBound(center);
    const sw = this.minSWBound(center);
    return [
      [sw.lng, sw.lat],
      [ne.lng, ne.lat]
    ];
  }

  private minNEBound = (center: LatLng): LatLng => {
    const [lng, lat] = destination(
      [center.lng, center.lat],
      this.config.mapBox.minimumDiagonalZoomDistanceKM / 2,
      bearing([center.lng, center.lat], [center.lng + 1, center.lat + 1]),
      { units: 'kilometers' }
    ).geometry.coordinates;
    return { lng, lat };
  };

  private minSWBound = (center: LatLng): LatLng => {
    const [lng, lat] = destination(
      [center.lng, center.lat],
      this.config.mapBox.minimumDiagonalZoomDistanceKM / 2,
      bearing([center.lng, center.lat], [center.lng - 1, center.lat - 1]),
      { units: 'kilometers' }
    ).geometry.coordinates;
    return { lng, lat };
  };

  private minimumPadding = (ne: LatLng, sw: LatLng): LatLng => ({
    lat: Math.abs(ne.lat - sw.lat) * this.config.mapBox.zoomPadding,
    lng: Math.abs(ne.lng - sw.lng) * this.config.mapBox.zoomPadding
  });

  private padNE = (ne: LatLng, padding: LatLng): LatLng => ({
    lat: ne.lat + padding.lat,
    lng: ne.lng + padding.lng
  });

  private padSW = (sw: LatLng, padding: LatLng): LatLng => ({
    lat: sw.lat - padding.lat,
    lng: sw.lng - padding.lng
  });

  private distanceKM = (ne: LatLng, sw: LatLng): number =>
    distance([ne.lng, ne.lat], [sw.lng, sw.lat], {
      units: 'kilometers'
    });

  private areBoundsTooSmall = (ne: LatLng, sw: LatLng): boolean =>
    Math.abs(ne.lng - sw.lng) < 0.01 ||
    Math.abs(ne.lat - sw.lat) < 0.01 ||
    this.distanceKM(ne, sw) -
      this.config.mapBox.minimumDiagonalZoomDistanceKM <=
      0;
}
