export enum PaymentTypes {
  CREDIT_CARD = 'creditcard',
  FISERV_CREDIT_CARD = 'CREDIT',
  WWT_FISERV = 'Fiserv',
  GIFT_CARD = 'giftcard',
  FISERV_GIFT_CARD = 'SVS',
  PAY_IN_STORE = 'payinstore',
  PRE_PAID = 'prepaid',
  CASH = 'cash',
  BILLING_ACCOUNT = 'billingaccount',
  EXTERNAL = 'external',
}
