import { BaseModel } from '@wingstop/models/base.model';

export class Modal extends BaseModel {
  id: string = null;
  contentType: string = null;
  title1: string = null;
  title2: string = null;
  image1Uri: string = null;
  image2Uri: string = null;
  imageAlt: string = null;
  attribute1: string = null;
  body: string = null;
  cta1Alt: string = null;
  cta1Label: string = null;
  cta1Uri: string = null;
  cta2Alt: string = null;
  cta2Label: string = null;
  cta2Uri: string = null;
  isActive: boolean = null;
  startsAt: string = null;
  endsAt: string = null;
  startTime: string = null;
  endTime: string = null;
  daysOfWeek: string = null;
  externalContentUri: string = null;
  createdAt: string = null;
  updatedAt: string = null;
  constructor(values?: any) {
    super();
    this.initialize(values);
  }
}
